import React, { FC, useEffect } from "react";
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Costs from './Costs';
import Usage from './Usage';
import RecentImages from './RecentImages';

// constants
import { PAGE_TITLE_DASHBOARD } from '../../utils/constants';
import { Helmet } from 'react-helmet-async';
import { useTour } from "@reactour/tour";

const PREFIX = 'Dashboard';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  fixedHeight: `${PREFIX}-fixedHeight`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  [`& .${classes.fixedHeight}`]: {
    [theme.breakpoints.up('sm')]: {
      height: 280,
    },
  }
}));

interface Props {}

const Dashboard: FC<Props> = () => {
  const {setIsOpen} = useTour();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  useEffect(() => {
    const wasTourOpen = localStorage.getItem('tour-displayed');
    if(wasTourOpen !== 'yes') {
      setIsOpen(true);
      localStorage.setItem('tour-displayed', 'yes');
    }
  }, [])


  return (
    <StyledContainer maxWidth="lg" className={classes.container}>
      <Helmet>
        <title>{PAGE_TITLE_DASHBOARD}</title>
        <meta name="header" content={PAGE_TITLE_DASHBOARD} />
      </Helmet>
      <Grid container spacing={3} id={'mainDashboard'}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper sx={{height: 280, paddingBottom: 4, paddingRight: 2}}>
            <Usage />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Costs />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <RecentImages />
          </Paper>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Dashboard;
