import React, { FC } from 'react';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChartTitle from '../Layout/ChartTitle';
import RouterButtonLink from '../RouterLink/RouterButtonLink';
import { DICOMS_ROUTE } from "../../utils/routes";


const PREFIX = 'RecentImages';

const classes = {
  seeMore: `${PREFIX}-seeMore`
};

const More = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.seeMore}`]: {
    marginTop: theme.spacing(3),
  }
}));

type ImageData = {
  id: string;
  date: string;
  clinic: string;
  imageType: string;
  patient: string;
  amount: number;
};

interface Props {
  data?: ImageData[];
}

const defaultData: ImageData[] = [
  {
    id: 'd1m90',
    date: format(new Date(), "PP"),
    clinic: 'Demo General Hospital',
    imageType: 'CTS',
    patient: 'John Smith',
    amount: 150.0,
  },
  {
    id: '0vmf0',
    date: format(new Date(), "PP"),
    clinic: 'Demo Clinic',
    imageType: 'CTS',
    patient: 'Jack Huge',
    amount: 150.0,
  },
  {
    id: '0d1m2',
    date: format(new Date(), "PP"),
    clinic: 'Demo General Hospital',
    imageType: 'USG',
    patient: 'Martha Brown',
    amount: 150.0,
  },
];

const RecentImages: FC<Props> = ({ data = defaultData }) => {
  return (
    <React.Fragment>
      <ChartTitle>Recent Orders</ChartTitle>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Clinic</TableCell>
            <TableCell>Image Type</TableCell>
            <TableCell>Patient</TableCell>
            <TableCell align="right">Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.clinic}</TableCell>
              <TableCell>{row.imageType}</TableCell>
              <TableCell>{row.patient}</TableCell>
              <TableCell align="right">{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <More className={classes.seeMore}>
        <RouterButtonLink color="primary" to={DICOMS_ROUTE}>
          See more orders
        </RouterButtonLink>
      </More>
    </React.Fragment>
  );
};

export default RecentImages;
