import React, { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DicomListElement } from '../../types/Dicom';
import { dataFiledDef } from './DicomList';
import GridActions from './GridActions';
import { DicomCell } from './DicomCell';

const PREFIX = 'Row';

const classes = {
  root: `${PREFIX}-root`,
  actions: `${PREFIX}-actions`
};

const TopRow = styled(TableRow)({
  [`&.${classes.root}`]: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  [`& .${classes.actions}`]: {
    display: 'flex',
    flexDirection: 'row',
  },
});

interface Props {
  row: DicomListElement;
  columns: dataFiledDef[];
  descriptionColumns?: dataFiledDef[];
}

const Row: FC<Props> = ({ row, columns, descriptionColumns }) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TopRow className={classes.root} onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column) => (
          <DicomCell
            key={column.key}
            cellConfig={{ component: 'td', scope: 'row', align: 'left' }}
            value={row[column.key]}
            type={column.type}
          />
        ))}
        <TableCell className={classes.actions}>
          <GridActions dicomEl={row} />
        </TableCell>
      </TopRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin:1}}>
              <Typography variant="h6" gutterBottom component="div">
                Additional Information
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {descriptionColumns?.map((column) => (
                      <TableCell key={column.key} align="left">
                        {column.header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {descriptionColumns?.map((descriptionCol) => (
                      <DicomCell
                        key={descriptionCol.key}
                        cellConfig={{ component: 'td', scope: 'row', align: 'left' }}
                        value={row[descriptionCol.key]}
                        type={descriptionCol.type}
                      />
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
