import dcmjs from 'dcmjs';
import { APP_VERSION } from "../../version";

export type CreateSegmentationOptions = {
  includeSliceSpacing?: boolean;
  Manufacturer?: string;
  SoftwareVersions?: string;
  DeviceSerialNumber?: string;
  ManufacturerModelName?: string;
  SeriesDescription?: string;
  SeriesNumber?: string;
  ImageComments?: string;
  SeriesDate?: string;
  SeriesTime?: string;
  ContentDate?: string;
  ContentTime?: string;
  ContentQualification?: string;
  SOPInstanceUID?: string;
  SeriesInstanceUID?: string;
  ContentLabel?: string;
  ContentDescription?: string;
  ContentCreatorName?: string;
  StudyDescription?: string;
}

/**
 * _createSegFromImages - description
 *
 * @param  {Object[]} images    An array of the cornerstone image objects.
 * @param  {Boolean} isMultiframe Whether the images are multiframe.
 * @returns {Object}              The Seg derived dataSet.
 */
function createSegFromImages(images: any[], isMultiframe: boolean, options: CreateSegmentationOptions) {
  const datasets = [];

  const mergedOptions = generateOptions(options);

  if (isMultiframe) {
    const image = images[0];
    const arrayBuffer = image.imageFrame.pixelData.buffer;

    const dicomData = dcmjs.data.DicomMessage.readFile(arrayBuffer);
    const dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomData.dict);

    dataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(dicomData.meta);

    datasets.push(dataset);
  } else {
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      image._meta = {};
      datasets.push(image);
    }
  }

  const multiframe = dcmjs.normalizers.Normalizer.normalizeToDataset(datasets);

  return new dcmjs.derivations.Segmentation([multiframe], mergedOptions);
}

function generateOptions(options: CreateSegmentationOptions): CreateSegmentationOptions {
  return {
    includeSliceSpacing: true,
    Manufacturer: 'ZhivaAI',
    SoftwareVersions: APP_VERSION,
    SeriesDescription: "Segmentation",
    ContentLabel: "Segmentation",
    ContentDescription: "Segmentation",
    ContentCreatorName: "ziva.ai",
    ...options
  }
}

export default createSegFromImages;