/**
 * Returns the Authorization header as part of an Object.
 *
 * @export
 * @param {Object} [server={}]
 * @param {Object} [server.requestOptions]
 * @param {string|function} [server.requestOptions.auth]
 * @param {string|function} [server.requestOptions.cors]
 * @returns {Object} { Authorization }
 */
import { AuthType } from '../../../types/dicomServer';

export type AuthHeaders = {
  Authorization?: string;
  'Access-Control-Allow-Origin'?: string;
};

export default function getAuthorizationHeader({
  requestOptions,
}: {
  requestOptions?: {
    auth:
      | {
          type: AuthType;
          value: string;
        }
      | ((requestOptions: any) => string)
      | undefined;
    cors?: string;
  };
}): AuthHeaders {
  const headers: AuthHeaders = {};

  // Auth for a specific server
  if (requestOptions?.auth) {
    if (typeof requestOptions.auth === 'function') {
      // Custom Auth Header
      headers.Authorization = requestOptions.auth(requestOptions);
    } else {
      // HTTP Basic Auth (user:password)
      if (requestOptions.auth.type === 'basicAuth') {
        headers.Authorization = `Basic ${btoa(requestOptions.auth.value)}`;
      }
      if (requestOptions.auth.type === 'token' || requestOptions.auth.type === 'zhivaAuth') {
        headers.Authorization = `Bearer ${requestOptions.auth.value}`;
      }
    }
  }

  return headers;
}
