import * as React from 'react';
import { ModelServer, ModelServerSpec } from '../../../types/modelServer';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import copyTextToClipboard from '../../../utils/copyToClipboard';
import { useSnackbar } from "notistack";


interface Props {
  modelServer: ModelServer;
  formErrors: any;
}

function generateJSONStringFromModelServer(modelServer: ModelServer): string {
  const data: ModelServerSpec = {
    [modelServer.uuid as string]: {
      uri: `Your Model's URI`,
      task: modelServer.type,
      supports: [modelServer.supports],
    },
  };

  if (modelServer.dataFilter != null && modelServer.dataFilter.length > 0) {
    data[modelServer.uuid as string].dataFilter = modelServer.dataFilter.map((filter) => {
      const exportFilter: {
        title: string;
        dataKey: string;
        dataFilter?: {
          title: string;
          dataKey: string;
        }[];
      } = {
        dataKey: filter.dataKey,
        title: filter.title,
      };
      if (filter.dataFilter != null && filter.dataFilter.length > 0) {
        exportFilter.dataFilter = filter.dataFilter.map((childFilter) => ({
          title: childFilter.title,
          dataKey: childFilter.dataKey,
        }));
      }
      return exportFilter;
    });
  }
  return JSON.stringify(data, null, 2);
}

export default function CopyModelSetting({ modelServer, formErrors }: Props) {
  const isModelValid = Object.keys(formErrors).length === 0;
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = () => {
    copyTextToClipboard(generateJSONStringFromModelServer(modelServer), () => {
      enqueueSnackbar('Copied to clipboard', { variant: 'info', autoHideDuration: 1000 });
    });
  };

  return (
    <Tooltip title={`Copy model settings to clipboard`}>
      <IconButton disabled={!isModelValid} onClick={handleCopy} aria-label="copy to clipboard" size={'medium'}>
        <ContentPasteIcon fontSize={'inherit'} />
      </IconButton>
    </Tooltip>
  );
}
