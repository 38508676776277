import dcmjs from 'dcmjs';

export type SegmentationDataset = {
  dataset: any;
  isBitpacked: boolean;
  options: any;
  referencedDataset: any;
  referencedDatasets: any;
};

export type FillSegmentationOptions = {
  rleEncode: boolean;
  includeSliceSpacing: boolean;
};
/**
 * _createSegFromImages - description
 *
 * @param  {Object[]} segDataset    DicomJS generated Segmentation Object
 * @param  {Boolean} selectedLabelmaps3DCopy labelmap3D
 * @param  {FillSegmentationOptions} options fill segmentation options
 * @returns {Object}              FillSegmentationOptions
 */
export default function fillSegmentation(
  segDataset: any,
  selectedLabelmaps3DCopy: any[],
  options: FillSegmentationOptions = { rleEncode: false, includeSliceSpacing: true },
) {
  return dcmjs.adapters.Cornerstone.Segmentation.fillSegmentation(
    segDataset,
    selectedLabelmaps3DCopy.filter((el) => el != null),
    options,
  );
}
