import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
// import CircularProgress from '@mui/material/CircularProgress';
// import IconButton from '@mui/material/IconButton';
// import DownloadIcon from '@mui/icons-material/CloudDownload';
// import DownloadDoneIcon from '@mui/icons-material/CloudDone';
import Tooltip from '@mui/material/Tooltip';
import OpenIcon from '@mui/icons-material/Launch';
// import useFetch from 'react-fetch-hook';
// import { useRecoilState } from 'recoil';
// import { dicomListSelector } from '../../recoil/dicoms';
import ReactIconLink from '../RouterLink/RouterIconLink';
import { DicomListElement } from '../../types/Dicom';
import { darkTheme } from '../Layout/theme';
import { getStudyRoute } from "../../utils/routes";

// const SuccessIcon = styled(DownloadDoneIcon)`
//   color: #50c467;
//   margin: 12px;
// `;

const StyledOpenIcon = styled(OpenIcon)`
  color: ${darkTheme.palette.secondary.light};
`;

interface Props {
  dicomEl: DicomListElement;
  children?: ReactNode;
}

const GridActions: FC<Props> = ({ dicomEl, children }) => {
  // const [dicomsList] = useRecoilState(dicomListSelector);
  //
  // const onDownloadClick = () => {
  //   setFetchImage(true);
  // };
  //
  // const storedOffline = dicomsList.findIndex((el) => el.id === dicomEl.id) !== -1 && false;

  const studyLink = getStudyRoute(dicomEl.StudyInstanceUID)

  return (
    <React.Fragment>
      <ReactIconLink to={studyLink} aria-label="store image" color="secondary">
        <Tooltip title={'Open Study'}>
          <StyledOpenIcon />
        </Tooltip>
      </ReactIconLink>
      {/*{storedOffline && <SuccessIcon />}*/}
      {/*{!storedOffline && (*/}
      {/*  <IconButton aria-label="store image" onClick={onDownloadClick} size="large">*/}
      {/*    {isLoading ? <CircularProgress size={24} /> : <DownloadIcon />}*/}
      {/*  </IconButton>*/}
      {/*)}*/}
    </React.Fragment>
  );
};

export default GridActions;
