import { AtomEffect } from "recoil";
import { defaultValuesPerKey } from "../../configs/dicomServer";

export const localStorageEffect =
  (key: string): AtomEffect<any> =>
    ({ setSelf, onSet, trigger }) => {
      if (trigger === 'get') {
        const storedVal = localStorage.getItem(key) != null ? JSON.parse(localStorage.getItem(key) || '{}') : null;
        setSelf(localStorage.getItem(key) != null ? storedVal : defaultValuesPerKey[key]);
      }

      onSet((newValue, _, isReset) => {
        isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
      });
    };