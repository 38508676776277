import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
// @ts-ignore
import { SketchPicker } from 'react-color';
import { styled } from '@mui/material/styles';

interface Props {
  onChange: (color: string) => void;
  selectedColor: string;
}

const PREFIX = 'ColorPicker';

const classes = {
  color: `${PREFIX}-color`,
  swatch: `${PREFIX}-swatch`,
  popover: `${PREFIX}-popover`,
  cover: `${PREFIX}-cover`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.color}`]: {
    width: '54px',
    height: '14px',
    borderRadius: '2px',
  },

  [`& .${classes.swatch}`]: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },

  [`& .${classes.cover}`]: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));

type Color = { rgb: { r: number; g: number; b: number } };
export const matchColors = /rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
export const defaultColor: Color['rgb'] = { r: 0, g: 100, b: 255 };
export const defaultStringColor = 'rgb(0,100,255)';

export default function ColorPicker({ onChange, selectedColor = defaultStringColor }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const selectedColorParts = matchColors.exec(selectedColor);
  const [color, setColor] = useState<Color>({
    rgb: {
      r: Number(selectedColorParts ? selectedColorParts[1] : defaultColor.r),
      g: Number(selectedColorParts ? selectedColorParts[2] : defaultColor.g),
      b: Number(selectedColorParts ? selectedColorParts[3] : defaultColor.b),
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (color: Color) => {
    setColor(color);
  };

  const handleChangeComplete = (color: Color) => {
    setColor(color);
    onChange(`rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`);
  }
  const open = Boolean(anchorEl);
  const id = open ? 'color-picker-popover' : undefined;

  return (
    <Root>
      <Button className={classes.swatch} onClick={handleClick} aria-describedby={id} variant="text">
        <div
          className={classes.color}
          style={{
            background: `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`,
          }}
        />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <SketchPicker color={color} onChange={handleChange}
                      onChangeComplete={ handleChangeComplete }/>
      </Popover>
    </Root>
  );
}
