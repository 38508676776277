import React, { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { emptyServerSetting } from '../../../configs/modelServer';
import { modelServerList } from '../../../recoil/modelServer';
import { ModelServer } from '../../../types/modelServer';
import { ModelTypes, ModelSupportedPaths, modelSchema } from './ModelSchema';
import { MODALITIES } from '../../../utils/constants';
import { Modality } from '../../../types/Dicom';
import { useSnackbar } from 'notistack';
import ModelDataFilter from './ModelDataFilter';
import { getSettingsModelsRoutes } from '../../../utils/routes';
import { useHistory, useParams } from 'react-router-dom';
import CopyModelSetting from './CopyModelSetting';
import useDebouncedEffect from "../../../hooks/useDeboundedEffect";
import SegmentationClasses from "./SegmentationClasses";

interface Props {}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ModelSettings: FC<Props> = () => {
  const { modelUID } = useParams<{ modelUID: string }>();
  const history = useHistory();
  const [serverList, setServerList] = useRecoilState(modelServerList);
  const uriSelectedServer = serverList.find((el) => el.id === modelUID);
  const { enqueueSnackbar } = useSnackbar();
  const [modelServer, setModelServer] = useState<ModelServer>(
    uriSelectedServer || serverList[0] || emptyServerSetting(),
  );
  const [formErrors, setFormErrors] = useState<any>({});
  const [hasChanges, setHasChanges] = useState(false);

  const handleServerChange = (event: SelectChangeEvent) => {
    const selectedServ = serverList.find((el) => el.id === (event.target.value as string));
    if (selectedServ != null) {
      setModelServer(selectedServ);
      setHasChanges(true);
    }
  };

  const createNewModelServer = () => {
    setModelServer(emptyServerSetting());
    setHasChanges(false);
  };

  useDebouncedEffect(() => {
    modelSchema
      .validate(modelServer, { abortEarly: false })
      .then(() => {
        setFormErrors({});
      })
      .catch((err) => {
        const currErrors = err.inner
          .map((field: any) => ({ key: field.path, message: field.message }))
          .reduce((acc: any, fieldErr: { key: string; message: string }) => {
            acc[fieldErr.key] = fieldErr.message;
            return acc;
          }, {});
        setFormErrors(currErrors);
      });
  }, [modelServer], 100);

  useEffect(() => {
    if (modelServer.id != null && modelServer.id !== modelUID) {
      history.push(getSettingsModelsRoutes(modelServer.id));
    }
  }, [modelServer.id, modelUID]);

  if (modelServer == null) {
    createNewModelServer();
    return null;
  }

  const handleSaveServer = () => {
    const filteredList = serverList.filter((el) => el.id !== modelServer.id);
    setServerList([...filteredList, modelServer]);
    setHasChanges(false);
    enqueueSnackbar('API settings updated', { variant: 'success', autoHideDuration: 1000 });
  };

  const removeCurrentServer = () => {
    if (modelServer.removable) {
      const filteredList = serverList.filter((el) => el.id !== modelServer.id);
      setServerList(filteredList);
      setModelServer(filteredList[0]);
      setHasChanges(false);
      enqueueSnackbar('API removed', { variant: 'success', autoHideDuration: 1000 });
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasChanges(true);
    setModelServer({
      ...modelServer,
      [event.target.name]: event.target.value,
    });
  };

  const handleDataFilterChange = (newFilter: ModelServer['dataFilter']) => {
    setHasChanges(true);
    setModelServer({
      ...modelServer,
      dataFilter: newFilter,
    });
  };

  const handleSegmentationClassesChange = (newClasses: ModelServer['segmentationClasses']) => {
    setHasChanges(true);
    setModelServer({
      ...modelServer,
      segmentationClasses: newClasses,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setHasChanges(true);
    setModelServer({
      ...modelServer,
      [event.target.name]: event.target.value,
    });
  };

  const isEditable = !!modelServer.editable;
  const formHasError = Object.keys(formErrors).length > 0;

  return (
    <React.Fragment>
      <Box component="form" noValidate autoComplete="off">
        <Grid container spacing={3} sx={{ p: 4 }}>
          <Grid item xs={10}>
            <Typography variant="h6" gutterBottom>
              Model Settings
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControl fullWidth>
              <InputLabel id="server-select-label">Select Model</InputLabel>
              <Select
                labelId="server-select-label"
                id="server-select"
                value={modelServer.id}
                label="Selected Model"
                onChange={handleServerChange}
              >
                {serverList.map((serverEl) => (
                  <MenuItem value={serverEl.id} key={serverEl.id}>
                    {serverEl.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <CopyModelSetting modelServer={modelServer} formErrors={formErrors} />
            <Tooltip title={`Delete selected model`}>
              <IconButton
                aria-label="delete"
                color={'error'}
                size={'large'}
                disabled={!modelServer.removable}
                onClick={removeCurrentServer}
              >
                <DeleteForeverIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
          {modelServer.isDemo && (
            <Grid item xs={12}>
              <Typography variant={'h5'} color={'error'}>
                This is a demo model only, it won't be available outside demo server.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              required
              error={formErrors['name'] != null}
              helperText={formErrors['name'] != null ? formErrors['name'] : null}
              disabled={!isEditable}
              inputProps={{ inputMode: 'text', pattern: '[a-zA-Z0-9_.-]+' }}
              id="name"
              name="name"
              value={modelServer.name}
              onChange={handleFieldChange}
              label="Model Name"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              error={formErrors['url'] != null}
              helperText={formErrors['url'] != null ? formErrors['url'] : null}
              disabled={!isEditable}
              id="url"
              name="url"
              value={modelServer.url}
              onChange={handleFieldChange}
              label="Server URL"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              error={formErrors['uuid'] != null}
              helperText={formErrors['uuid'] != null ? formErrors['uuid'] : null}
              disabled={!isEditable}
              inputProps={{
                inputMode: 'text',
                pattern: '[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}',
              }}
              id="uuid"
              name="uuid"
              value={modelServer.uuid}
              onChange={handleFieldChange}
              label="Model UUID (uuidv4)"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="server-type-label">Model Type</InputLabel>
              <Select
                required
                error={formErrors['type'] != null}
                labelId="server-type-label"
                id="server-type"
                disabled={!isEditable}
                value={modelServer.type}
                label="Model Type"
                name="type"
                onChange={handleSelectChange}
              >
                {Object.values(ModelTypes).map((serverEl) => (
                  <MenuItem value={serverEl} key={serverEl}>
                    {serverEl}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="server-supports-label">Supported Path</InputLabel>
              <Select
                required
                labelId="server-supports-label"
                id="server-supports"
                error={formErrors['supports'] != null}
                value={modelServer.supports}
                label="Supported Paths"
                disabled={!isEditable}
                name="supports"
                onChange={handleSelectChange}
              >
                {Object.values(ModelSupportedPaths).map((serverEl) => (
                  <MenuItem value={serverEl} key={serverEl}>
                    {serverEl}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="modalities-label">Modalities</InputLabel>
              <Select
                labelId="modalities-label"
                id="modalities-chip"
                name="modalities"
                multiple
                disabled={!isEditable}
                value={(modelServer.modalities || []) as any}
                onChange={handleSelectChange}
                input={<OutlinedInput id="modalities" label="Modality" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {(selected as any).map((value: Modality) => (
                      <Chip key={value} label={MODALITIES[value]} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Object.entries(MODALITIES).map(([modKey, modName]) => (
                  <MenuItem key={modKey} value={modKey}>
                    {modName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <ModelDataFilter
              onChange={handleDataFilterChange}
              dataFilter={modelServer.dataFilter}
              filterType={modelServer.supports}
              formErrors={formErrors}
              isEditable={isEditable}
            />
          </Grid>
          <Grid item xs={12}>
            <SegmentationClasses
              onChange={handleSegmentationClassesChange}
              segmentationClasses={modelServer.segmentationClasses}
              formErrors={formErrors}
              isEditable={isEditable}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button color={'warning'} variant="outlined" onClick={createNewModelServer} sx={{ mt: 1, ml: 1 }}>
            {'Create New Model API'}
          </Button>
          <Button
            disabled={!hasChanges || formHasError}
            variant="contained"
            onClick={handleSaveServer}
            sx={{ mt: 1, ml: 1 }}
          >
            {'Save Settings'}
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ModelSettings;
