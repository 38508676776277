import { DicomField } from '../types/dicomServer';
import { Modality, SegmentationObj, SeriesObj } from '../types/Dicom';
import { COMMON_DICOM_CODES } from './constants';

export function getDicomCodeName(code: string): string {
  // return DicomDictionary[code];
  return '';
}

export function getStringValue(fieldData: DicomField, defaultValue: string = ''): string {
  if (!fieldData) {
    return defaultValue;
  }
  // Value is not present if the attribute has a zero length value
  if (!fieldData.Value) {
    return defaultValue;
  }
  // Sanity check to make sure we have at least one entry in the array.
  if (!fieldData.Value.length) {
    return defaultValue;
  }
  // Join the array together separated by backslash
  // NOTE: Orthanc does not correctly split values into an array so the join is a no-op
  return fieldData.Value.join('\\');
}

export function getNumberValue(fieldData: DicomField, defaultValue: number = 0): number {
  if (!fieldData) {
    return defaultValue;
  }
  // Value is not present if the attribute has a zero length value
  if (!fieldData.Value) {
    return defaultValue;
  }
  // Sanity check to make sure we have at least one entry in the array.
  if (!fieldData.Value.length) {
    return defaultValue;
  }
  // Join the array together separated by backslash
  // NOTE: Orthanc does not correctly split values into an array so the join is a no-op
  return parseFloat(fieldData.Value[0]);
}

export function getArrayValue(fieldData: DicomField, defaultValue: any[] = []): any[] {
  if (!fieldData) {
    return defaultValue;
  }
  // Value is not present if the attribute has a zero length value
  if (!fieldData.Value) {
    return defaultValue;
  }
  // Sanity check to make sure we have at least one entry in the array.
  if (!fieldData.Value.length) {
    return defaultValue;
  }
  return fieldData.Value;
}

export function getPatientName(fieldData: DicomField, defaultValue: string = ''): string {
  if (!fieldData) {
    return defaultValue;
  }
  // Value is not present if the attribute has a zero length value
  if (!fieldData.Value) {
    return defaultValue;
  }
  // Sanity check to make sure we have at least one entry in the array.
  if (!fieldData.Value.length) {
    return defaultValue;
  }
  // Return the Alphabetic component group
  if (fieldData.Value[0].Alphabetic) {
    return fieldData.Value[0].Alphabetic;
  }
  // Orthanc does not return PN properly so this is a temporary workaround
  return fieldData.Value[0];
}

export function isSeriesSegmentation(series: SeriesObj) {
  if ((Array.isArray(series.Modalities) && series.Modalities.includes('SEG')) || series.Modalities === 'SEG') {
    return true;
  }
  return false;
}

export function getReferencedSeries({
  seriesSeqField,
}: {
  seriesSeqField?: DicomField;
}): SegmentationObj['ReferencedSeriesSequence'] {
  if (!seriesSeqField) {
    return [];
  }
  // Value is not present if the attribute has a zero length value
  if (!seriesSeqField.Value) {
    return [];
  }
  // Sanity check to make sure we have at least one entry in the array.
  if (!seriesSeqField.Value.length) {
    return [];
  }
  return seriesSeqField.Value.map((seriesSegData) => ({
    SeriesInstanceUID: getStringValue(seriesSegData[COMMON_DICOM_CODES.SERIES_INSTANCE_UID]),
  }));
}

export function getSegmentSequence({
  segmentSeqField,
}: {
  segmentSeqField?: DicomField;
}): SegmentationObj['SegmentSequence'] {
  if (!segmentSeqField) {
    return [];
  }
  // Value is not present if the attribute has a zero length value
  if (!segmentSeqField.Value) {
    return [];
  }
  // Sanity check to make sure we have at least one entry in the array.
  if (!segmentSeqField.Value.length) {
    return [];
  }
  return segmentSeqField.Value.map((segData) => ({
    SegmentNumber: getNumberValue(segData[COMMON_DICOM_CODES.SEGMENT_NUMBER]),
    SegmentLabel: getStringValue(segData[COMMON_DICOM_CODES.SEGMENT_LABEL]),
    SegmentDescription: getStringValue(segData[COMMON_DICOM_CODES.SEGMENT_DESCRIPTION]),
    SegmentAlgorithmType: getStringValue(segData[COMMON_DICOM_CODES.SEGMENT_ALGORITHM_TYPE]),
    CIELabValue: getStringValue(segData[COMMON_DICOM_CODES.CIELAB_VALUE]),
  }));
}

export function getSegmentationFramesDefinition({
  segmentFramesField,
}: {
  segmentFramesField: DicomField;
}): SegmentationObj['PerFrameFunctionalGroupsSequence'] {
  return getArrayValue(segmentFramesField).map((segData) => ({
    DerivationImageSequence: getArrayValue(segData[COMMON_DICOM_CODES.DERIVATION_IMAGE_SEQUENCE]).map(
      (derivationData) => ({
        SourceImageSequence: getArrayValue(derivationData[COMMON_DICOM_CODES.SOURCE_IMAGE_SEQUENCE]).map(
          (sourceData) => ({
            ReferencedSOPInstanceUID: getStringValue(sourceData[COMMON_DICOM_CODES.REFERENCED_SOP_INSTANCE_UID]),
          }),
        ),
      }),
    ),
    FrameContentSequence: getArrayValue(segData[COMMON_DICOM_CODES.FRAME_CONTENT_SEQUENCE]).map((frameContent) => ({
      DimensionIndexValues: getStringValue(frameContent[COMMON_DICOM_CODES.DIMENSION_INDEX_VALUES]),
    })),
    SegmentIdentificationSequence: getArrayValue(segData[COMMON_DICOM_CODES.SEGMENTATION_IDENTIFICATION_SEQUENCE]).map(
      (segmentId) => ({
        ReferencedSegmentNumber: getStringValue(segmentId[COMMON_DICOM_CODES.REFERENCED_SEGMENT_NUMBER]),
      }),
    ),
  }));
}

export function getModalities({
  modalityField,
  modalitiesAvailableField,
  keepSeparate = false,
}: {
  modalityField?: DicomField;
  modalitiesAvailableField?: DicomField;
  keepSeparate?: boolean;
}): Modality[] | Modality {
  const modalities: { vr: string; Value: any[] } = {
    vr: 'CS',
    Value: [],
  };

  if (modalityField != null) {
    modalities.vr = modalityField.vr;
    modalities.Value =
      modalityField.Value != null ? [...modalities.Value, ...modalityField.Value] : [...modalities.Value];
  }

  if (modalitiesAvailableField) {
    if (modalities.vr && modalities.vr === modalitiesAvailableField.vr) {
      modalities.Value =
        modalitiesAvailableField.Value != null
          ? [...modalities.Value, ...modalitiesAvailableField.Value]
          : [...modalities.Value];
    } else {
      modalities.vr = modalitiesAvailableField.vr;
      modalities.Value =
        modalitiesAvailableField.Value != null
          ? [...modalities.Value, ...modalitiesAvailableField.Value]
          : [...modalities.Value];
    }
  }

  modalities.Value = Array.from(new Set(modalities.Value));

  if (keepSeparate === false) {
    return getStringValue(modalities) as Modality;
  }

  return modalities.Value;
}
