import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import KeyIcon from '@mui/icons-material/Key';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DataFilter, ModelServer } from '../../../types/modelServer';
import { ModelSupportedPaths } from './ModelSchema';
import Paper from '@mui/material/Paper';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@mui/material/Typography';

interface Props {
  onChange: (dataFilter: ModelServer['dataFilter']) => void;
  dataFilter: ModelServer['dataFilter'];
  filterType: ModelServer['supports'];
  formErrors: any;
  isEditable: boolean;
}

function createNewDataFilter(): DataFilter {
  return {
    uuid: uuidv4(),
    title: '',
    dataKey: '',
  };
}

function canHaveFiltersAtLevel(filterType: ModelServer['supports'], level: number): boolean {
  if (filterType === ModelSupportedPaths.INSTANCES) {
    return false;
  }

  if (filterType === ModelSupportedPaths.SERIES && level > 1) {
    return false;
  }

  return true;
}

function getFilterTypeName(filterType: ModelServer['supports']): string {
  switch (filterType) {
    case ModelSupportedPaths.STUDIES:
      return 'Series';
    case ModelSupportedPaths.SERIES:
      return 'Instance';
    default:
      return 'Input';
  }
}

export default function ModelDataFilter({ onChange, dataFilter, filterType, formErrors, isEditable }: Props) {
  const [open, setOpen] = React.useState<string[]>([]);

  const handleClick = (filterUUID: string) => {
    setOpen(open.includes(filterUUID) ? open.filter((el) => el !== filterUUID) : [...open, filterUUID]);
  };

  if (!canHaveFiltersAtLevel(filterType, 1) && (dataFilter == null || dataFilter.length === 0)) {
    return null;
  }

  const is2ndLevelDisabled = filterType !== ModelSupportedPaths.STUDIES || !isEditable;
  const is1stLevelDisabled = filterType === ModelSupportedPaths.INSTANCES || !isEditable;

  const removeFilter = (filterUUID: string, parentUUID?: string) => {
    if (parentUUID == null) {
      onChange([...(dataFilter || []).filter((el) => el.uuid !== filterUUID)]);
    }

    const selectedParent = (dataFilter || []).filter((el) => el.uuid === parentUUID)[0] as DataFilter;
    if (selectedParent != null) {
      onChange(
        (dataFilter || []).map((el) => {
          if (el.uuid !== parentUUID) {
            return el;
          }
          return {
            ...el,
            dataFilter: [...(el.dataFilter || []).filter((childEl) => childEl.uuid !== filterUUID)],
          };
        }),
      );
    }
  };

  const addNewFilter = () => {
    onChange([...(dataFilter || []), createNewDataFilter()]);
  };

  const addNewFilterTo = (filterUUID: string) => {
    const currSelectedFilter = dataFilter?.find((filter) => filter.uuid === filterUUID);
    if (currSelectedFilter != null) {
      onChange(
        (dataFilter || []).map((el) => {
          if (el.uuid !== filterUUID) {
            return el;
          }

          return {
            ...currSelectedFilter,
            dataFilter: [...(currSelectedFilter.dataFilter || []), createNewDataFilter()],
          };
        }),
      );
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const fieldPath = event.target.name.split('/');

    if (fieldPath[1] === 'null') {
      const selectedFilter = (dataFilter || []).filter((el) => el.uuid === fieldPath[0])[0];
      onChange(
        (dataFilter || []).map((el) => {
          if (el.uuid !== fieldPath[0]) {
            return el;
          }

          return {
            ...selectedFilter,
            [fieldPath[2]]: newValue,
          };
        }),
      );
    } else {
      const selectedParent = (dataFilter || []).filter((el) => el.uuid === fieldPath[1])[0];
      if (selectedParent != null) {
        const selectedFilter = (selectedParent.dataFilter || []).filter((el) => el.uuid === fieldPath[0])[0];
        onChange(
          (dataFilter || []).map((el) => {
            if (el.uuid !== fieldPath[1]) {
              return el;
            }

            return {
              ...selectedParent,
              dataFilter: (selectedParent.dataFilter || []).map((childEl) => {
                if (childEl.uuid !== fieldPath[0]) {
                  return childEl;
                }

                return {
                  ...selectedFilter,
                  [fieldPath[2]]: newValue,
                };
              }),
            };
          }),
        );
      }
    }
  };

  return (
    <Paper variant={'outlined'}>
      <List
        sx={{ width: '100%', maxWidth: '500px', pl: 1 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {`${getFilterTypeName(filterType)} data filters`}
            {filterType !== ModelSupportedPaths.INSTANCES && (
              <Tooltip
                title={
                  filterType === ModelSupportedPaths.STUDIES
                    ? `List of required series for the model's inference process, if model requires all series just leave this list empty`
                    : `List of required instances for the model's inference process, if model requires all instances just leave this list empty`
                }
              >
                <IconButton aria-label="remove-measurement" sx={{ zIndex: 1 }}>
                  <HelpOutlineOutlinedIcon fontSize={'small'} />
                </IconButton>
              </Tooltip>
            )}
          </ListSubheader>
        }
      >
        {dataFilter != null &&
          dataFilter.length > 0 &&
          dataFilter.map((filterEl, filterIdx) => (
            <React.Fragment key={filterEl.uuid}>
              <ListItem style={{ border: '1px solid rgba(255,255,255,0.3)' }} sx={{ mb: 1, mt: 1 }}>
                <ListItemText
                  disableTypography
                  primary={
                    <React.Fragment>
                      <KeyIcon sx={{ mr: 1 }} />
                      <TextField
                        placeholder="Input Key"
                        variant="standard"
                        size="small"
                        style={{ maxWidth: '200px' }}
                        name={`${filterEl.uuid}/null/dataKey`}
                        onChange={handleFieldChange}
                        value={filterEl.dataKey}
                        error={formErrors[`dataFilter[${filterIdx}].dataKey`] != null}
                        helperText={
                          formErrors[`dataFilter[${filterIdx}].dataKey`] != null
                            ? formErrors[`dataFilter[${filterIdx}].dataKey`]
                            : null
                        }
                        disabled={is1stLevelDisabled}
                      />
                      <Tooltip
                        title={`This key is used by the model to select assigned ${getFilterTypeName(
                          filterType,
                        )} for inference`}
                      >
                        <IconButton aria-label="remove-measurement" sx={{ zIndex: 1 }}>
                          <HelpOutlineOutlinedIcon fontSize={'small'} />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <TextField
                        placeholder="Title"
                        variant="standard"
                        multiline
                        fullWidth
                        name={`${filterEl.uuid}/null/title`}
                        onChange={handleFieldChange}
                        value={filterEl.title}
                        error={formErrors[`dataFilter[${filterIdx}].title`] != null}
                        helperText={
                          formErrors[`dataFilter[${filterIdx}].title`] != null
                            ? formErrors[`dataFilter[${filterIdx}].title`]
                            : null
                        }
                        disabled={is1stLevelDisabled}
                        inputProps={{ style: { fontSize: '0.8rem' } }}
                        sx={{ mt: 1 }}
                      />
                      <TextField
                        placeholder="Description"
                        variant="standard"
                        multiline
                        fullWidth
                        name={`${filterEl.uuid}/null/description`}
                        onChange={handleFieldChange}
                        value={filterEl.description}
                        error={formErrors[`dataFilter[${filterIdx}].description`] != null}
                        helperText={
                          formErrors[`dataFilter[${filterIdx}].description`] != null
                            ? formErrors[`dataFilter[${filterIdx}].description`]
                            : null
                        }
                        disabled={is1stLevelDisabled}
                        inputProps={{ style: { fontSize: '0.8rem' } }}
                        sx={{ mt: 1 }}
                      />
                    </React.Fragment>
                  }
                />
                <Tooltip title={'Remove filter'}>
                  <IconButton aria-label="delete" color={'error'} onClick={() => removeFilter(filterEl.uuid)}>
                    <DeleteForeverIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                {canHaveFiltersAtLevel(filterType, 2) ? (
                  open.includes(filterEl.uuid) ? (
                    <Tooltip title={'Show instance filter'}>
                      <IconButton aria-label="expand" onClick={() => handleClick(filterEl.uuid)}>
                        <ExpandLess />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title={'Hide instance filter'}>
                      <IconButton aria-label="contract" onClick={() => handleClick(filterEl.uuid)}>
                        <ExpandMore />
                      </IconButton>
                    </Tooltip>
                  )
                ) : null}
              </ListItem>
              <Collapse
                in={open.includes(filterEl.uuid) || filterType !== ModelSupportedPaths.STUDIES}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding sx={{ ml: 6 }}>
                  {filterEl.dataFilter != null &&
                    filterEl.dataFilter.length > 0 &&
                    filterEl.dataFilter.map((nestedFilter, nestedFilterIdx) => (
                      <React.Fragment key={nestedFilter.uuid}>
                        <ListItem style={{ border: '1px solid rgba(255,255,255,0.3)' }} sx={{ mb: 1 }}>
                          <ListItemText
                            disableTypography
                            primary={
                              <React.Fragment>
                                <KeyIcon sx={{ mr: 1 }} />
                                <TextField
                                  placeholder="Field Key"
                                  variant="standard"
                                  size="small"
                                  style={{ maxWidth: '200px' }}
                                  name={`${nestedFilter.uuid}/${filterEl.uuid}/dataKey`}
                                  onChange={handleFieldChange}
                                  value={nestedFilter.dataKey}
                                  error={
                                    formErrors[`dataFilter[${filterIdx}].dataFilter[${nestedFilterIdx}].dataKey`] !=
                                    null
                                  }
                                  helperText={
                                    formErrors[`dataFilter[${filterIdx}].dataFilter[${nestedFilterIdx}].dataKey`] !=
                                    null
                                      ? formErrors[`dataFilter[${filterIdx}].dataFilter[${nestedFilterIdx}].dataKey`]
                                      : null
                                  }
                                  disabled={is2ndLevelDisabled}
                                />
                                <Tooltip
                                  title={'This key is used by the model to select assigned instance for inference.'}
                                >
                                  <IconButton aria-label="remove-measurement" sx={{ zIndex: 1 }}>
                                    <HelpOutlineOutlinedIcon fontSize={'small'} />
                                  </IconButton>
                                </Tooltip>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <TextField
                                  placeholder="Title"
                                  variant="standard"
                                  multiline
                                  name={`${nestedFilter.uuid}/${filterEl.uuid}/title`}
                                  onChange={handleFieldChange}
                                  value={nestedFilter.title}
                                  error={
                                    formErrors[`dataFilter[${filterIdx}].dataFilter[${nestedFilterIdx}].title`] != null
                                  }
                                  helperText={
                                    formErrors[`dataFilter[${filterIdx}].dataFilter[${nestedFilterIdx}].title`] != null
                                      ? formErrors[`dataFilter[${filterIdx}].dataFilter[${nestedFilterIdx}].title`]
                                      : null
                                  }
                                  disabled={is2ndLevelDisabled}
                                  fullWidth
                                  inputProps={{ style: { fontSize: '0.8rem' } }}
                                  sx={{ mt: 1 }}
                                />
                                <TextField
                                  placeholder="Description"
                                  variant="standard"
                                  multiline
                                  name={`${nestedFilter.uuid}/${filterEl.uuid}/description`}
                                  onChange={handleFieldChange}
                                  value={nestedFilter.description}
                                  error={
                                    formErrors[`dataFilter[${filterIdx}].dataFilter[${nestedFilterIdx}].description`] !=
                                    null
                                  }
                                  helperText={
                                    formErrors[`dataFilter[${filterIdx}].dataFilter[${nestedFilterIdx}].description`] !=
                                    null
                                      ? formErrors[
                                          `dataFilter[${filterIdx}].dataFilter[${nestedFilterIdx}].description`
                                        ]
                                      : null
                                  }
                                  disabled={is2ndLevelDisabled}
                                  fullWidth
                                  inputProps={{ style: { fontSize: '0.8rem' } }}
                                  sx={{ mt: 1 }}
                                />
                              </React.Fragment>
                            }
                          />
                          <Tooltip title={'Remove filter'}>
                            <IconButton
                              aria-label="delete"
                              color={'error'}
                              onClick={() => removeFilter(nestedFilter.uuid, filterEl.uuid)}
                            >
                              <DeleteForeverIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      </React.Fragment>
                    ))}
                  {formErrors[`dataFilter[${filterIdx}].dataFilter`] &&
                    filterEl.dataFilter != null &&
                    filterEl.dataFilter.length > 0 && (
                      <Typography color={'error'} variant={'caption'}>
                        {formErrors[`dataFilter[${filterIdx}].dataFilter`]}
                      </Typography>
                    )}
                  {canHaveFiltersAtLevel(filterType, 2) && (
                    <ListItemButton onClick={() => addNewFilterTo(filterEl.uuid)}>
                      <ListItemText primary="Add new filter" />
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </ListItemButton>
                  )}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        {formErrors[`dataFilter`] && (
          <Typography color={'error'} variant={'caption'}>
            {formErrors[`dataFilter`]}
          </Typography>
        )}
        {canHaveFiltersAtLevel(filterType, 1) && (
          <ListItemButton onClick={addNewFilter} disabled={!isEditable}>
            <ListItemText primary="Add new filter" />
            <IconButton>
              <AddIcon />
            </IconButton>
          </ListItemButton>
        )}
      </List>
    </Paper>
  );
}
