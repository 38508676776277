import React, { FC } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import AirplayIcon from '@mui/icons-material/Airplay';
import HomeIcon from '@mui/icons-material/Home';
import { DASHBOARD_ROUTE, DICOMS_ROUTE } from "../../utils/routes";
import RouterButtonNavLink from "../RouterLink/RouterBottomNavLink";
import { useRouteMatch } from "react-router-dom";

interface Props {
  handleDrawerOpen: () => void;
  drawerOpen: boolean;
}

const BottomNav: FC<Props> = ({ handleDrawerOpen, drawerOpen }) => {
  const isDicomsRoute = useRouteMatch({
    path: DICOMS_ROUTE,
    exact: true,
  });
  const isDashboardRoute = useRouteMatch({
    path: DASHBOARD_ROUTE,
    exact: true,
  });

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation sx={{ width: '100vw' }} showLabels>
        <BottomNavigationAction onClick={handleDrawerOpen} aria-selected={true} label="Menu" icon={<MenuIcon />} />
        <RouterButtonNavLink to={DICOMS_ROUTE} label="Studies" icon={<AirplayIcon color={isDicomsRoute ? 'action' : undefined}/>} />
        <RouterButtonNavLink to={DASHBOARD_ROUTE} label="Home" icon={<HomeIcon  color={isDashboardRoute ? 'action' : undefined}/>} />
        <BottomNavigationAction label="Profile" value="folder" icon={<AccountBoxOutlinedIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
