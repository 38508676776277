import React, { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useRecoilState } from 'recoil';
import { dicomServerList, dicomServerSelector } from '../../../recoil/dicomServer';
import { AuthType, DicomServer } from '../../../types/dicomServer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { emptyServerSetting } from '../../../configs/dicomServer';
import { serverSchema } from './ServerSchema';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { getSettingsServerRoutes, OAUTH_ROUTE } from '../../../utils/routes';
import useDebouncedEffect from '../../../hooks/useDeboundedEffect';
import { parseJwt } from '../../../utils/jwt';
import { TokenService } from '../../../services/TokenService/JWTTokenService';
import { AuthToken } from "../../../types/JWT";

interface Props {}

const ServerSettings: FC<Props> = (props) => {
  const { serverUID } = useParams<{ serverUID: string }>();
  const history = useHistory();
  const [storedDicomServer, setStoredDicomServer] = useRecoilState<DicomServer>(dicomServerSelector);
  const { enqueueSnackbar } = useSnackbar();
  const [serverList, setServerList] = useRecoilState(dicomServerList);
  const uriSelectedServer = serverList.find((el) => el.id === serverUID);
  const [dicomServer, setDicomServer] = useState<DicomServer>(
    uriSelectedServer || storedDicomServer || emptyServerSetting(),
  );
  const [formErrors, setFormErrors] = useState<any>({});
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const handleServerChange = (event: SelectChangeEvent) => {
    const selectedServ = serverList.find((el) => el.id === (event.target.value as string));
    if (selectedServ != null) {
      setDicomServer(selectedServ);
      setHasChanges(true);
    }
  };

  useDebouncedEffect(
    () => {
      serverSchema
        .validate(dicomServer, { abortEarly: false })
        .then(() => {
          setFormErrors({});
        })
        .catch((err) => {
          const currErrors = err.inner
            .map((field: any) => ({ key: field.path, message: field.message }))
            .reduce((acc: any, fieldErr: { key: string; message: string }) => {
              acc[fieldErr.key] = fieldErr.message;
              return acc;
            }, {});
          setFormErrors(currErrors);
        });
    },
    [dicomServer],
    100,
  );

  useEffect(() => {
    if (dicomServer.id != null && dicomServer.id !== serverUID) {
      history.push(getSettingsServerRoutes(dicomServer.id));
    }
  }, [dicomServer.id, serverUID]);

  const handleSaveServer = () => {
    setStoredDicomServer(dicomServer);
    setHasChanges(false);
    enqueueSnackbar('Server settings updated', { variant: 'success', autoHideDuration: 1000 });
  };

  const removeCurrentServer = () => {
    if (dicomServer.removable) {
      const filteredList = serverList.filter((el) => el.id !== dicomServer.id);
      setServerList(filteredList);
      setDicomServer(filteredList[0]);
      setStoredDicomServer(filteredList[0]);
      setHasChanges(false);
      enqueueSnackbar('Server removed', { variant: 'success', autoHideDuration: 1000 });
    }
  };

  const createNewServer = () => {
    setDicomServer(emptyServerSetting());
    setHasChanges(false);
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasChanges(true);
    setDicomServer({
      ...dicomServer,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasChanges(true);
    setDicomServer({
      ...dicomServer,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAuthTypeChange = (event: SelectChangeEvent<AuthType>) => {
    setHasChanges(true);
    setDicomServer({
      ...dicomServer,
      authType: event.target.value as AuthType,
    });
  };

  const isEditable = Boolean(dicomServer.editable);
  const formHasError = Object.keys(formErrors).length > 0;

  const handleLoginWithZhiva = () => {
    const reqParams = {
      return_uri: `${window.location.origin}${OAUTH_ROUTE}`,
      response_type: JSON.stringify({
        type: 'authToken',
        returnParams: {
          serverUID: dicomServer.id,
        },
      }),
    };
    const parameters = new URLSearchParams(reqParams).toString();

    setStoredDicomServer(dicomServer);
    window.location.href = `${dicomServer.authServer}?${parameters}`;
  };

  const handleLoginWithToken = async () => {
    const tokenPayload = parseJwt(dicomServer.token || null) as AuthToken;

    if (tokenPayload.tokenServer) {
      const tokens = await TokenService.getAccessAndRefTokensFromAuthToken(dicomServer.token as string, {
        tokenServer: tokenPayload.tokenServer,
      });

      if (tokens && tokens.accessToken) {
        setDicomServer({
          ...dicomServer,
          authType: 'zhivaAuth',
          token: '',
          name: dicomServer.name || 'Zhiva PACS',
          url: tokenPayload.pacs || dicomServer.url,
          tokenServer: tokenPayload.tokenServer,
          refreshServer: tokenPayload.refreshServer,
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
          canSave: tokenPayload.canEditResource,
        });
        setStoredDicomServer({
          ...dicomServer,
          authType: 'zhivaAuth',
          token: '',
          name: dicomServer.name || 'Zhiva PACS',
          url: tokenPayload.pacs || dicomServer.url,
          tokenServer: tokenPayload.tokenServer,
          refreshServer: tokenPayload.refreshServer,
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
          canSave: tokenPayload.canEditResource,
        });
        enqueueSnackbar('Server authenticated', { variant: 'success', autoHideDuration: 1000 });
      } else {
        enqueueSnackbar('Authentication error', { variant: 'error', autoHideDuration: 1000 });
      }
    } else {
      enqueueSnackbar('Invalid token', { variant: 'error', autoHideDuration: 1000 });
    }
  };

  return (
    <React.Fragment>
      <Box component="form" noValidate autoComplete="off">
        <Grid container spacing={3} sx={{ p: 4 }}>
          <Grid item xs={10}>
            <Typography variant="h6" gutterBottom>
              Server Settings
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControl fullWidth>
              <InputLabel id="server-select-label">Selected Server</InputLabel>
              <Select
                labelId="server-select-label"
                id="server-select"
                value={dicomServer.id}
                label="Selected Server"
                onChange={handleServerChange}
              >
                {serverList.map((serverEl) => (
                  <MenuItem value={serverEl.id} key={serverEl.id}>
                    {serverEl.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title={`Delete selected server`}>
              <IconButton
                aria-label="delete"
                color={'error'}
                size={'large'}
                disabled={!dicomServer.removable}
                onClick={removeCurrentServer}
              >
                <DeleteForeverIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              error={formErrors['name'] != null}
              helperText={formErrors['name'] != null ? formErrors['name'] : null}
              disabled={!isEditable}
              inputProps={{ inputMode: 'text', pattern: '[a-zA-Z0-9_.-]+' }}
              id="name"
              name="name"
              value={dicomServer.name}
              onChange={handleFieldChange}
              label="Server Name"
              fullWidth
              variant="standard"
              // helperText="Invalid URL."
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              error={formErrors['url'] != null}
              helperText={formErrors['url'] != null ? formErrors['url'] : null}
              disabled={!isEditable}
              id="url"
              name="url"
              value={dicomServer.url}
              onChange={handleFieldChange}
              label="Server URL"
              fullWidth
              variant="standard"
              // helperText="Invalid URL."
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="auth-type-label">Authentication Type</InputLabel>
              <Select
                labelId="auth-type-label"
                id="auth-type"
                value={dicomServer.authType}
                label="Authentication Type"
                onChange={handleAuthTypeChange}
              >
                <MenuItem value={''} key={'none'}>
                  None
                </MenuItem>
                <MenuItem value={'basicAuth'} key={'basic'}>
                  Basic Auth (Login + Password)
                </MenuItem>
                <MenuItem value={'token'} key={'token'}>
                  Token
                </MenuItem>
                <MenuItem value={'zhivaAuth'} key={'zhivaAuth'}>
                  zhiva Auth
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {dicomServer?.authType === 'basicAuth' && (
            <React.Fragment>
              <Grid item xs={12}>
                <TextField
                  required
                  error={formErrors['login'] != null}
                  helperText={formErrors['login'] != null ? formErrors['login'] : null}
                  disabled={!isEditable}
                  id="login"
                  name="login"
                  value={dicomServer.login}
                  onChange={handleFieldChange}
                  label="Server Login"
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  error={formErrors['password'] != null}
                  helperText={formErrors['password'] != null ? formErrors['password'] : null}
                  disabled={!isEditable}
                  id="password"
                  name="password"
                  type="password"
                  value={!isEditable ? '********' : dicomServer.password}
                  onChange={handleFieldChange}
                  label="Server Password"
                  fullWidth
                  variant="standard"
                />
              </Grid>
            </React.Fragment>
          )}
          {(dicomServer?.authType === 'token' || dicomServer?.authType === 'zhivaAuth') && (
            <React.Fragment>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                  // required
                  type="password"
                  error={formErrors['token'] != null}
                  helperText={formErrors['token'] != null ? formErrors['token'] : null}
                  disabled={!isEditable}
                  id="token"
                  name="token"
                  value={dicomServer.token || ''}
                  onChange={handleFieldChange}
                  label="Token"
                  fullWidth
                  variant="standard"
                />
                <Button
                  variant={'outlined'}
                  sx={{ ml: 1 }}
                  onClick={handleLoginWithToken}
                  disabled={!dicomServer.token || dicomServer.token.length < 1}
                >
                  Authenticate with token
                </Button>
              </Grid>
              {dicomServer?.authType === 'zhivaAuth' && (
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    type="text"
                    error={formErrors['authServer'] != null}
                    helperText={formErrors['authServer'] != null ? formErrors['authServer'] : null}
                    disabled={!isEditable}
                    id="authServer"
                    name="authServer"
                    value={dicomServer.authServer || ''}
                    onChange={handleFieldChange}
                    label="Auth Server URL"
                    fullWidth
                    variant="standard"
                  />
                  <Button
                    variant={'outlined'}
                    sx={{ ml: 1 }}
                    onClick={handleLoginWithZhiva}
                    disabled={!dicomServer.authServer || dicomServer.authServer === ''}
                  >
                    Login with zhiva
                  </Button>
                </Grid>
              )}
            </React.Fragment>
          )}
          <Grid item xs={12}>
            <TextField
              error={formErrors['uuid'] != null}
              helperText={formErrors['uuid'] != null ? formErrors['uuid'] : null}
              disabled={!isEditable}
              inputProps={{
                inputMode: 'text',
                pattern: '[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}',
              }}
              id="uuid"
              name="uuid"
              value={dicomServer.uuid}
              onChange={handleFieldChange}
              label="Server UUID (uuidv4)"
              fullWidth
              variant="standard"
              // helperText="Invalid URL."
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={formErrors['postfix'] != null}
              helperText={formErrors['postfix'] != null ? formErrors['postfix'] : null}
              disabled={!isEditable}
              id="postfix"
              name="postfix"
              value={dicomServer.postfix}
              onChange={handleFieldChange}
              label="Server Postfix"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={formErrors['wadoUriPostfix'] != null}
              helperText={formErrors['wadoUriPostfix'] != null ? formErrors['wadoUriPostfix'] : null}
              disabled={!isEditable}
              id="wadoUriPostfix"
              name="wadoUriPostfix"
              value={dicomServer.wadoUriPostfix}
              onChange={handleFieldChange}
              label="WADO URI Postfix"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={formErrors['wadoPostfix'] != null}
              helperText={formErrors['wadoPostfix'] != null ? formErrors['wadoPostfix'] : null}
              disabled={!isEditable}
              id="wadoPostfix"
              name="wadoPostfix"
              value={dicomServer.wadoPostfix}
              onChange={handleFieldChange}
              label="WADO Postfix"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={formErrors['qidoPostfix'] != null}
              helperText={formErrors['qidoPostfix'] != null ? formErrors['qidoPostfix'] : null}
              disabled={!isEditable}
              id="qidoPostfix"
              name="qidoPostfix"
              value={dicomServer.qidoPostfix}
              onChange={handleFieldChange}
              label="QIDO Postfix"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl error={formErrors['qidoSupportsIncludeField'] != null} component="fieldset" variant="standard">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isEditable}
                    color="primary"
                    name="qidoSupportsIncludeField"
                    checked={dicomServer.qidoSupportsIncludeField}
                    onChange={handleCheckboxChange}
                  />
                }
                label="QIDO Supports Include Field"
              />
              {formErrors['qidoSupportsIncludeField'] != null && (
                <FormHelperText error={true}>{formErrors['qidoSupportsIncludeField']}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              error={formErrors['imageRendering'] != null}
              helperText={formErrors['imageRendering'] != null ? formErrors['imageRendering'] : null}
              disabled={!isEditable}
              id="imageRendering"
              name="imageRendering"
              value={dicomServer.imageRendering}
              onChange={handleFieldChange}
              label="Image rendering protocol"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              error={formErrors['thumbnailRendering'] != null}
              helperText={formErrors['thumbnailRendering'] != null ? formErrors['thumbnailRendering'] : null}
              disabled={!isEditable}
              id="thumbnailRendering"
              name="thumbnailRendering"
              value={dicomServer.thumbnailRendering}
              onChange={handleFieldChange}
              label="Thumbnail rendering protocol"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl error={formErrors['enableStudyLazyLoad'] != null} component="fieldset" variant="standard">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isEditable}
                    color="primary"
                    name="enableStudyLazyLoad"
                    checked={dicomServer.enableStudyLazyLoad}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Enable Study LazyLoad"
              />
              {formErrors['enableStudyLazyLoad'] != null && (
                <FormHelperText error={true}>{formErrors['enableStudyLazyLoad']}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl error={formErrors['supportsFuzzyMatching'] != null} component="fieldset" variant="standard">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isEditable}
                    color="primary"
                    name="supportsFuzzyMatching"
                    checked={dicomServer.supportsFuzzyMatching}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Supports Fuzzy Matching"
              />
              {formErrors['supportsFuzzyMatching'] != null && (
                <FormHelperText error={true}>{formErrors['supportsFuzzyMatching']}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl error={formErrors['canSave'] != null} component="fieldset" variant="standard">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!isEditable}
                    color="primary"
                    name="canSave"
                    checked={dicomServer.canSave || false}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Can Store/Update Data?"
              />
              {formErrors['canSave'] != null && <FormHelperText error={true}>{formErrors['canSave']}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button color={'warning'} variant="outlined" onClick={createNewServer} sx={{ mt: 1, ml: 1 }}>
            {'Create New Server'}
          </Button>
          <Button
            disabled={!hasChanges || formHasError}
            variant="contained"
            onClick={handleSaveServer}
            sx={{ mt: 1, ml: 1 }}
          >
            {'Save Settings'}
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ServerSettings;
