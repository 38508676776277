import { InstanceUIDs, SegmentationObj, InstanceMetadataObj, SeriesObj } from '../../types/Dicom';
import mapSeriesListResults from './maps/mapSeriesListResults';
import mapSeriesMetadataResults from './maps/mapSeriesMetadataResults';
import addInstancesToWADOLoader from './helpers/addInstancesToWADOLoader';
import { sortStudyInstances } from './helpers/sortStudies';
import { isSeriesSegmentation } from '../../utils/dicomCodesUtils';
import SegmentationAPI from '../SegmentationService/SegmentationAPI';
import mapSegmentationListResults from './maps/mapSegmentationListResults';
import addInstancesToMetadataProvider from './helpers/addInstancesToMetadataProvider';
import { getDicomWebClientInstance } from './helpers/getDicomWebClientInstance';
import DicomWebService from './DicomWebService';

export async function getSeriesFromStudy(
  StudyInstanceUID: InstanceUIDs['StudyInstanceUID'],
  filter: Record<string, any> = {},
): Promise<SeriesObj[]> {
  const dicomWeb = getDicomWebClientInstance();

  const options = {
    studyInstanceUID: StudyInstanceUID,
    // queryParams: { SeriesInstanceUID: StudyInstanceUID },
  };

  const series = mapSeriesListResults(await dicomWeb.searchForSeries(options));
  const seriesToDisplay = series.filter((series: SeriesObj) => !isSeriesSegmentation(series));
  const segmentations = series.filter((series: SeriesObj) => isSeriesSegmentation(series));
  if (segmentations.length > 0) {
    SegmentationAPI.setDicomSegMetaData(segmentations);
  }
  return seriesToDisplay;
}

export function getSeriesMetaData(
  StudyInstanceUID: InstanceUIDs['StudyInstanceUID'],
  seriesList: SeriesObj[],
  filter: Record<string, any> = {},
): Promise<InstanceMetadataObj[][]> {
  const dicomWeb = new DicomWebService();

  const seriesLoaders = seriesList.map((series) => {
    return new Promise<InstanceMetadataObj[]>((resolve) => {
      dicomWeb
        .call('retrieveSeriesMetadata', [
          {
            studyInstanceUID: StudyInstanceUID,
            seriesInstanceUID: series.SeriesInstanceUID,
          },
        ])
        .then(sortStudyInstances)
        .then(addInstancesToWADOLoader)
        .then(mapSeriesMetadataResults)
        .then(addInstancesToMetadataProvider)
        .then((data: PromiseLike<InstanceMetadataObj[]> | InstanceMetadataObj[]) => resolve(data));
    });
  });

  return Promise.all(seriesLoaders);
}

export async function getSegmentationSeriesMetaData(
  StudyInstanceUID: InstanceUIDs['StudyInstanceUID'],
  seriesList: SeriesObj[],
  filter: Record<string, any> = {},
): Promise<SegmentationObj[][]> {
  const dicomWeb = getDicomWebClientInstance();

  const seriesLoaders = seriesList.map((series) => {
    return new Promise<InstanceMetadataObj[]>((resolve) => {
      dicomWeb
        .retrieveSeriesMetadata({
          studyInstanceUID: StudyInstanceUID,
          seriesInstanceUID: series.SeriesInstanceUID,
        })
        .then(mapSeriesMetadataResults)
        .then((data: PromiseLike<InstanceMetadataObj[]> | InstanceMetadataObj[]) => resolve(data));
    });
  });

  const seriesMetadata = (await Promise.all(seriesLoaders)).reduce<PromiseLike<SegmentationObj[]>[]>(
    (acc, seriesData) => {
      for (const segInstance of seriesData) {
        acc.push(
          new Promise<SegmentationObj[]>((resolve) => {
            dicomWeb
              .retrieveInstanceMetadata({
                studyInstanceUID: segInstance.StudyInstanceUID,
                seriesInstanceUID: segInstance.SeriesInstanceUID,
                sopInstanceUID: segInstance.SOPInstanceUID,
              })
              .then(mapSegmentationListResults)
              .then((data: PromiseLike<SegmentationObj[]> | SegmentationObj[]) => resolve(data));
          }),
        );
      }
      return acc;
    },
    [],
  );

  return Promise.all(seriesMetadata);
}

export async function getSegInstancePixelData(segData: SegmentationObj) {
  const dicomWeb = getDicomWebClientInstance();

  return dicomWeb.retrieveInstance({
    studyInstanceUID: segData.StudyInstanceUID,
    seriesInstanceUID: segData.SeriesInstanceUID,
    sopInstanceUID: segData.SOPInstanceUID,
  });
}
