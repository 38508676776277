import React, { FC, ReactNode, useMemo } from 'react';
import { Link as LinkFromRouter } from 'react-router-dom';
import IconButton from "@mui/material/IconButton";

// constants

interface Props {
  to: string;
  children: ReactNode;
  [rest: string]: any;
}

const RouterIconLink: FC<Props> = (props) => {
  const { to, children, ...rest } = props;

  const CustomLink = useMemo(
    () => React.forwardRef<HTMLAnchorElement>((linkProps, ref) => <LinkFromRouter ref={ref} to={to} {...linkProps} />),
    [to],
  );
  return <IconButton component={CustomLink} {...rest} size="large">{children}</IconButton>;
};

export default RouterIconLink;
