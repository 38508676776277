import React, { FC } from 'react';
// import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import AirplayIcon from '@mui/icons-material/Airplay';
import RouterListLink from '../RouterLink/RouterListLink';
import { DICOMS_ROUTE, getSettingsRoute } from "../../utils/routes";

interface Props {}

const MainLinks: FC<Props> = () => {
  return (
    <React.Fragment>
      {[
        // { to: DASHBOARD_ROUTE, label: 'Dashboard', icon: <DashboardIcon /> },
        { to: DICOMS_ROUTE, label: 'Studies', icon: <AirplayIcon /> },
      ].map((link) => (
        <RouterListLink key={link.to} to={link.to} icon={link.icon} label={link.label} />
      ))}
    </React.Fragment>
  );
};

const SettingLinks: FC<Props> = () => {
  return (
    <React.Fragment>
      {[{ to: getSettingsRoute(), label: 'Settings', icon: <SettingsIcon /> }].map((link) => (
        <RouterListLink key={link.to} to={link.to} icon={link.icon} label={link.label} />
      ))}
    </React.Fragment>
  );
};

export { MainLinks, SettingLinks };
