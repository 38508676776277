import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

// constants
import { FOOTER_TEXT } from '../../utils/constants';
import { APP_VERSION } from "../../version";

interface Props {}

const Footer: FC<Props> = () => {
  return (
    <Box sx={{ pt: 2 }}>
      <Typography variant="body2" color="textSecondary" align="center">
        <Link
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
          href={'https://zhiva.org'}
          target={'_blank'}
          underline={"none"}
          color={"text.secondary"}
        >
          <img src={'/logos/zhiva_light.svg'} alt={'logo'} style={{ maxHeight: '24px', marginBottom: '4px' }} />
          <span style={{ textDecoration: 'initial'}}>© ZhivaAI</span>
        </Link>
        {FOOTER_TEXT}
      </Typography>
      <Typography variant="caption" color="textSecondary" align="center" component={"p"}>
        {`version: ${APP_VERSION}`}
      </Typography>
    </Box>
  );
};

export default Footer;
