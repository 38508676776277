import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { UserProfile } from '../../types/User';

interface Props {
  children: ReactNode;
}

type UserData = {
  username: string;
  remember: boolean;
};

type AuthContext = {
  isAuthenticated: boolean;
  authError: boolean;
  user?: string;
  signin: (userData: UserData | null, cb: (success: boolean) => void) => void;
  signout: (cb: () => void) => void;
};

const defaultProfile: UserProfile = {
  username: '',
};

const isValidUser = (userData: UserProfile | null) => {
  return userData != null;
};

const auth: AuthContext = {
  isAuthenticated: false,
  authError: false,
  signin(userData, cb: (success: boolean) => void) {
    if (isValidUser(userData)) {
      cb(true);
    } else {
      auth.isAuthenticated = false;
      auth.authError = true;
      cb(false);
    }
  },
  signout(cb: () => void) {
    auth.isAuthenticated = false;
    cb();
  },
};
const authContext = createContext(auth);

function useProvideAuth() {
  const [user, setUser] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [authUserProfile, setAuthUserProfile] = useSessionStorage('authUser', defaultProfile);
  const [localProfile, setLocalProfile] = useLocalStorage('authUser', defaultProfile);

  useEffect(() => {
    if (authUserProfile.username !== '') {
      setUser(authUserProfile.username);
    } else {
      setUser('');
    }
  }, [authUserProfile]);

  useEffect(() => {
    if (authUserProfile.username === '') {
      if (localProfile.username !== '') {
        setAuthUserProfile(localProfile);
      } else {
        setAuthUserProfile(defaultProfile);
      }
    }
  }, [localProfile, authUserProfile, setAuthUserProfile]);

  const signin: (userData: UserData | null, cb: (success: boolean) => void) => void = (userData, cb) => {
    return auth.signin(userData, (success: boolean) => {
      if (success && userData) {
        setAuthUserProfile({
          username: userData.username,
        });
        if (userData.remember) {
          setLocalProfile({
            username: userData.username,
          });
        }
        setIsAuthenticated(true);
        cb(true);
      } else {
        setAuthError(true);
      }
    });
  };

  const signout: (cb: () => void) => void = (cb) => {
    return auth.signout(() => {
      setAuthUserProfile(defaultProfile);
      setLocalProfile(defaultProfile);
      cb();
    });
  };

  return {
    user,
    isAuthenticated: isAuthenticated,
    authError: authError,
    signin,
    signout,
  };
}

const ProvideAuth: FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const useAuth = (): AuthContext => {
  return useContext(authContext);
};

export { ProvideAuth, useAuth };
