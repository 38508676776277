import React, { useEffect, FC, useState } from "react";
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { getSteps, Tours } from "./ToursProvider";
import { useSetRecoilState } from "recoil";
import { currentTourStepsState } from "../../recoil/tour";
import { DASHBOARD_ROUTE, DICOM_ROUTE, DICOMS_ROUTE } from "../../utils/routes";

interface Props {}

const TourNavigation: FC<Props> = () => {
  let location = useLocation();
  let match = useRouteMatch(DICOM_ROUTE);
  const [prevLocation, setPrevLocation] = useState('');
  const { setSteps, setCurrentStep } = useTour();
  const setCurrentTourSteps = useSetRecoilState(currentTourStepsState)

  const changeStepsOnRouteChange = (routeName: Tours) => {
    setTimeout(() => {
      const currSteps = getSteps(routeName);
      setSteps(currSteps);
      setCurrentStep(0);
      setCurrentTourSteps(currSteps)
    }, 500)
  }

  useEffect(() => {
    if(prevLocation !== location.pathname) {
      setPrevLocation(location.pathname);
      if (location.pathname === DASHBOARD_ROUTE) {
        changeStepsOnRouteChange('dashboard');
      } else if (location.pathname === DICOMS_ROUTE) {
        changeStepsOnRouteChange('dicomList');
      } else if (match) {
        changeStepsOnRouteChange('dicom');
      }
    }
  }, [location.pathname, match, prevLocation, setPrevLocation]);

  return <div />;
};

export default TourNavigation;
