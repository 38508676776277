import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import TagManager from 'react-gtm-module';
import './index.css';
import App from './components/App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ProvideAuth } from './components/Auth';
import UpdateSWAlert, { onSWUpdateAvailable } from './components/UpdateSWAlert/UpdateSWAlert';
import { darkTheme, snackbarClasses } from './components/Layout/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarUtilsConfigurator } from "./components/SnackbarGenerator/SnackbarGenerator";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTAG_ID || '',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={darkTheme}>
        <SnackbarProvider
          classes={{
            variantSuccess: snackbarClasses.success.backgroundColor,
            variantError: snackbarClasses.error.backgroundColor,
            variantWarning: snackbarClasses.warning.backgroundColor,
            variantInfo: snackbarClasses.info.backgroundColor,
          }}
        >
          <CssBaseline />
          <RecoilRoot>
            <ProvideAuth>
              <BrowserRouter>
                <SnackbarUtilsConfigurator />
                <App />
              </BrowserRouter>
            </ProvideAuth>
          </RecoilRoot>
          <UpdateSWAlert />
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: onSWUpdateAvailable,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
