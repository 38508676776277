import React, { FC } from 'react';
import ChartTitle from '../Layout/ChartTitle';
import type { DicomDBObj } from '../../types/Dicom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DicomRow from './DicomRow';
import { StudyObj } from '../../types/Dicom';
import { filterColumnElemenets } from './columnFilter';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export interface dataFiledDef {
  key: keyof StudyObj;
  header: string;
  type: string;
  maxWidth?: string; //in px
  displayOnMobile?: boolean;
  displayOnDesktop?: boolean;
  align?: 'right' | 'left' | 'center';
}

const columns: dataFiledDef[] = [
  {
    key: 'StudyDate',
    header: 'Date',
    type: 'date',
  },
  {
    key: 'PatientName',
    header: 'Patient',
    type: 'text',
    displayOnMobile: false,
  },
  {
    key: 'Modalities',
    header: 'Modality',
    type: 'modality',
  },
];

const descriptionColumns: dataFiledDef[] = [
  {
    key: 'PatientName',
    header: 'Patient',
    type: 'text',
    displayOnDesktop: false,
  },
  {
    key: 'StudyDescription',
    header: 'Description',
    type: 'text',
  },
];

interface Props {
  isLoading?: boolean;
  data?: DicomDBObj[];
}

const mobileFilter = filterColumnElemenets([{ key: 'displayOnMobile', value: false }]);
const desktopFilters = filterColumnElemenets([{ key: 'displayOnDesktop', value: false }]);

const DicomList: FC<Props> = ({ isLoading = false, data = [] }) => {
  const theme = useTheme();
  const isSmallOrLarger = useMediaQuery(theme.breakpoints.up('sm'));
  const columnFilter = isSmallOrLarger ? desktopFilters : mobileFilter;
  return (
    <React.Fragment>
      <ChartTitle>Studies</ChartTitle>
      <TableContainer component={Paper} id={'dicomListContainer'} sx={{ maxHeight: 'calc(100%)' }}>
        <Table aria-label="studies list" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.filter(columnFilter).map((column) => (
                <TableCell key={column.key} align="left">
                  {column.header}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <DicomRow
                key={row.id}
                row={row}
                columns={columns.filter(columnFilter)}
                descriptionColumns={descriptionColumns.filter(columnFilter)}
              />
            ))}
          </TableBody>
        </Table>
        {isLoading && (
          <Box sx={{ width: '100%' }}>
            <Skeleton variant="rectangular" height={64} sx={{ mt: 1 }} />
          </Box>
        )}
      </TableContainer>
    </React.Fragment>
  );
};

export default DicomList;
