import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import KeyIcon from '@mui/icons-material/Key';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ModelServer, SegmentationClass } from '../../../types/modelServer';
import Paper from '@mui/material/Paper';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Typography from '@mui/material/Typography';
import { InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from "uuid";
import ColorPicker from "../../common/ColorPicker";
import { parseNameToClassString } from "../../../services/SegmentationService/SegmentationAPI";

interface Props {
  onChange: (segmentationClasses: ModelServer['segmentationClasses']) => void;
  segmentationClasses: ModelServer['segmentationClasses'];
  formErrors: any;
  isEditable: boolean;
}

function createNewClass(): SegmentationClass {
  return {
    uuid: uuidv4(),
    color: 'rgb(16,160,160)',
    name: '',
    class: '',
  };
}

export default function SegmentationClasses({ onChange, segmentationClasses = [], formErrors, isEditable }: Props) {
  const addNewClass = () => {
    onChange([...(segmentationClasses || []), createNewClass()]);
  };

  const removeClass = (index: number) => {
    onChange((segmentationClasses || []).filter((el, idx) => index !== idx));
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const fieldPath = event.target.name.split('/');
    const newSlug = parseNameToClassString(newValue);

    const selectedElement = segmentationClasses[Number(fieldPath[0])];

    onChange(
      (segmentationClasses || []).map((el, idx) => {
        if (idx !== Number(fieldPath[0])) {
          return el;
        }

        return {
          ...selectedElement,
          name: newValue,
          class: newSlug,
        };
      }),
    );
  };

  const handleColorChange = (color: string, classIdx: number) => {
    const selectedElement = segmentationClasses[classIdx];
    onChange(
      (segmentationClasses || []).map((el, idx) => {
        if (idx !== classIdx) {
          return el;
        }

        return {
          ...selectedElement,
          color: color,
        };
      }),
    );
  };

  return (
    <Paper variant={'outlined'}>
      <List
        sx={{ width: '100%', maxWidth: '500px', pl: 1 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {`Segmentation Classes`}
            <Tooltip title={`List of classes that model could provide. It is not required to provide all of them.`}>
              <IconButton aria-label="remove-measurement" sx={{ zIndex: 1 }}>
                <HelpOutlineOutlinedIcon fontSize={'small'} />
              </IconButton>
            </Tooltip>
          </ListSubheader>
        }
      >
        {segmentationClasses.map((segmentationClass, classIdx) => {
          return (
            <ListItem
              style={{ border: '1px solid rgba(255,255,255,0.3)' }}
              sx={{ mb: 1 }}
              key={segmentationClass.uuid}
            >
              <ListItemText
                disableTypography
                primary={
                  <React.Fragment>
                    <KeyIcon sx={{ mr: 1 }} />
                    <TextField
                      placeholder="Class Name"
                      variant="standard"
                      size="small"
                      style={{ maxWidth: '200px' }}
                      name={`${classIdx}/name`}
                      onChange={handleNameChange}
                      value={segmentationClass.name}
                      error={formErrors[`segmentationClasses[${classIdx}].name`] != null}
                      helperText={
                        formErrors[`segmentationClasses[${classIdx}].name`] != null
                          ? formErrors[`segmentationClasses[${classIdx}].name`]
                          : null
                      }
                      disabled={!isEditable}
                    />
                    <Tooltip title={'Unique segmentation class name'}>
                      <IconButton aria-label="remove-measurement" sx={{ zIndex: 1 }}>
                        <HelpOutlineOutlinedIcon fontSize={'small'} />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <InputLabel sx={{minWidth: '50px'}}>Color</InputLabel>
                      <Box sx={{ width: '100%', pl: 2, pr: 2 }}>
                        <ColorPicker selectedColor={segmentationClass.color} onChange={(color) => handleColorChange(color, classIdx)} />
                      </Box>
                    </Box>
                  </React.Fragment>
                }
              />
              <Tooltip title={'Remove filter'}>
                <IconButton aria-label="delete" color={'error'} onClick={() => removeClass(classIdx)}>
                  <DeleteForeverIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </ListItem>
          );
        })}
        {formErrors[`segmentationClasses`] && (
          <Typography color={'error'} variant={'caption'}>
            {formErrors[`segmentationClasses`]}
          </Typography>
        )}
        <ListItemButton onClick={addNewClass} disabled={!isEditable}>
          <ListItemText primary="Add new class" />
          <IconButton>
            <AddIcon />
          </IconButton>
        </ListItemButton>
      </List>
    </Paper>
  );
}
