import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AirplayIcon from '@mui/icons-material/Airplay';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import { Route, useParams, useHistory } from 'react-router-dom';

// constants
import { PAGE_TITLE_SETTINGS } from '../../utils/constants';
import { Helmet } from 'react-helmet-async';
import ServerSettings from './ServerSettings/ServerSettings';
import Typography from '@mui/material/Typography';
import ModelSettings from './ModelSettings/ModelSettings';
import { getSettingsRoute, SETTING_ROUTES } from '../../utils/routes';

const PREFIX = 'Settings';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  fixedHeight: `${PREFIX}-fixedHeight`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(0),
  },
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  [`& .${classes.fixedHeight}`]: {
    [theme.breakpoints.up('sm')]: {
      height: 280,
    },
  },
}));

const tabs: { name: string; TabIcon: any; value: SETTING_ROUTES }[] = [
  {
    name: 'Servers',
    TabIcon: DisplaySettingsIcon,
    value: SETTING_ROUTES.SERVERS,
  },
  {
    name: 'AI Models',
    TabIcon: PsychologyOutlinedIcon,
    value: SETTING_ROUTES.MODELS,
  },
  {
    name: 'Organization',
    TabIcon: CorporateFareIcon,
    value: SETTING_ROUTES.ORGANIZATIONS,
  },
  {
    name: 'Application',
    TabIcon: AirplayIcon,
    value: SETTING_ROUTES.APPLICATION,
  },
];

interface Props {}

const Settings: FC<Props> = () => {
  const params = useParams<{ panel: SETTING_ROUTES }>();
  const history = useHistory();
  const { panel } = params;
  const handleChange = (event: React.SyntheticEvent, newValue: SETTING_ROUTES) => {
    history.push(getSettingsRoute(newValue));
  };

  return (
    <StyledContainer className={classes.container} maxWidth={false}>
      <Helmet>
        <title>{PAGE_TITLE_SETTINGS}</title>
        <meta name="header" content={PAGE_TITLE_SETTINGS} />
      </Helmet>
      <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0, height: 64 }} enableColorOnDark={false}>
        <Tabs
          value={panel}
          onChange={handleChange}
          variant="fullWidth"
          textColor={'inherit'}
          aria-label="icon label tabs example"
        >
          {tabs.map(({ name, TabIcon, value }) => (
            <Tab key={value} icon={<TabIcon />} iconPosition="start" label={name} value={value} />
          ))}
        </Tabs>
      </AppBar>
      <Grid container sx={{ pl: 2, pt: 0, height: 'calc(100vh - 128px)', overflow: 'auto' }}>
        <Paper variant="outlined" sx={{ mr: 2, my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, width: '100%' }}>
          <Route
            path={[`${getSettingsRoute(SETTING_ROUTES.SERVERS)}/:serverUID`, getSettingsRoute(SETTING_ROUTES.SERVERS)]}
            component={ServerSettings}
          />
          <Route
            path={[`${getSettingsRoute(SETTING_ROUTES.MODELS)}/:modelUID`, getSettingsRoute(SETTING_ROUTES.MODELS)]}
            component={ModelSettings}
          />
          <Route path={[getSettingsRoute(SETTING_ROUTES.ORGANIZATIONS)]}>
            <Typography variant={'h4'} sx={{ textAlign: 'center' }}>
              <EngineeringIcon fontSize={'large'} sx={{ mr: 2 }} />
              Work in progress
            </Typography>
          </Route>
          <Route path={[getSettingsRoute(SETTING_ROUTES.APPLICATION)]}>
            <Typography variant={'h4'} sx={{ textAlign: 'center' }}>
              <EngineeringIcon fontSize={'large'} sx={{ mr: 2 }} />
              Work in progress
            </Typography>
          </Route>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default Settings;
