import { atom, AtomEffect, selector } from 'recoil';
import { DicomServer } from '../types/dicomServer';
import { defaultDemoServer, localServers } from "../configs/dicomServer";
import { LOCAL_STORAGE } from '../utils/constants';
import DicomServerInstance from '../services/DicomService/DicomServerService';
import { localStorageEffect } from "./effects/localStorageEffect";


const addServerToService: AtomEffect<DicomServer> = ({ onSet }) => {
  onSet((newValue) => {
    DicomServerInstance.setSelectedServer(newValue);
  });
};

export const dicomServerState = atom<DicomServer>({
  key: 'dicomServer',
  default: {
    ...defaultDemoServer,
  },
  effects_UNSTABLE: [localStorageEffect(LOCAL_STORAGE.DICOM_SERVER_KEY), addServerToService],
});

export const dicomServerList = atom<DicomServer[]>({
  key: 'dicomServerList',
  default: localServers.map((server) => ({ ...server })),
  effects_UNSTABLE: [localStorageEffect(LOCAL_STORAGE.DICOM_SERVER_LIST_KEY)],
});

export const dicomServerSelector = selector<DicomServer>({
  key: 'dicomServerState',
  get: ({ get }) => {
    return get(dicomServerState);
  },
  set: ({ set }, newValue) => {
    set(dicomServerList, (prevValue): DicomServer[] => {
      if (prevValue == null) {
        prevValue = [];
      }
      if (prevValue.findIndex((prev) => prev.id === (newValue as DicomServer).id) > -1) {
        prevValue = prevValue.filter((prev) => prev.id !== (newValue as DicomServer).id);
      }
      if ((newValue as DicomServer).authType !== 'basicAuth') {
        (newValue as DicomServer) = {
          ...newValue,
          login: '',
          password: ''
        } as DicomServer
      }
      return [...prevValue, newValue as DicomServer];
    });
    set(dicomServerState, newValue);
  },
});
