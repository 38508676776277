type LoginFormState = {
  email: string;
  password: string;
  remember: boolean;
};

const FakeAuth = {
  signin(userData: LoginFormState, cb: (success: boolean) => void) {
    if(userData.email === process.env.REACT_APP_DEMO_LOGIN && userData.password === process.env.REACT_APP_DEMO_PASSWORD) {
      setTimeout(() => cb(true), 100); // fake async
    } else {
      setTimeout(() => cb(false), 100); // fake async
    }
  },
}

export default FakeAuth;