import React, { ReactNode, FC } from 'react';
import { TourProvider } from '@reactour/tour';

import dashboardSteps from './dashboardSteps';
import dicomListSteps from './dicomListSteps';
import dicomSteps from './dicomSteps';
import { darkTheme } from '../Layout/theme';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useRecoilValue } from 'recoil';
import { currentTourStepsState } from '../../recoil/tour';
import { useHistory } from 'react-router-dom';

export type Tours = 'dashboard' | 'dicomList' | 'dicom';

export const getSteps = (name: Tours) => {
  switch (name) {
    case 'dashboard':
      return dashboardSteps;
    case 'dicomList':
      return dicomListSteps;
    case 'dicom':
      return dicomSteps;
    default:
      console.error(`Cannot match tour name: ${name}`);
      return dashboardSteps;
  }
};

interface Props {
  children: ReactNode;
}

const styles = {
  popover: (base: any) => ({
    ...base,
    backgroundColor: darkTheme.palette.background.default,
    color: darkTheme.palette.text.primary,
    '& button': {
      outline: 'none !important',
    },
  }),
  badge: (base: any) => ({
    ...base,
    backgroundColor: darkTheme.palette.primary.dark,
    color: darkTheme.palette.text.primary,
  }),
  close: (base: any) => ({
    ...base,
    color: darkTheme.palette.text.secondary,
    margin: '-10px',
    width: '12px',
    '&:hover': {
      color: darkTheme.palette.text.primary,
    },
  }),
  button: (base: any, data: any) => {
    if (data.kind === 'prev' || data.kind === 'next') {
      if (data.disabled === false) {
        return {
          ...base,
          '& svg': {
            color: darkTheme.palette.text.primary,
            '&:hover': {
              color: darkTheme.palette.text.primary,
            },
          },
        };
      }
      return {
        ...base,
        '& svg': {
          color: darkTheme.palette.text.disabled,
          '&:hover': {
            color: darkTheme.palette.text.disabled,
          },
        },
      };
    }
    return {
      ...base,
    };
  },
};

export const ToursContextProvider: FC<Props> = ({ children }) => {
  const selectedSteps = useRecoilValue(currentTourStepsState);
  const history = useHistory();

  return (
    <TourProvider
      styles={styles}
      disableKeyboardNavigation={['left', 'right']}
      steps={[]}
      nextButton={({ Button, currentStep, stepsLength, setIsOpen, setCurrentStep }) => {
        const last = currentStep === stepsLength - 1;
        const reloadOnNextStep = selectedSteps[currentStep] != null && selectedSteps[currentStep].nextLocation != null;
        return (
          <IconButton
            aria-label="next button"
            onClick={() => {
              if (reloadOnNextStep) {
                history.push(selectedSteps[currentStep].nextLocation);
              } else if (last) {
                setIsOpen(false);
              } else {
                setCurrentStep((s) => (s === stepsLength - 1 ? 0 : s + 1));
              }
            }}
            size="large">
            <ArrowForwardIcon />
          </IconButton>
        );
      }}
      prevButton={({ currentStep, stepsLength, setIsOpen, setCurrentStep }) => {
        const first = currentStep === 0;
        const reloadOnPrevStep = selectedSteps[currentStep] != null && selectedSteps[currentStep].prevLocation != null;
        return (
          <IconButton
            aria-label="prev button"
            disabled={first && !reloadOnPrevStep}
            onClick={() => {
              if (reloadOnPrevStep) {
                history.push(selectedSteps[currentStep].prevLocation);
              } else if (first) {
                setCurrentStep((s) => stepsLength - 1);
              } else {
                setCurrentStep((s) => s - 1);
              }
            }}
            size="large">
            <ArrowBackIcon />
          </IconButton>
        );
      }}
    >
      {children}
    </TourProvider>
  );
};
