import { buildWADORSImageId } from "./buildInstanceWadoRsUri";
import DicomServerService from "../DicomServerService";
import MetadataProvider from "../../../utils/cornerstoneUtils/MetadataProvider";
import { InstanceMetadataObj } from "../../../types/Dicom";

export default function addInstancesToMetadataProvider(results: InstanceMetadataObj[]): InstanceMetadataObj[] {

  results.forEach(instance => {
    const wadorsImageId = buildWADORSImageId(DicomServerService.server, instance.StudyInstanceUID, instance.SeriesInstanceUID, instance.SOPInstanceUID);

    MetadataProvider.addImageIdToUIDs(wadorsImageId, {
      StudyInstanceUID: instance.StudyInstanceUID,
      SeriesInstanceUID: instance.SeriesInstanceUID,
      SOPInstanceUID: instance.SOPInstanceUID
    })
    MetadataProvider.addInstance(instance)
  })
  return results;
}
