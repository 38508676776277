import { seriesResultsResponse } from '../../../types/dicomServer';
import { SegmentationObj } from '../../../types/Dicom';
import {
  getModalities,
  getNumberValue, getPatientName,
  getReferencedSeries,
  getSegmentationFramesDefinition,
  getSegmentSequence,
  getStringValue
} from "../../../utils/dicomCodesUtils";
import { COMMON_DICOM_CODES } from '../../../utils/constants';

export function mapSegmentation(series: seriesResultsResponse): SegmentationObj {
  return {
    id:
      getStringValue(series[COMMON_DICOM_CODES.SERIES_INSTANCE_UID]) +
      '-' +
      getStringValue(series[COMMON_DICOM_CODES.SERIES_NUMBER]),
    SeriesNumber: getNumberValue(series[COMMON_DICOM_CODES.SERIES_NUMBER]),
    StudyInstanceUID: getStringValue(series[COMMON_DICOM_CODES.STUDY_INSTANCE_UID]),
    SeriesInstanceUID: getStringValue(series[COMMON_DICOM_CODES.SERIES_INSTANCE_UID]),
    SOPInstanceUID: getStringValue(series[COMMON_DICOM_CODES.SOP_INSTANCE_UID]),
    NumberOfFrames: getNumberValue(series[COMMON_DICOM_CODES.NUMBER_OF_FRAMES]),
    PerformedProcedureStepStartDate: getStringValue(series[COMMON_DICOM_CODES.PERFORMED_PROCEDURE_STEP_START_DATE]),
    PerformedProcedureStepStartTime: getStringValue(series[COMMON_DICOM_CODES.PERFORMED_PROCEDURE_STEP_START_TIME]),
    NumberOfSeriesRelatedInstances: getNumberValue(series[COMMON_DICOM_CODES.NUMBER_OF_SERIES_RELATED_INSTANCES]),
    SeriesDescription: getStringValue(series[COMMON_DICOM_CODES.SERIES_DESCRIPTION]),
    Modalities: getModalities({
      modalityField: series[COMMON_DICOM_CODES.MODALITY],
      keepSeparate: true,
    }),
    rows: getNumberValue(series[COMMON_DICOM_CODES.ROWS]),
    columns: getNumberValue(series[COMMON_DICOM_CODES.COLUMNS]),
    ReferencedSeriesSequence: getReferencedSeries({
      seriesSeqField: series[COMMON_DICOM_CODES.REFERENCED_SERIES_SEQUENCE],
    }),
    SegmentSequence: getSegmentSequence({ segmentSeqField: series[COMMON_DICOM_CODES.SEGMENT_SEQUENCE] }),
    ContentCreatorName: getPatientName(series[COMMON_DICOM_CODES.CONTENT_CREATOR_NAME]),
    PerFrameFunctionalGroupsSequence: getSegmentationFramesDefinition({
      segmentFramesField: series[COMMON_DICOM_CODES.PER_FRAME_FUNCTIONAL_GROUPS_SEQUENCE],
    }),
  };
}

export default function mapSegmentationListResults(results: seriesResultsResponse[]): SegmentationObj[] {
  return results.map((series) => mapSegmentation(series));
}
