import React, { Suspense, lazy } from 'react';
import NotFound from '../NotFound/NotFound';
import Login from '../Login/Login';
import AuthRoute from '../RouterLink/AuthRoute';
import Layout from '../Layout/Layout';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { APP_TITLE, PAGE_TITLE_HOME } from '../../utils/constants';
import Dashboard from '../Dashboard/Dashboard';
import Dicoms from '../Dicoms/Dicoms';
import LoadingScreen from '../Layout/LoadingScreen';
import { ToursContextProvider } from '../Tours/ToursProvider';
import TourNavigation from '../Tours/ToursNavigator';
import {
  APP_ROUTE, AUTH_ROUTE,
  DASHBOARD_ROUTE,
  DICOM_ROUTE,
  DICOMS_ROUTE, LOGIN_ROUTE,
  SETTINGS_ROUTE,
  SHARED_DICOM_ROUTE
} from "../../utils/routes";
import Settings from "../Settings/Settings";
import Auth from "../Auth/Auth";

const DicomViewContainer = lazy(() => import('../DicomView/DicomViewContainer'));
const helmetContext = {};
function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <Helmet titleTemplate={`%s | ${APP_TITLE}`}>
        <title>{PAGE_TITLE_HOME}</title>
        <meta name="header" content={PAGE_TITLE_HOME} />
      </Helmet>
      <Switch>
        <Route path={SHARED_DICOM_ROUTE}>
          <Layout withNav={false} options={{ fullWidth: true }}>
            <Suspense fallback={<LoadingScreen title={'Loading DICOM viewer'} />}>
              <DicomViewContainer />
            </Suspense>
          </Layout>
        </Route>
        <AuthRoute path={APP_ROUTE}>
          <ToursContextProvider>
            <Switch>
              <Redirect exact from={APP_ROUTE} to={DASHBOARD_ROUTE} />
              <Route path={DASHBOARD_ROUTE}>
                <Redirect to={DICOMS_ROUTE} />
                <Layout>
                  <Dashboard />
                </Layout>
              </Route>
              <Route exact path={DICOMS_ROUTE}>
                <Layout>
                  <Suspense fallback={<div>Loading</div>}>
                    <Dicoms />
                  </Suspense>
                </Layout>
              </Route>
              <Route path={DICOM_ROUTE}>
                <Layout options={{ fullWidth: true }}>
                  <Suspense fallback={<LoadingScreen title={'Loading DICOM viewer'} />}>
                    <DicomViewContainer />
                  </Suspense>
                </Layout>
              </Route>
              <Route path={SETTINGS_ROUTE}>
                <Layout><Settings /></Layout>
              </Route>
              <Route path="*">
                <Layout>
                  <NotFound />
                </Layout>
              </Route>
            </Switch>
            <TourNavigation />
          </ToursContextProvider>
        </AuthRoute>
        <Route exact path={LOGIN_ROUTE}>
          <Layout withNav={false}>
            <Login />
          </Layout>
        </Route>
        <Route path={AUTH_ROUTE}>
          <Layout withNav={false}>
            <Auth />
          </Layout>
        </Route>
        <Route path="*">
          <Redirect to={DASHBOARD_ROUTE} />
        </Route>
      </Switch>
    </HelmetProvider>
  );
}

export default App;
