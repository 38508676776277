import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// constants
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

const PREFIX = 'LoadingScreen';

const classes = {
  container: `${PREFIX}-container`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

interface Props {
  title?: string;
}

const LoadingScreen: FC<Props> = ({ title }) => {
  return (
    <StyledContainer maxWidth={'lg'} className={classes.container}>
      <CircularProgress color={'primary'} />
      {title && (
        <Typography variant="h4" color="textSecondary" align="center">
          {title}
        </Typography>
      )}
    </StyledContainer>
  );
};

export default LoadingScreen;
