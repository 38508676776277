import { PUBLIC_IMAGE_PATH_PREFIX } from './constants';

export enum SETTING_ROUTES {
  SERVERS = 'servers',
  MODELS = 'models',
  ORGANIZATIONS = 'organization',
  APPLICATION = 'application',
}

// Main app routes
export const APP_ROUTE = '/app';
export const DASHBOARD_ROUTE = `${APP_ROUTE}/dashboard`;

// Studies routes (login required
export const DICOMS_ROUTE = `${APP_ROUTE}/studies`;
export const DICOM_ROUTE = `${APP_ROUTE}/studies/:studyid`;
export const getStudyRoute = (studyId: string) => DICOM_ROUTE.replace(':studyid', studyId);

// Setting routes
export const SETTINGS_ROUTE = `${APP_ROUTE}/settings/:panel`;
export const getSettingsRoute = (panelId: string = SETTING_ROUTES.SERVERS) => SETTINGS_ROUTE.replace(':panel', panelId);
export const getSettingsServerRoutes = (serverUID: string) =>
  `${getSettingsRoute(SETTING_ROUTES.SERVERS)}/${serverUID}`;
export const getSettingsModelsRoutes = (modelUID: string) =>
  `${getSettingsRoute(SETTING_ROUTES.MODELS)}/${modelUID}`;

// Login routes
export const LOGIN_ROUTE = '/login';

// Auth routes
export const AUTH_ROUTE = '/auth';
export const OAUTH_ROUTE = `${AUTH_ROUTE}/oauth`

// Shared dicom routes
export const SHARED_DICOM_ROUTE = `/${PUBLIC_IMAGE_PATH_PREFIX}:studyid`;
export const getSharedStudyRoute = (studyId: string) => SHARED_DICOM_ROUTE.replace(':studyid', studyId);

export const ROUTES_WITH_STEALTH_MODE = [DICOM_ROUTE, SHARED_DICOM_ROUTE];
