import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import { seriesResultsResponse } from '../../../types/dicomServer';
import { buildWADORSImageId } from "./buildInstanceWadoRsUri";
import { getStringValue } from "../../../utils/dicomCodesUtils";
import { COMMON_DICOM_CODES } from "../../../utils/constants";
import DicomServerService from "../DicomServerService";

export default function addInstancesToWADOLoader(results: seriesResultsResponse[]): seriesResultsResponse[] {

  results.forEach(instance => {
    const StudyInstanceUID = getStringValue(instance[COMMON_DICOM_CODES.STUDY_INSTANCE_UID])
    const SeriesInstanceUID = getStringValue(instance[COMMON_DICOM_CODES.SERIES_INSTANCE_UID])
    const SOPInstanceUID = getStringValue(instance[COMMON_DICOM_CODES.SOP_INSTANCE_UID])
    const wadorsImageId = buildWADORSImageId(DicomServerService.server, StudyInstanceUID, SeriesInstanceUID, SOPInstanceUID);
    cornerstoneWADOImageLoader.wadors.metaDataManager.add(
      wadorsImageId,
      instance
    );
  })
  return results;
}
