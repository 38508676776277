import { format } from 'date-fns';
import DicomServerService from "../DicomServerService";

/**
 * Produces a QIDO URL query parameters for dicomweb-clien. It uses given server details and a set of specified search filter
 */
export default function getQIDOQueryParams(filter: Record<string, any>, serverSupportsQIDOIncludeField: boolean, extraFieldsToInclude?: string[] | undefined): Record<string, string> {
  const commaSeparatedFields = extraFieldsToInclude || DicomServerService.server.defaultFilterFields;

  const parameters: Record<string, any> = {
    PatientName: filter.PatientName,
    PatientID: filter.PatientID,
    AccessionNumber: filter.AccessionNumber,
    StudyDescription: filter.StudyDescription,
    ModalitiesInStudy: filter.ModalitiesInStudy,
    limit: filter.limit,
    offset: filter.offset,
    fuzzymatching: filter.fuzzymatching,
    includefield: serverSupportsQIDOIncludeField ? commaSeparatedFields.join(',') : 'all',
  };

  // build the StudyDate range parameter
  if (filter.studyDateFrom || filter.studyDateTo) {
    const dateFrom = format(filter.studyDateFrom, 'yyyyMMdd');
    const dateTo = format(filter.studyDateTo, 'yyyyMMdd');
    parameters.StudyDate = `${dateFrom}-${dateTo}`;
  }

  // Build the StudyInstanceUID parameter
  if (filter.StudyInstanceUID) {
    let studyUIDs = filter.StudyInstanceUID;
    studyUIDs = Array.isArray(studyUIDs) ? studyUIDs.join() : studyUIDs;
    studyUIDs = studyUIDs.replace(/[^0-9.]+/g, '\\');
    parameters.StudyInstanceUID = studyUIDs;
  }


  return Object.entries(parameters).reduce((acc: Record<string, string>, [key, val]) => {
    if (val != null && val !== '') {
      acc[key] = String(val)
    }
    return acc;
  }, {});
}
