import dcmjs from 'dcmjs';

import { getDicomWebClientInstance } from '../DicomService/helpers/getDicomWebClientInstance';

export default async function storeSegToPACS(name: string, type: string, data: any) {
  const dicomWeb = getDicomWebClientInstance('*');
  const options = {
    datasets: [dcmjs.data.datasetToBuffer(data.dataset)],
  };
  return await dicomWeb.storeInstances(options);
}
