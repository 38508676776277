import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { enUS } from '@mui/material/locale';
import darkScrollbar from '@mui/material/darkScrollbar';

// const themeConf = {
//   palette: {
//     common: { black: '#000', white: '#fff' },
//     background: { paper: '#fff', default: '#fafafa' },
//     primary: {
//       light: 'rgba(126, 251, 255, 1)',
//       main: 'rgba(61, 201, 238, 1)',
//       dark: 'rgba(0, 151, 187, 1)',
//       contrastText: '#000',
//     },
//     secondary: {
//       light: 'rgba(172, 107, 255, 1)',
//       main: 'rgba(120, 60, 205, 1)',
//       dark: 'rgba(67, 6, 155, 1)',
//       contrastText: '#fff',
//     },
//     error: { light: '#e57373', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
//     text: {
//       primary: 'rgba(0, 0, 0, 0.87)',
//       secondary: 'rgba(0, 0, 0, 0.54)',
//       disabled: 'rgba(0, 0, 0, 0.38)',
//       hint: 'rgba(0, 0, 0, 0.38)',
//     },
//   },
// };
//
// const theme = responsiveFontSizes(
//   createTheme(
//     {
//       ...themeConf,
//     },
//     enUS,
//   ),
// );

const darkTheme = responsiveFontSizes(
  createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: darkScrollbar(),
        },
      },
    },
    palette: {
      action: {
        active: 'rgba(255,255,255, 0.87)',
        hover: 'rgba(255,255,255,0.08)',
        selected: 'rgba(255,255,255,0.16)',
        disabled: 'rgba(255,255,255,0.3)',
        disabledBackground: 'rgba(255,255,255,0.12)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      common: { black: '#121212', white: '#bfbfbf' },
      background: { paper: '#121212', default: '#303030' },
      mode: 'dark',
      primary: {
        light: '#aee5f9',
        main: '#34bdf0',
        dark: '#005e98',
      },
      secondary: {
        light: '#D1C0FF',
        main: '#936DFF',
        dark: '#5C29FF',
      },
      error: { light: '#e49cad', main: '#CF6679', dark: '#b75463' },
      warning: { light: '#ffe0b2', main: '#FFB74D', dark: '#fb8d00' },
      info: { light: '#b3e4fc', main: '#4fc3f7', dark: '#0b99e4' },
      success: { light: '#c8e6c9', main: '#81c784', dark: '#43a048' },
      text: {
        primary: 'rgba(255,255,255, 0.87)',
        secondary: 'rgba(255,255,255, 0.54)',
        disabled: 'rgba(255,255,255, 0.38)',
      },
    },
  }, enUS),
);


const snackbarClasses = {
  success: { backgroundColor: '#2EA32C' },
  error: { backgroundColor: '#b00020' },
  warning: { backgroundColor: '#E46C21' },
  info: { backgroundColor: '#34bdf0' },
};

export { darkTheme, snackbarClasses };
