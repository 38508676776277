import { v4 as uuidv4 } from 'uuid';
import { ModelServer } from '../types/modelServer';
import { ModelSupportedPaths, ModelTypes } from '../components/Settings/ModelSettings/ModelSchema';

export const emptyServerSetting: () => ModelServer = () => ({
  id: uuidv4(),
  editable: true,
  removable: true,
  name: '',
  uuid: '',
  type: ModelTypes.ANNOTATION,
  supports: ModelSupportedPaths.SERIES,
  modalities: [],
  url: '',
});
