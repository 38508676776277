import React, { FC, Fragment } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import useMediaQuery from '@mui/material/useMediaQuery';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

// constants
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../utils/constants';

import { MainLinks, SettingLinks } from './links';
import { useAuth } from '../Auth';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListItem from '@mui/material/ListItem';
import Footer from './Footer';
import { useRouteMatch } from 'react-router-dom';
import { ROUTES_WITH_STEALTH_MODE } from '../../utils/routes';
import { useUA } from 'use-ua-parser-js';
import LogoLink from "./LogoLink";

const PREFIX = 'Navigation';

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  toolbarIcon: `${PREFIX}-toolbarIcon`,
  mobileDrawer: `${PREFIX}-mobileDrawer`,
};

const Root = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    // width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  [`& .${classes.mobileDrawer}`]: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const StyledToolbarIcon = styled('div')(({ theme }) => ({
  [`&.${classes.toolbarIcon}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
}));

interface Props {
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  drawerOpen: boolean;
}

const Navigation: FC<Props> = ({ handleDrawerClose, handleDrawerOpen, drawerOpen }) => {
  const theme = useTheme();
  const isSmallOrLarger = useMediaQuery(theme.breakpoints.up('sm'));
  const auth = useAuth();
  const stealthMode = useRouteMatch({
    path: ROUTES_WITH_STEALTH_MODE,
  });
  const ua = useUA();
  const iOS = ua != null ? ua.os.name === 'iOS' : false;

  const container = window !== undefined ? () => window.document.body : undefined;

  const logout = () => {
    auth.signout(() => {});
  };

  const drawer = (
    <Fragment>
      <StyledToolbarIcon className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </StyledToolbarIcon>
      <Divider sx={{ width: DRAWER_WIDTH }} />
      <List>
        <MainLinks />
      </List>
      <Divider />
      <List>
        <SettingLinks />
      </List>
      <Divider />
      <List>
        <ListItem button onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={'Logout'} />
        </ListItem>
      </List>
      <div style={{ flex: 'auto' }} />
      {drawerOpen && (
        <footer>
          <Footer />
        </footer>
      )}
      {!drawerOpen && (
        <footer>
          <LogoLink />
        </footer>
      )}
    </Fragment>
  );
  return (
    <Root className={classes.drawer} aria-label="navigation menu">
      {(!isSmallOrLarger || stealthMode) && (
        <SwipeableDrawer
          sx={{ width: DRAWER_WIDTH }}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          container={container}
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={drawerOpen}
          onClose={handleDrawerClose}
          onOpen={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </SwipeableDrawer>
      )}
      {isSmallOrLarger && !stealthMode && (
        <StyledDrawer variant={'permanent'} open={drawerOpen}>
          {drawer}
        </StyledDrawer>
      )}
    </Root>
  );
};

export default Navigation;
