import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import ChartTitle from '../Layout/ChartTitle';

interface Props {
  data?: [string, number][];
}

function createData(time: string, amount: number | undefined) {
  return { time, amount };
}

const defaultData: [string, number | undefined][] = [
  ['00:00', 2000],
  ['03:00', 400],
  ['06:00', 200],
  ['09:00', 600],
  ['12:00', 1500],
  ['15:00', 2400],
  ['18:00', 2100],
  ['21:00', 2100],
  ['24:00', 2000],
];

const Usage: FC<Props> = ({ data = defaultData }) => {
  const theme = useTheme();

  const mappedData = data.map((el) => createData(el[0], el[1]));
  return (
    <React.Fragment>
      <ChartTitle>{'Today'}</ChartTitle>
      <ResponsiveContainer>
        <LineChart
          data={mappedData}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
            <Label angle={270} position="left" style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}>
              Usage (images)
            </Label>
          </YAxis>
          <Line type="monotone" dataKey="amount" stroke={theme.palette.primary.main} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default Usage;
