import { atom, AtomEffect } from "recoil";
import { LOCAL_STORAGE } from '../utils/constants';
import { localStorageEffect } from "./effects/localStorageEffect";
import { ModelServer } from "../types/modelServer";
import ModelServerService from "../services/ModelService/ModelServerService";


const addServerToService: AtomEffect<ModelServer[]> = ({ onSet }) => {
  onSet((newValue) => {
    ModelServerService.setAvailableModels(newValue);
  });
};

export const modelServerList = atom<ModelServer[]>({
  key: 'modelServerList',
  default: [],
  effects_UNSTABLE: [localStorageEffect(LOCAL_STORAGE.MODEL_SERVER_LIST_KEY), addServerToService],
});