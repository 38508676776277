import * as yup from 'yup';

export const serverSchema = yup.object().shape({
  name: yup.string().required(),
  uuid: yup
    .string()
    .matches(
      /^([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12})?$/,
      'Enter correct UUIDv4',
    ),
  url: yup
    .string()
    .required()
    .matches(
      /^(?:(https):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/,
      'Enter correct url',
    ),
  login: yup.string().test('required-with-password', `Login is required`, (value, context: any) => {
    return (
      (context.parent.authType === 'basicAuth' && value != null && value !== '') ||
      context.parent.authType !== 'basicAuth' ||
      context.parent.authType == null
    );
  }),
  password: yup.string().test('required-with-login', `Password is required`, (value, context: any) => {
    return (
      (context.parent.authType === 'basicAuth' && value != null && value !== '') ||
      context.parent.authType !== 'basicAuth' ||
      context.parent.authType == null
    );
  }),
  authServer: yup
    .string()
    .matches(
      /^(?:(https):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/,
      'Enter correct url',
    ),
  postfix: yup.string(),
  wadoUriPostfix: yup.string(),
  qidoPostfix: yup.string(),
  wadoPostfix: yup.string(),
  imageRendering: yup.string().required(),
  thumbnailRendering: yup.string().required(),
  qidoSupportsIncludeField: yup.boolean(),
  enableStudyLazyLoad: yup.boolean(),
  supportsFuzzyMatching: yup.boolean(),
  canSave: yup.boolean(),
});
