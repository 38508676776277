import { seriesResultsResponse } from '../../../types/dicomServer';
import { SeriesObj } from '../../../types/Dicom';
import { getModalities, getNumberValue, getStringValue } from '../../../utils/dicomCodesUtils';
import { COMMON_DICOM_CODES } from '../../../utils/constants';

export function mapSeries(series: seriesResultsResponse): SeriesObj {
  return {
    id:
      getStringValue(series[COMMON_DICOM_CODES.SERIES_INSTANCE_UID]) +
      '-' +
      getStringValue(series[COMMON_DICOM_CODES.SERIES_NUMBER]),
    SeriesNumber: getNumberValue(series[COMMON_DICOM_CODES.SERIES_NUMBER]),
    StudyInstanceUID: getStringValue(series[COMMON_DICOM_CODES.STUDY_INSTANCE_UID]),
    SeriesInstanceUID: getStringValue(series[COMMON_DICOM_CODES.SERIES_INSTANCE_UID]),
    PerformedProcedureStepStartDate: getStringValue(series[COMMON_DICOM_CODES.PERFORMED_PROCEDURE_STEP_START_DATE]),
    PerformedProcedureStepStartTime: getStringValue(series[COMMON_DICOM_CODES.PERFORMED_PROCEDURE_STEP_START_TIME]),
    NumberOfSeriesRelatedInstances: getNumberValue(series[COMMON_DICOM_CODES.NUMBER_OF_SERIES_RELATED_INSTANCES]),
    SeriesDescription: getStringValue(series[COMMON_DICOM_CODES.SERIES_DESCRIPTION]),
    Modalities: getModalities({
      modalityField: series[COMMON_DICOM_CODES.MODALITY],
      keepSeparate: true,
    }),
  };
}

export default function mapSeriesListResults(results: seriesResultsResponse[]): SeriesObj[] {
  return results.map((series) => mapSeries(series));
}
