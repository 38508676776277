import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import {darkTheme} from "../Layout/theme";

const PREFIX = 'UpdateSWAlert';

const classes = {
  root: `${PREFIX}-root`,
  alert: `${PREFIX}-alert`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    minWidth: '240px',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& button': {
      marginRight: theme.spacing(1),
      color: darkTheme.palette.error.dark,
    },
    color: darkTheme.palette.text.primary,
    backgroundColor: darkTheme.palette.background.default,
    position: 'fixed',
    zIndex: 1000,
    right: '24px',
    bottom: '24px',
    display: 'none',
  },

  [`& .${classes.alert}`]: {
    color: darkTheme.palette.text.primary,
  }
}));

const updateSWAlertId = 'update-sw-alert';

export function onSWUpdateAvailable() {
  const alertElement = document.getElementById(updateSWAlertId);

  // @ts-ignore
  alertElement.style.display = 'block'
}

export default function UpdateSWAlert() {

  const containerRef = useRef(null);

  const closeAlert = () => {
    // @ts-ignore
    containerRef.current.style.display = 'none';
  };

  return (
    <Root className={classes.root} id={updateSWAlertId} ref={containerRef}>
      <Alert
        severity="info"
        className={classes.alert}
        color={'info'}
        icon={<UpdateOutlinedIcon fontSize={'small'} color={'inherit'} />}
        action={
          <React.Fragment>
            <Button color="inherit" size="small" aria-label={'dismiss'} onClick={closeAlert}>
              Dismiss
            </Button>
          </React.Fragment>
        }
      >
        New version available! Please reload the app.
      </Alert>
    </Root>
  );
}
