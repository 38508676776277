import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

interface Props {}

const LogoLink: FC<Props> = () => {
  return (
    <Box sx={{ pt: 2, pb: 2 }}>
        <Link
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
          href={'https://zhiva.org'}
          target={'_blank'}
          underline={"none"}
          color={"text.secondary"}
        >
          <img src={'/logos/zhiva_light.svg'} alt={'logo'} style={{ maxHeight: '24px' }} />
        </Link>
    </Box>
  );
};

export default LogoLink;
