import getQIDOQueryParams from './helpers/getQIDOQueryParameters';
import { StudyObj } from '../../types/Dicom';
import mapStudyListResults from './maps/mapStudyListResults';
import DicomServerService from './DicomServerService';
import DicomWebService from "./DicomWebService";
function filterStudyList(results: StudyObj[]): StudyObj[] {
  const studyIdFilter = Array.isArray(DicomServerService.server.restrictedListOfDicoms) && DicomServerService.server.restrictedListOfDicoms.length > 0;

  return results.filter((study) => {
      if(studyIdFilter) {
        return DicomServerService.server.restrictedListOfDicoms?.includes(study.StudyInstanceUID);
      }
      return true;
  })
}

export function getStudyList(filter: Record<string, any> = {}): Promise<StudyObj[]> {
  const dicomWeb = new DicomWebService();
  if (
    Array.isArray(DicomServerService.server.restrictedListOfDicoms) &&
    DicomServerService.server.restrictedListOfDicoms.length > 0
  ) {
    filter.StudyInstanceUID = DicomServerService.server.restrictedListOfDicoms;
  }

  const qidoSupportsIncludeField =
    DicomServerService.server.qidoSupportsIncludeField === undefined
      ? true
      : DicomServerService.server.qidoSupportsIncludeField;

  const queryParams = getQIDOQueryParams(filter, qidoSupportsIncludeField);
  const options = {
    queryParams,
  };

  return dicomWeb.call('searchForStudies', [options]).then(mapStudyListResults).then(filterStudyList);
}
