import { buildServerUri } from './buildInstanceWadoRsUri';
import DicomServerService from '../DicomServerService';
import getAuthorizationHeader from './getAuthorizationHeader';
import getXHRRetryRequestHook from '../hooks/xhrRetryRequestHook';
import { api } from 'dicomweb-client';

export function getDicomWebClientInstance(cors?: string) {
  const qidoRoot = buildServerUri(DicomServerService.server, [DicomServerService.server.qidoPostfix]);
  const config = {
    url: qidoRoot,
    headers: getAuthorizationHeader({
      requestOptions: {
        auth:
          DicomServerService.server.authType === 'basicAuth' ||
          DicomServerService.server.authType === 'token' ||
          DicomServerService.server.authType === 'zhivaAuth'
            ? {
                type: DicomServerService.server.authType,
                value:
                  DicomServerService.server.authType === 'token' || DicomServerService.server.authType === 'zhivaAuth'
                    ? `${DicomServerService.server.accessToken}`
                    : `${DicomServerService.server.login}:${DicomServerService.server.password}`,
              }
            : undefined,
        cors: cors ? cors : undefined,
      },
    }),
    errorInterceptor: (e: any) => console.error(e),
    requestHooks: [getXHRRetryRequestHook()],
  };

  return new api.DICOMwebClient(config);
}
