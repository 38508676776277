import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { DASHBOARD_ROUTE, OAUTH_ROUTE } from '../../utils/routes';
import OAuth from './OAuth';

interface Props {}

const Auth: FC<Props> = () => {
  return (
    <Switch>
      <Route path={OAUTH_ROUTE}>
        <OAuth />
      </Route>
      <Route path="*">
        <Redirect to={DASHBOARD_ROUTE} />
      </Route>
    </Switch>
  );
};

export default Auth;
