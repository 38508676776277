import React, { FC, FormEvent, useReducer } from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useAuth } from '../Auth';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { APP_TITLE, PAGE_TITLE_HOME } from '../../utils/constants';
import { LOGIN_ROUTE, DASHBOARD_ROUTE } from '../../utils/routes';
import { darkTheme } from '../Layout/theme';
import FakeAuth from '../Auth/FakeAuth';

const PREFIX = 'Login';

const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  poweredBy: `${PREFIX}-poweredby`,
  zhivaLink: `${PREFIX}-zhivalink`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.form}`]: {
    width: '100%',
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },

  [`& .${classes.poweredBy}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [`& .${classes.zhivaLink}`]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '8px',
    },
  },
}));

type State = {
  email: string;
  password: string;
  remember: boolean;
};

enum ActionKind {
  UPDATE = 'UPDATE',
  CLEAR = 'CLEAR',
}

type ActionInterface<T extends string, U> = {
  type: T;
  value: U;
  field: keyof State;
};

type AllActions = ActionInterface<ActionKind.UPDATE, string | boolean> | ActionInterface<ActionKind.CLEAR, undefined>;

const formData: State = {
  email: process.env.REACT_APP_DEMO_LOGIN || 'demo',
  password: process.env.REACT_APP_DEMO_HIDE_PASSWORD ? '' : process.env.REACT_APP_DEMO_PASSWORD || 'demo',
  remember: false,
};

function reducer(state: State, action: AllActions) {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, [action.field]: action.value };
    case 'CLEAR':
      return { ...formData };
    default:
      return state;
  }
}

interface Props {}

const Login: FC<Props> = () => {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const [state, dispatch] = useReducer(reducer, formData);

  // @ts-ignore
  const { from } = location.state || { from: { pathname: '/' } };
  const login = (e: FormEvent) => {
    e.preventDefault();

    FakeAuth.signin(state, (success) => {
      if (success) {
        auth.signin({ username: state.email, remember: state.remember }, (success) => {
          history.replace(from);
        });
      } else {
        auth.signin(null, (success) => {
          history.replace(from);
        });
      }
    });
  };

  const updateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target.name as keyof State;
    const value = field === 'remember' ? !state.remember : (e.target.value as string);
    dispatch({
      type: ActionKind.UPDATE,
      field: field,
      value: value,
    });
  };

  if (auth.user !== '' && auth.user != null) {
    return <Redirect to={from === LOGIN_ROUTE ? DASHBOARD_ROUTE : from} />;
  }

  return (
    <StyledContainer maxWidth="xs">
      <Helmet titleTemplate={`%s | ${APP_TITLE}`}>
        <title>{PAGE_TITLE_HOME}</title>
      </Helmet>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon style={{ color: darkTheme.palette.text.secondary }} />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in to access{' '}
          <code>
            {from.pathname.substring(0, Math.min(20, from.pathname.length))}
            {from.pathname.length > 20 ? '...' : ''}
          </code>
        </Typography>
        <form className={classes.form} noValidate onSubmit={login}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={state.email}
            onChange={updateField}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={state.password}
            onChange={updateField}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {auth.authError && <FormHelperText error>Provided credentials are invalid</FormHelperText>}
          <FormControlLabel
            control={
              <Checkbox name="remember" value={true} color="primary" onChange={updateField} checked={state.remember} />
            }
            label="Remember me"
          />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>

        <Typography variant="subtitle2" className={classes.poweredBy} sx={{ pt: 2 }}>
          <span>powered by </span>
          <Link href={'https://zhiva.org'} target={'_blank'} className={classes.zhivaLink}>
            <span>zhiva.org</span>
            <img src={'/logos/zhiva_light.svg'} alt={'logo'} style={{ maxHeight: '24px' }} />
          </Link>
        </Typography>
      </div>
    </StyledContainer>
  );
};

export default Login;
