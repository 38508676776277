import { DicomServer } from '../../types/dicomServer';
import getAuthorizationHeader, { AuthHeaders } from './helpers/getAuthorizationHeader';
import DicomServerService from './DicomServerService';
import { TokenService } from '../TokenService/JWTTokenService';
import getXHRRetryRequestHook from './hooks/xhrRetryRequestHook';
import { buildServerUri } from './helpers/buildInstanceWadoRsUri';
import { api } from 'dicomweb-client';
import SnackbarGenerator from "../../components/SnackbarGenerator/SnackbarGenerator";

interface IDicomWebService {
  cors?: string;
  server?: DicomServer;
}

type ServerConfig = {
  url: string;
  headers: AuthHeaders;
  errorInterceptor: (error: any) => void;
  requestHooks: ((request: XMLHttpRequest, metadata: { url: string; method: string }) => XMLHttpRequest)[];
};

export default class DicomWebService {
  private _config: ServerConfig;
  private _server: DicomServer;
  private _useCors: string | undefined;
  private _dicomClient: any;

  constructor({ cors, server }: IDicomWebService = {}) {
    this._server = server || DicomServerService.server;
    this._useCors = cors;
    this._config = this.generateConfig(this._server)

    this.updateDicomClient();
  }

  call = async (method: string, params: any[]): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this._dicomClient[method](...params);
        resolve(result);
      } catch (e: any) {
        if(this.isSeverUsingJWT() && TokenService.isTokenExpired(this._server.accessToken || '') && this.doesServerHasRefresh() && !TokenService.isTokenExpired(this._server.refreshToken || '')) {
          try {
            const refreshedToken = await this.refreshJWT();
            if(refreshedToken) {
              //retry request
              const result = await this._dicomClient[method](...params);
              resolve(result)
            }
          } catch (e) {
            SnackbarGenerator.error('PACS authentication failed');
            reject();
          }
        } else {
          SnackbarGenerator.error('PACS authentication failed');
          reject();
        }
      }
    });
  };

  private isSeverUsingJWT(): boolean {
    return this._server.authType === 'zhivaAuth' || this._server.authType === 'token';
  }

  private doesServerHasRefresh(): boolean {
    return Boolean(this._server.refreshToken && this._server.refreshServer)
  }

  private async refreshJWT() {
    return TokenService.getRefreshToken(this._server.refreshToken as string, {
      tokenServer: this._server.refreshServer as string,
    }).then((res) => {
      if (res) {
        const { accessToken: newAccessToken } = res;
        DicomServerService.setNewToken(newAccessToken);
        this._server.accessToken = newAccessToken;

        this.updateDicomClient();
        return newAccessToken;
      }
      return false;
    });
  }

  private generateConfig(server: DicomServer): ServerConfig {
    const qidoRoot = buildServerUri(server, [server.qidoPostfix]);
    return {
      url: qidoRoot,
      headers: getAuthorizationHeader({
        requestOptions: {
          auth:
            this._server.authType === 'basicAuth' ||
            this._server.authType === 'token' ||
            this._server.authType === 'zhivaAuth'
              ? {
                type: this._server.authType,
                value:
                  this._server.authType === 'token' || this._server.authType === 'zhivaAuth'
                    ? `${this._server.accessToken}`
                    : `${this._server.login}:${this._server.password}`,
              }
              : undefined,
          cors: this._useCors ? this._useCors : undefined,
        },
      }),
      errorInterceptor: (e: any) => {
        console.error(e);
      },
      requestHooks: [getXHRRetryRequestHook()],
    };
  }

  private updateDicomClient() {
    this._config = this.generateConfig(this._server)
    this._dicomClient = new api.DICOMwebClient(this._config);
  }
}
