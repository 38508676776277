import { dataFiledDef } from "./DicomList";

type columnFilter = {
  key: keyof dataFiledDef,
  value?: any
}

/**
 * Filters out columns when at least one of the condition is true
 * @param filters
 */
export function filterColumnElemenets(filters: columnFilter[] = []) {
  return (column: dataFiledDef): boolean => {
    return !filters.some(filter => column[filter.key] === filter.value)
  }
}