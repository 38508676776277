import { ModelServer } from '../../types/modelServer';
import { InstanceUIDs } from '../../types/Dicom';
import { ModelSupportedPaths, ModelTypes } from '../../components/Settings/ModelSettings/ModelSchema';
import DicomServerInstance from '../DicomService/DicomServerService';
import { ModelRequestOptions } from './ModelServerService';

export const generateModelAPIPath = (
  model: ModelServer,
  dicomData: {
    StudyInstanceUID: InstanceUIDs['StudyInstanceUID'];
    SeriesInstanceUID?: InstanceUIDs['SeriesInstanceUID'];
    SOPInstanceUID?: InstanceUIDs['SOPInstanceUID'];
  },
  options: ModelRequestOptions = {},
) => {
  let prefix = '';
  switch (model.type) {
    case ModelTypes.SEGMENTATION:
      prefix = 'segmentations';
      break;
    case ModelTypes.ANNOTATION:
      prefix = 'annotations';
      break;
    case ModelTypes.PREDICTION:
      prefix = 'predictions';
      break;
    default:
      throw Error(`Model Server ${model.uuid} has no defined type of inference`);
  }
  const queryParams = [];
  let postfix = '';
  if (model.isDemo) {
    postfix = '/index.json';
  }
  const selectedServer = DicomServerInstance.getSelectedServer();
  if (selectedServer.uuid != null && selectedServer.uuid.length > 0) {
    queryParams.push({ key: 'server', value: selectedServer.uuid });
  }

  if (options.filters != null) {
    queryParams.push({ key: 'data-filters', value: JSON.stringify(options.filters) });
  }

  if (queryParams.length > 0) {
    postfix += `?${new URLSearchParams(
      queryParams.reduce((acc, param) => ({ ...acc, [param.key]: param.value }), {}),
    ).toString()}`;
  }

  switch (model.supports) {
    case ModelSupportedPaths.STUDIES:
      return `${model.url}/${prefix}/${model.uuid}/studies/${dicomData.StudyInstanceUID}${postfix}`;
    case ModelSupportedPaths.SERIES:
      return `${model.url}/${prefix}/${model.uuid}/studies/${dicomData.StudyInstanceUID}/series/${dicomData.SeriesInstanceUID}${postfix}`;
    case ModelSupportedPaths.INSTANCES:
      return `${model.url}/${prefix}/${model.uuid}/studies/${dicomData.StudyInstanceUID}/series/${dicomData.SeriesInstanceUID}/instances/${dicomData.SOPInstanceUID}${postfix}`;
    default:
      throw Error(`Model Server ${model.uuid} has no defined type of prediction path`);
  }
};
