import { seriesResultsResponse } from '../../../types/dicomServer';
import { mapSeries } from '../maps/mapSeriesListResults';
import { SeriesObj } from "../../../types/Dicom";
import { getNumberValue } from "../../../utils/dicomCodesUtils";
import { COMMON_DICOM_CODES } from "../../../utils/constants";

function seriesInfoSortingCriteria(firstSeries: seriesResultsResponse, secondSeries: seriesResultsResponse): number {
  const a = mapSeries(firstSeries);
  const b = mapSeries(secondSeries);
  if (!a.isLowPriority && b.isLowPriority) {
    return -1;
  }
  if (a.isLowPriority && !b.isLowPriority) {
    return 1;
  }
  return a.SeriesNumber - b.SeriesNumber;
}

const seriesSortCriteria = {
  default: (a: SeriesObj, b: SeriesObj) => a.SeriesNumber - b.SeriesNumber,
  seriesInfoSortingCriteria,
};

const instancesSortCriteria = {
  default: (a: seriesResultsResponse, b: seriesResultsResponse) => getNumberValue(a[COMMON_DICOM_CODES.INSTANCE_NUMBER]) - getNumberValue(b[COMMON_DICOM_CODES.INSTANCE_NUMBER]),
};

const sortingCriteria = {
  seriesSortCriteria,
  instancesSortCriteria,
};

const sortStudySeries = (series: SeriesObj[], seriesSortingCriteria = seriesSortCriteria.default) => {
  return series.sort(seriesSortingCriteria);
};

const sortStudyInstances = (instancesList: seriesResultsResponse[], instancesSortingCriteria = instancesSortCriteria.default) => {
  return instancesList.sort(instancesSortingCriteria);
};

// /**
//  * Sorts the series and instances (by default) inside a study instance based on sortingCriteria (given param is modified)
//  * The default criteria is based on series and instance numbers in ascending order.
//  *
//  * @param {Object} study The study instance
//  * @param {boolean} [deepSort = true] to sort instance also
//  * @param {function} [seriesSortingCriteria = seriesSortCriteria.default] method for sorting series
//  * @param {function} [instancesSortingCriteria = instancesSortCriteria.default] method for sorting instances
//  * @returns {Object} sorted study object
//  */
// export default function sortStudy(
//   study: StudyObj,
//   deepSort = true,
//   seriesSortingCriteria = seriesSortCriteria.default,
//   instancesSortingCriteria = instancesSortCriteria.default,
// ) {
//   if (!study || !study.series) {
//     throw new Error('Insufficient study data was provided to sortStudy');
//   }
//
//   sortStudySeries(study.series, seriesSortingCriteria);
//
//   if (deepSort) {
//     study.series.forEach((series) => {
//       sortStudyInstances(series.instances, instancesSortingCriteria);
//     });
//   }
//
//   return study;
// }

export { sortStudySeries, sortStudyInstances, sortingCriteria };
