import cornerstoneTools from 'cornerstone-tools';
import dcmjs from 'dcmjs';
import { EnabledElement } from 'cornerstone-core';
import { InstanceMetadataObj, InstanceUIDs, SegmentationObj, SeriesObj } from '../../types/Dicom';
import { StudyInstance } from '../../types/StudyInstance';
import { buildWADORSImageId } from '../DicomService/helpers/buildInstanceWadoRsUri';
import DicomServerService from '../DicomService/DicomServerService';
import { LOCAL_STORAGE } from '../../utils/constants';
import { getSegInstancePixelData, getSegmentationSeriesMetaData } from '../DicomService/DicomSeriesService';
import MetadataProvider from '../../utils/cornerstoneUtils/MetadataProvider';
import createSeg from './generateSegmentationBlob';
import csTools from 'cornerstone-tools';
import { wadorsUrlParser } from '../../utils/cornerstoneUtils/urlParser';
import createSegmentationObj from './createSegmentationObj';
import saveFile from '../../utils/saveFile';
import storeSegToPACS from './storeSegToPACS';
import SnackbarGenerator from '../../components/SnackbarGenerator/SnackbarGenerator';
const { getters, setters } = cornerstoneTools.getModule('segmentation');

export type BrushColorClass = {
  color: string;
  name: string;
  class: string;
  activeLabelmapIndex: number;
  source: 'dcm' | 'model' | 'manual' | 'setting';
};

export type BrushColorClasses = Record<string, BrushColorClass>;

export type SegmentInstance = {
  name: string;
  segObj: SegmentationObj;
  affectedSeriesUIDs: InstanceUIDs['SeriesInstanceUID'][];
  classes: string[];
};

const InitBrushClasses: BrushColorClasses = {
  other: {
    color: 'rgb(211,255,20)',
    name: 'Other',
    class: 'other',
    activeLabelmapIndex: 0,
    source: 'setting',
  },
};

export function parseNameToClassString(name: string): string {
  return name.toLowerCase().replace(/\s/g, '_').replace(/\W/g, '');
}
class SegmentationAPI {
  csElementRef: HTMLElement | null;
  dicomSeg: Record<string, SeriesObj>;
  segInstances: Record<string, SegmentInstance>;
  userSelectedClasses: Set<string>;
  autoSelectedClasses: Set<string>;
  LUTColorClasses: BrushColorClasses;
  LUTColorClassesFromModels: BrushColorClasses;
  LUTColorClassesFromUser: BrushColorClasses;
  ActiveLUTColorClasses: Set<BrushColorClass['class']>;
  DisplayedLUTColorClasses: BrushColorClasses;
  changeCallback?: ({
    displayed,
    active,
  }: {
    displayed: BrushColorClasses;
    active: BrushColorClass['class'][];
    segments: Record<string, SegmentInstance>;
  }) => void;

  constructor() {
    const storedValRaw = localStorage.getItem(LOCAL_STORAGE.CUSTOM_SEG_CLASSES_KEY);
    this.csElementRef = null;
    this.dicomSeg = {};
    this.segInstances = {};
    this.LUTColorClassesFromModels = {};
    this.LUTColorClassesFromUser = {};
    this.ActiveLUTColorClasses = new Set<BrushColorClass['class']>();
    this.DisplayedLUTColorClasses = {};
    this.LUTColorClasses = {};
    this.userSelectedClasses = new Set<string>();
    this.autoSelectedClasses = new Set<string>();

    if (storedValRaw != null) {
      this.LUTColorClassesFromUser = { ...InitBrushClasses, ...JSON.parse(storedValRaw || '{}') };
    } else {
      this.LUTColorClassesFromUser = InitBrushClasses;
      this.DisplayedLUTColorClasses = InitBrushClasses;
    }
    Object.values(this.LUTColorClassesFromUser).forEach((el) => this.ActiveLUTColorClasses.add(el.class));
    this.updateAvailableBrushClassesFromState();
  }

  setAvailableBrushClassesFromModels = (newBrushClasses: BrushColorClasses): void => {
    this.LUTColorClassesFromModels = newBrushClasses;
    this.updateAvailableBrushClassesFromState();
  };

  updateAvailableBrushClassesFromState = (): void => {
    this.LUTColorClasses = {
      ...this.LUTColorClassesFromUser,
      ...this.LUTColorClassesFromModels,
    };
  };

  setAvailableBrushClasses = (newBrushClasses: BrushColorClasses): void => {
    this.LUTColorClasses = newBrushClasses;
  };

  getLastLabelMapIndex = (): number => {
    return Object.values(this.LUTColorClasses).reduce((acc, lutColorClass) => {
      if (lutColorClass.activeLabelmapIndex > acc) {
        return lutColorClass.activeLabelmapIndex;
      }
      return acc;
    }, 0);
  };

  addAvailableBrushClasses = (newBrushClass: BrushColorClasses): void => {
    Object.entries(newBrushClass).forEach(([index, brushClassDef]) => {
      this.LUTColorClasses[index] = {
        ...brushClassDef,
      };
    });
  };

  handleActiveClassesChange = () => {
    if (this.changeCallback) {
      this.changeCallback({
        displayed: this.getCurrClasses(),
        active: this.getActiveClasses(),
        segments: this.segInstances,
      });
    }
  };

  addAutoSelectedClasses = (classes: string[]): void => {
    classes.forEach((item) => {
      this.autoSelectedClasses.add(item);
      this.ActiveLUTColorClasses.add(item);
    });
    this.updateExistingClasses();
  };

  selectActiveBrushClasses = (classes: string[]): void => {
    this.userSelectedClasses = new Set<string>(classes);
    this.updateExistingClasses();
  };

  addSegmentation = (
    seriesData: {
      [sopInstanceUID: InstanceMetadataObj['SOPInstanceUID']]: {
        segments: { points: number[][]; className: string; color: string }[];
      };
    },
    StudyInstanceUID: string,
    SeriesInstanceUID: string,
    study: StudyInstance,
    defaultSegmentationGroupName?: string
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      if (this.csElementRef != null) {
        const newSeg = createSegmentationObj({
          name: defaultSegmentationGroupName ? `${defaultSegmentationGroupName} (${Object.keys(this.segInstances).length + 1})` : `Segmentation (${Object.keys(this.segInstances).length + 1})`,
          rows: 0,
          columns: 0,
          studyUID: StudyInstanceUID,
          seriesNumber: 100,
        });
        this.segInstances[newSeg.id] = {
          name: newSeg.SeriesDescription,
          segObj: newSeg,
          affectedSeriesUIDs: [SeriesInstanceUID as string],
          classes: [],
        };
        const images = study.series[SeriesInstanceUID].instances;
        let rows,
          columns = null;
        let activeLabelmaps: Record<string, BrushColorClass> = {};
        let views: Record<string, Uint16Array> = {};
        let buffers: Record<string, ArrayBuffer> = {};

        for (const [SOPInstanceUID, series] of Object.entries(seriesData).filter(([key, data]) => key !== 'metadata')) {
          if (rows == null) {
            rows = series.segments[0].points.length;
            this.segInstances[newSeg.id].segObj.rows = rows;
          }
          if (columns == null) {
            columns = series.segments[0].points[0].length;
            this.segInstances[newSeg.id].segObj.columns = columns;
          }
          for (const segmentData of series.segments) {
            if (activeLabelmaps[segmentData.className] == null) {
              activeLabelmaps[segmentData.className] =
                Object.values(this.LUTColorClasses).find(
                  (lut) => lut.class === parseNameToClassString(segmentData.className),
                ) || this.LUTColorClasses['other'];
            }
            if (buffers[segmentData.className] == null) {
              buffers[segmentData.className] = new ArrayBuffer(rows * columns * images.length * 2);
              views[segmentData.className] = new Uint16Array(buffers[segmentData.className]);
            }
          }
          const imageIdx = images.findIndex((currImg) => currImg.SOPInstanceUID === SOPInstanceUID);
          const byteOffset = imageIdx * rows * columns;

          for (const segmentData of series.segments) {
            for (let rowIdx = 0; rowIdx < rows; rowIdx += 1) {
              for (let colIdx = 0; colIdx < columns; colIdx += 1) {
                // @ts-ignore
                views[segmentData.className][byteOffset + rowIdx * columns + colIdx] =
                  segmentData.points[rowIdx][colIdx];
              }
            }
          }
        }

        for (const className in views) {
          if (views[className] != null && activeLabelmaps[className] != null) {
            const firstWadorsImageId = buildWADORSImageId(
              DicomServerService.server,
              StudyInstanceUID,
              SeriesInstanceUID,
              images[0].SOPInstanceUID,
            );

            setters.labelmap3DByFirstImageId(
              firstWadorsImageId,
              views[className].buffer,
              activeLabelmaps[className].activeLabelmapIndex,
              [],
              images.length,
              undefined,
              activeLabelmaps[className].activeLabelmapIndex,
            );
            this.segInstances[newSeg.id].classes = [
              ...this.segInstances[newSeg.id].classes,
              activeLabelmaps[className].class,
            ];
            this.ActiveLUTColorClasses.add(activeLabelmaps[className].class);
            this.redrawCSElement();
          } else {
            console.warn(
              `When parsing segments for series: ${SeriesInstanceUID}, buffer was not initialized properly.`,
            );
          }
        }
        this.handleActiveClassesChange();
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  getCurrClasses = (): BrushColorClasses => {
    return this.DisplayedLUTColorClasses;
  };

  getActiveClasses = (): BrushColorClass['class'][] => {
    const activeClasses = Object.values(this.LUTColorClasses)
      .filter((brushClass) => {
        return getters.isSegmentVisible(this.csElementRef, 1, brushClass.activeLabelmapIndex);
      })
      .map((brushClass) => brushClass.class);
    this.ActiveLUTColorClasses = new Set(activeClasses);
    return Array.from(this.ActiveLUTColorClasses);
  };

  redrawCSElement = (): void => {
    if (this.csElementRef) {
      window.cornerstone.updateImage(this.csElementRef);
    } else {
      console.warn('Trying to redraw CS element and the element is not available in Segmentation API');
    }
  };

  saveSegmentation = async (segId: string, shouldDownload: boolean = false) => {
    if (this.segInstances[segId] && this.csElementRef != null) {
      const segData = createSeg(this.segInstances[segId], this.LUTColorClasses, this.csElementRef);
      try {
        if (segData != null) {
          if (shouldDownload) {
            saveFile(`${this.segInstances[segId].name}.dcm`, 'dcm', segData.blob);
          } else {
            const serverRes = await storeSegToPACS(`${this.segInstances[segId].name}.dcm`, 'dcm', segData.dataset);
            if (JSON.parse(serverRes)?.['00081190']?.vr) {
              SnackbarGenerator.success('Segmentation stored');
            } else {
              SnackbarGenerator.error('Error when storing segmentation');
            }
          }
        }
      } catch (e) {
        SnackbarGenerator.error('Error when storing segmentation');
      }
    }
  };

  toggleActiveClass = (activeLUTColorClass: BrushColorClass['class']): void => {
    if (this.ActiveLUTColorClasses.has(activeLUTColorClass)) {
      this.ActiveLUTColorClasses.delete(activeLUTColorClass);
      setters.toggleSegmentVisibility(
        this.csElementRef,
        1,
        this.DisplayedLUTColorClasses[activeLUTColorClass].activeLabelmapIndex,
      );
      this.redrawCSElement();
    } else {
      if (this.DisplayedLUTColorClasses[activeLUTColorClass] != null) {
        this.ActiveLUTColorClasses.add(activeLUTColorClass);
        setters.toggleSegmentVisibility(
          this.csElementRef,
          1,
          this.DisplayedLUTColorClasses[activeLUTColorClass].activeLabelmapIndex,
        );
        this.redrawCSElement();
      }
    }
    this.handleActiveClassesChange();
  };

  synchronizeSegmentationUpdate = (
    newSegments: Record<string, SegmentInstance>,
    newBrushClasses: BrushColorClasses,
  ): void => {
    Object.entries(newSegments).forEach(([segId, segInstance]) => {
      if (this.segInstances[segId] == null) {
        this.segInstances[segId] = segInstance;
      } else {
        this.segInstances[segId] = {
          ...segInstance,
          segObj: { ...segInstance.segObj },
        };
      }
    });
    Object.entries(newBrushClasses).forEach(([brushClassId, brushClass]) => {
      if (this.LUTColorClasses[brushClassId] == null) {
        const highestActiveLabelMap = Object.values(this.LUTColorClasses).reduce(
          (acc, colorClass) => (colorClass.activeLabelmapIndex > acc ? colorClass.activeLabelmapIndex : acc),
          1,
        );
        this.LUTColorClasses[brushClassId] = {
          ...brushClass,
          activeLabelmapIndex: highestActiveLabelMap + 1,
        };
        this.userSelectedClasses.add(brushClassId);
      } else {
        this.LUTColorClasses[brushClassId] = {
          ...brushClass,
        };
      }
    });
    this.updateExistingClasses();
  };

  updateExistingClasses = (): void => {
    this.DisplayedLUTColorClasses = Object.entries(this.LUTColorClasses).reduce<BrushColorClasses>(
      (acc, [classId, brushClass]) => {
        acc[classId] = {
          ...brushClass,
        };
        return acc;
      },
      {},
    );
    Object.values(this.DisplayedLUTColorClasses).forEach((colorClass) => {
      const colorArr = [
        ...colorClass.color
          .replace(/[^\d,]/g, '')
          .split(',')
          .map((el) => Number(el)),
        255,
      ];
      setters.colorLUT(colorClass.activeLabelmapIndex, [colorArr]);
    });
    this.handleActiveClassesChange();
  };

  setActiveLabelmapId = (activeLabelmapIndex: number): void => {
    if (this.csElementRef && activeLabelmapIndex !== getters.activeLabelmapIndex(this.csElementRef)) {
      setters.activeLabelmapIndex(this.csElementRef, activeLabelmapIndex);
      const curr3DLabelmap = getters.labelmap3D(this.csElementRef, activeLabelmapIndex);
      setters.colorLUTIndexForLabelmap3D(curr3DLabelmap, activeLabelmapIndex);
      this.redrawCSElement();
    }
  };

  handleLabelmapModified = (e: any): void => {
    if (this.csElementRef) {
      const currImage = window.cornerstone.getEnabledElement(this.csElementRef).image;
      const currImageUIDs = wadorsUrlParser(currImage?.imageId || '');

      if (
        currImage != null &&
        !Object.values(this.segInstances).some((el) =>
          el.affectedSeriesUIDs.includes(currImageUIDs.SeriesInstanceUID || ''),
        )
      ) {
        const newSeg = createSegmentationObj({
          name: `Segmentation (${Object.keys(this.segInstances).length + 1})`,
          rows: currImage.rows,
          columns: currImage.columns,
          studyUID: currImageUIDs.StudyInstanceUID,
          seriesNumber: 100,
        });
        const activeClass = Object.entries(this.LUTColorClasses).find(
          ([classId, brushClass]) => brushClass.activeLabelmapIndex === e.detail.labelmapIndex,
        );
        if (activeClass) {
          this.segInstances[newSeg.id] = {
            name: newSeg.SeriesDescription,
            segObj: newSeg,
            affectedSeriesUIDs: [currImageUIDs.SeriesInstanceUID as string],
            classes: [activeClass[0]],
          };
          this.updateExistingClasses();
        }
      }
    }
  };

  enableListeners = (csEvent: { detail: EnabledElement }) => {
    const csElement = csEvent.detail.element;

    if (this.csElementRef != null) {
      this.removeListeners(this.csElementRef);
    }
    if (csElement != null) {
      this.csElementRef = csElement;
      csElement.addEventListener(csTools.EVENTS.LABELMAP_MODIFIED, this.handleLabelmapModified);
    }
  };

  disableListeners = (csEvent: { detail: EnabledElement }) => {
    const csElement = csEvent.detail.element;

    this.csElementRef = null;
    this.removeListeners(csElement);
  };

  removeListeners = (element: EnabledElement['element']) => {
    if (element != null) {
      element.removeEventListener(csTools.EVENTS.LABELMAP_MODIFIED, this.handleLabelmapModified);
    }
  };

  setDicomSegMetaData = (segmentationsSeries: SeriesObj[]) => {
    for (let series of segmentationsSeries) {
      this.dicomSeg[`studies/${series.StudyInstanceUID}/series/${series.SeriesInstanceUID}`] = series;
    }
  };

  loadSegmentationInstanceFromPACS = async () => {
    const segmentationsSeries = Object.values(this.dicomSeg);
    if (segmentationsSeries.length < 1) {
      return;
    }
    const segMetadata = await getSegmentationSeriesMetaData(
      segmentationsSeries[0].StudyInstanceUID,
      segmentationsSeries,
    );

    for (let dicomSegObj of segMetadata) {
      for (let segInstance of dicomSegObj) {
        this.segInstances[segInstance.id] = {
          segObj: segInstance,
          name: segInstance['SeriesDescription'],
          affectedSeriesUIDs: segInstance.ReferencedSeriesSequence.map((refSeries) => refSeries.SeriesInstanceUID),
          classes: [],
        };
        const arrayData = await getSegInstancePixelData(segInstance);

        const affectedSeriesKeys = Array.from(
          MetadataProvider.studies
            .get(segInstance.StudyInstanceUID)
            .series.get(segInstance.ReferencedSeriesSequence[0].SeriesInstanceUID)
            .instances.keys(),
        ).map((sopUID) =>
          buildWADORSImageId(
            DicomServerService.server,
            segInstance.StudyInstanceUID,
            segInstance.ReferencedSeriesSequence[0].SeriesInstanceUID,
            sopUID as string,
          ),
        );

        const {
          labelmapBufferArray,
          segMetadata: segParsedMetadata,
          segmentsOnFrame,
        } = dcmjs.adapters.Cornerstone.Segmentation.generateToolState(
          affectedSeriesKeys,
          arrayData,
          window.cornerstone.metaData,
        );
        let currSegIndex = segInstance.SegmentSequence[0].SegmentNumber;

        for (let segArrayBuffer of labelmapBufferArray) {
          const selectedSegMetadata = segParsedMetadata.data[currSegIndex];

          const DefaultDisplayColor = dcmjs.data.Colors.dicomlab2RGB(
            selectedSegMetadata.RecommendedDisplayCIELabValue,
          ).map((x: number) => Math.round(x * 255));

          let matchedLutColorClass = Object.values(this.LUTColorClasses).find(
            (lut) => lut.class === parseNameToClassString(selectedSegMetadata.SegmentLabel),
          );

          if (matchedLutColorClass == null) {
            this.addAvailableBrushClasses({
              [parseNameToClassString(selectedSegMetadata.SegmentLabel) as string]: {
                color: `rgb(${DefaultDisplayColor[0]}, ${DefaultDisplayColor[1]}, ${DefaultDisplayColor[2]})`,
                name: selectedSegMetadata.SegmentLabel,
                class: parseNameToClassString(selectedSegMetadata.SegmentLabel),
                activeLabelmapIndex: this.getLastLabelMapIndex() + 1,
                source: 'dcm',
              },
            });
            this.addAutoSelectedClasses([parseNameToClassString(selectedSegMetadata.SegmentLabel) as string]);
            matchedLutColorClass = this.LUTColorClasses[parseNameToClassString(selectedSegMetadata.SegmentLabel)];
          }

          this.segInstances[segInstance.id].classes.push(matchedLutColorClass.class as string);

          setters.labelmap3DByFirstImageId(
            affectedSeriesKeys[0],
            segArrayBuffer,
            matchedLutColorClass.activeLabelmapIndex,
            segParsedMetadata,
            affectedSeriesKeys.length,
            segmentsOnFrame,
            matchedLutColorClass.activeLabelmapIndex,
          );

          currSegIndex += 1;
        }
      }
    }
    this.redrawCSElement();
    this.handleActiveClassesChange();
  };
}

export default new SegmentationAPI();
