import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import DicomList from './DicomList';

// constants
import { HEADER_HEIGHT, PAGE_TITLE_DICOMS } from '../../utils/constants';
import { Helmet } from 'react-helmet-async';
import { DicomListElement } from '../../types/Dicom';
import { dicomListSelector } from '../../recoil/dicoms';
import { useRecoilState } from 'recoil';
import { getStudyList } from '../../services/DicomService/DicomStudyService';

const PREFIX = 'Studies';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  fixedHeight: `${PREFIX}-fixedHeight`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),

    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  [`& .${classes.fixedHeight}`]: {
    height: `calc(100vh - ${HEADER_HEIGHT}px`,
  }
}));

interface Props {}

const Dicoms: FC<Props> = () => {

  const [isLoading, setIsLoading] = useState(false);
  // const { isLoading, data } = useFetch<DicomResponse>('/dicoms.json');
  const [storedList, setStoredList] = useRecoilState<DicomListElement[]>(dicomListSelector);

  // useEffect(() => {
  //   if (!isLoading && data != null) {
  //     setStoredList(data.data);
  //   }
  // }, [isLoading, data, setStoredList]);

  useEffect(() => {
    setIsLoading(true);
    getStudyList({limit: 100, offset: 0}).then((data) => {
      setStoredList(data);
      setIsLoading(false);
    });
  }, [setStoredList, setIsLoading]);

  return (
    <StyledContainer maxWidth="lg" className={classes.container}>
      <Helmet>
        <title>{PAGE_TITLE_DICOMS}</title>
        <meta name="header" content={PAGE_TITLE_DICOMS} />
      </Helmet>
      <Grid container spacing={0} margin={0} id={'dicomListContainer'}>
        <Grid item xs={12} className={classes.fixedHeight}>
          <DicomList isLoading={isLoading} data={storedList} />
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Dicoms;
