import cornerstoneTools from 'cornerstone-tools';
import dcmjs from 'dcmjs';
import { BrushColorClasses, SegmentInstance } from './SegmentationAPI';
import { defaultStringColor, matchColors } from '../../components/common/ColorPicker';
import createSegFromImages, { CreateSegmentationOptions } from '../../utils/cornerstoneUtils/createSegFromImages';
import MetadataProvider from '../../utils/cornerstoneUtils/MetadataProvider';
import { wadorsUrlParser } from '../../utils/cornerstoneUtils/urlParser';
import fillSegmentation from '../../utils/cornerstoneUtils/fillSegmentation';
const { getters } = cornerstoneTools.getModule('segmentation');

export default function createSeg(
  segInstance: SegmentInstance,
  brushClasses: BrushColorClasses,
  csElement: HTMLElement,
): { blob: Blob; dataset: any } | null {
  const stackToolState = cornerstoneTools.getToolState(csElement, 'stack');
  const imageIds = stackToolState.data[0].imageIds;

  let images = [];
  for (let i = 0; i < imageIds.length; i++) {
    const UIDs = wadorsUrlParser(imageIds[i]);
    images.push(
      MetadataProvider.studies
        .get(UIDs.StudyInstanceUID)
        .series.get(UIDs.SeriesInstanceUID)
        .instances.get(UIDs.SOPInstanceUID),
    );
  }

  const { labelmaps3D } = getters.labelmaps3D(csElement);

  if (!labelmaps3D) {
    return null;
  }

  const selectedLabelmaps3DCopy = [];

  for (let segClass of segInstance.classes) {
    const selectedClass = brushClasses[segClass];
    const labelmap3D = labelmaps3D[selectedClass.activeLabelmapIndex];
    const labelmaps2D = labelmap3D.labelmaps2D;

    if (!Array.isArray(labelmap3D.metadata)) {
      labelmap3D.metadata = [];
    }

    for (let i = 0; i < labelmaps2D.length; i++) {
      if (!labelmaps2D[i]) {
        continue;
      }

      const segmentsOnLabelmap = labelmaps2D[i].segmentsOnLabelmap;

      segmentsOnLabelmap.forEach((segmentIndex: number) => {
        if (segmentIndex !== 0 && !labelmap3D.metadata[segmentIndex]) {
          labelmap3D.metadata[segmentIndex] = generateSegMetadata({
            segmentIndex: segmentIndex,
            segColor: selectedClass.color,
            segName: selectedClass.name,
          });
        }
      });
    }
    selectedLabelmaps3DCopy.push(labelmap3D);
  }

  try {
    const options = {
      ...segInstance.segObj,
      StudyDescription: images[0].StudyDescription,
      ContentLabel: segInstance.name,
    };
    const segDataset = createSegFromImages(images, false, options as any as CreateSegmentationOptions);
    segDataset.dataset.StudyDescription = images[0].StudyDescription;
    segDataset.dataset.BodyPartExamined = images[0].BodyPartExamined;
    const segBlob = fillSegmentation(segDataset, selectedLabelmaps3DCopy);

    return { blob: segBlob as Blob, dataset: segDataset };
  } catch (e) {
    console.error(e);
    return null;
  }
}

type SegMetadataOptions = {
  segmentIndex: number;
  segColor: string; // rgb(x,y,z)
  segName: string;
  CodeSequence?: {
    CodeValue?: string;
    CodingSchemeDesignator?: string;
    CodeMeaning?: string;
  };
  SegmentAlgorithmType?: 'MANUAL' | 'AUTOMATIC' | 'SEMIAUTOMATIC';
  SegmentAlgorithmName?: string;
};

function generateSegMetadata({
  segmentIndex,
  segColor,
  segName,
  CodeSequence,
  SegmentAlgorithmType,
  SegmentAlgorithmName,
}: SegMetadataOptions) {
  const selectedColorParts = matchColors.exec(segColor) || matchColors.exec(defaultStringColor);

  const RecommendedDisplayCIELabValue = dcmjs.data.Colors.rgb2DICOMLAB(
    (selectedColorParts as any as number[]).slice(1).map((el) => el / 255),
  );

  return {
    SegmentedPropertyCategoryCodeSequence: {
      CodeValue: CodeSequence?.CodeValue || 'T-D0050', // https://dicom.nema.org/medical/dicom/current/output/chtml/part16/sect_CID_7150.html
      CodingSchemeDesignator: CodeSequence?.CodingSchemeDesignator || 'SCT',
      CodeMeaning: CodeSequence?.CodeMeaning || 'Tissue',
    },
    SegmentNumber: (segmentIndex + 1).toString(),
    SegmentLabel: segName,
    SegmentAlgorithmType: SegmentAlgorithmType || 'MANUAL',
    SegmentAlgorithmName: SegmentAlgorithmName,
    RecommendedDisplayCIELabValue,
    SegmentedPropertyTypeCodeSequence: {
      CodeValue: CodeSequence?.CodeValue || 'T-D0050',
      CodingSchemeDesignator: CodeSequence?.CodingSchemeDesignator || 'SCT',
      CodeMeaning: CodeSequence?.CodeMeaning || 'Tissue',
    },
  };
}
