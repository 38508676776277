import React, { FC, useState } from 'react';
import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Helmet } from 'react-helmet-async';

import { styled, useTheme } from '@mui/material/styles';

import { DRAWER_WIDTH } from '../../utils/constants';
import { useTour } from '@reactour/tour';
import { useRouteMatch } from 'react-router-dom';
import { ROUTES_WITH_STEALTH_MODE } from '../../utils/routes';
import useMediaQuery from '@mui/material/useMediaQuery';

const PREFIX = 'Header';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcon: `${PREFIX}-toolbarIcon`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonHidden: `${PREFIX}-menuButtonHidden`,
  title: `${PREFIX}-title`,
  logo: `${PREFIX}-logo`,
};

const StyledAppBar = styled(AppBar)(({ theme, enableColorOnDark }) => ({
  [`& .${classes.toolbar}`]: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  [`& .${classes.toolbarIcon}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  [`&.${classes.appBar}`]: {
    backgroundColor: !enableColorOnDark ? undefined : theme.palette.primary.dark,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin', 'background-color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`&.${classes.appBarShift}`]: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.menuButton}`]: {
    marginRight: 36,
    color: theme.palette.text.primary,
  },

  [`& .${classes.menuButtonHidden}`]: {
    display: 'none',
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },

  [`& .${classes.logo}`]: {
    maxHeight: '32px',
    paddingRight: '16px',
  },
}));

interface Props {
  handleDrawerOpen: () => void;
  drawerOpen: boolean;
}

const Header: FC<Props> = ({ handleDrawerOpen, drawerOpen }) => {
  const [title, setTitle] = useState('Home');
  const theme = useTheme();
  const isSmallOrLarger = useMediaQuery(theme.breakpoints.up('sm'));
  const stealthMode = useRouteMatch({
    path: ROUTES_WITH_STEALTH_MODE,
  });

  const { setIsOpen } = useTour();

  const checkHeaderMetaChange = (helmetState: any) => {
    const headerMeta = helmetState.metaTags.filter((meta: HTMLMetaElement) => meta.name === 'header');
    if (headerMeta.length > 0) {
      setTitle(headerMeta[0].content);
    }
  };

  const displayTour = () => {
    setIsOpen(true);
  };

  return (
    <StyledAppBar
      enableColorOnDark={!stealthMode}
      position="absolute"
      className={clsx(classes.appBar, drawerOpen && isSmallOrLarger && classes.appBarShift)}
      elevation={1}
    >
      <Helmet onChangeClientState={checkHeaderMetaChange} />
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, drawerOpen && isSmallOrLarger && classes.menuButtonHidden)}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          {title}
        </Typography>
        {process.env.REACT_APP_DEMO_LOGO && (
          <img src={process.env.REACT_APP_DEMO_LOGO} alt={'logo'} className={classes.logo} />
        )}
        <IconButton color="inherit" onClick={displayTour} aria-label={'help-button'} size="large">
          <HelpOutlineIcon fontSize={'medium'} color={'action'} />
        </IconButton>
        <IconButton color="inherit" size="large">
          <AccountBoxOutlinedIcon fontSize={'large'} color={'action'} />
        </IconButton>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
