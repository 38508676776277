import { FC, useEffect } from 'react';
import { DASHBOARD_ROUTE, getSettingsServerRoutes, getStudyRoute } from '../../utils/routes';
import { parseJwt } from '../../utils/jwt';
import { TokenService } from '../../services/TokenService/JWTTokenService';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { dicomServerList, dicomServerSelector } from '../../recoil/dicomServer';
import { DicomServer } from '../../types/dicomServer';
import { emptyServerSetting } from '../../configs/dicomServer';
import { useSnackbar } from 'notistack';
import { useAuth } from './AuthProvider';
import { AuthToken } from '../../types/JWT';

const regex = /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/gm;

function compareServerURIs(server1: string, server2: string): boolean {
  if (server1 === server2) {
    return true;
  }
  const [, s1_protocol, , , s1_host] = Array.from(regex.exec(server1) || []);
  const [, s2_protocol, , , s2_host] = Array.from(regex.exec(server2) || []);

  return s1_protocol === s2_protocol && s1_host === s2_host;
}

interface Props {}

const OAuth: FC<Props> = () => {
  const setStoredDicomServer = useSetRecoilState<DicomServer>(dicomServerSelector);
  const serverList = useRecoilValue(dicomServerList);

  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();

  useEffect(() => {
    async function getAccessToken() {
      const url = window.location;
      const params = new URLSearchParams(url.search);

      const token = params.get('token');
      const tokenPayload = parseJwt(token) as AuthToken;
      const state = JSON.parse(params.get('state') || '{}');

      if (token && tokenPayload) {
        const tokens = await TokenService.getAccessAndRefTokensFromAuthToken(token, {
          tokenServer: tokenPayload.tokenServer,
        });

        if (tokens && tokens.accessToken) {
          let selectedPACS = state.params?.serverUID
            ? serverList.find((el) => el.id === state.params?.serverUID)
            : serverList.find((el) => compareServerURIs(el.url, tokenPayload.pacs || ''));

          if (!selectedPACS) {
            selectedPACS = emptyServerSetting();
          }

          setStoredDicomServer({
            ...selectedPACS,
            authType: 'zhivaAuth',
            name: selectedPACS.name || 'Zhiva PACS',
            url: tokenPayload.pacs || selectedPACS.url,
            tokenServer: tokenPayload.tokenServer,
            refreshServer: tokenPayload.refreshServer,
            accessToken: tokens.accessToken,
            refreshToken: tokens.refreshToken,
            canSave: tokenPayload.canEditResource,
          });

          enqueueSnackbar('Server authenticated', { variant: 'success', autoHideDuration: 1000 });
          auth.signin({ username: tokenPayload.username, remember: true }, (success) => {
            if (success) {
              if (tokenPayload.dicom) {
                window.location.replace(getStudyRoute(tokenPayload.dicom));
              } else if (state.params?.serverUID) {
                window.location.replace(getSettingsServerRoutes(selectedPACS?.id as string));
              } else {
                window.location.replace(DASHBOARD_ROUTE);
              }
            }
          });
        } else {
          enqueueSnackbar('Authentication error', { variant: 'error', autoHideDuration: 1000 });
          window.location.href = DASHBOARD_ROUTE;
        }
      } else {
        enqueueSnackbar('Invalid token', { variant: 'error', autoHideDuration: 1000 });
        window.location.href = DASHBOARD_ROUTE;
      }
    }

    getAccessToken();
  }, []);
  return null;
};

export default OAuth;
