import React from 'react';
import { DICOMS_ROUTE } from "../../utils/routes";

const steps = [
  {
    selector: '#root',
    content: () => (
      <React.Fragment>
        Hi. Welcome to the demo of our product. Please follow my lead my lead to find out where are the most important
        parts of the website.
        <br />
        <br />
        TL; DR; This app is a medical images viewer that can be easily extended with AI models. In this demo you have
        access to the images gathered at our server which you can display in your browser.
      </React.Fragment>
    ),
  },
  {
    selector: 'a[title="Dashboard"]',
    content:
      'Right now, you\'re on the dashboard page. You can always go back here by clicking on the "Dashboard" icon.',
  },
  {
    selector: '#mainDashboard',
    content: 'This is a mock-up of a basic dashboard that will be used to show the doctor his recent activities.',
  },
  {
    selector: 'a[title="Studies"]',
    content: 'By clicking that icon you will be moved to the list of your recent images. Click the "next" arrow to go there directly',
    nextLocation: DICOMS_ROUTE,
  },
];

export default steps;
