import retry from 'retry';

type RetryOptions = {
  retries?: number;
  factor?: number;
  minTimeout?: number;
  maxTimeout?: number;
  randomize?: boolean;
  retryableStatusCodes: number[];
}

const defaultRetryOptions: RetryOptions = {
  retries: 5,
  factor: 3,
  minTimeout: 1 * 1000,
  maxTimeout: 60 * 1000,
  randomize: true,
  retryableStatusCodes: [429, 500],
};

let retryOptions: RetryOptions = { ...defaultRetryOptions };

export const getXHRRetryRequestHook = (options: RetryOptions = defaultRetryOptions) => {
  retryOptions = { ...defaultRetryOptions };
  return (request: XMLHttpRequest, metadata: { url: string; method: string }) => {
    const { url, method } = metadata;

    function faultTolerantRequestSend(...args: any[]) {
      const operation = retry.operation(retryOptions);

      operation.attempt(function operationAttempt(currentAttempt: number) {
        const originalOnReadyStateChange = request.onreadystatechange;

        /** Overriding/extending XHR function */
        request.onreadystatechange = function onReadyStateChange(...args) {
          originalOnReadyStateChange?.apply(request, args);

          if (retryOptions.retryableStatusCodes.includes(request.status)) {
            const errorMessage = `Attempt to request ${url} failed.`;
            const attemptFailedError = new Error(errorMessage);
            operation.retry(attemptFailedError);
          }
        };

        /** Call open only on retry (after headers and other things were set in the xhr instance) */
        if (currentAttempt > 1) {
          console.warn(`Requesting ${url}... (attempt: ${currentAttempt})`);
          request.open(method, url, true);
        }
      });

      // @ts-ignore
      originalRequestSend.apply(request, args);
    }

    /** Overriding/extending XHR function */
    const originalRequestSend = request.send;
    request.send = faultTolerantRequestSend;

    return request;
  };
};

export default getXHRRetryRequestHook;
