import { atom, AtomEffect, selector, selectorFamily } from 'recoil';

import offlineFileService from '../services/FileSystemService';
import { StudyObj } from "../types/Dicom";

const fileStorageEffect: AtomEffect<StudyObj[]> = ({ setSelf, onSet, trigger }) => {
  // If there's a persisted value - set it on load
  // const loadPersisted = async () => {
  //   const localDBList = await offlineFileService.getDicoms();
  //
  //   if (localDBList != null) {
  //     setSelf(localDBList);
  //   }
  // };

  // Load the persisted data
  // if(trigger === 'get') {
  //   loadPersisted();
  // }

  // Subscribe to state changes and persist them to idb
  onSet((newValue) => {
    offlineFileService.setDicoms(newValue);
  });
};

const selectedDicomState = atom<string>({
  key: 'selectedDicom',
  default: '',
});

const dicomsListState = atom<StudyObj[]>({
  key: 'dicomsList',
  default: [],
  effects_UNSTABLE: [fileStorageEffect],
});

const dicomListSelector = selector<StudyObj[]>({
  key: 'dicomListSelector',
  get: ({ get }) => {
    const currentList = get(dicomsListState);

    return currentList;
  },
  set: ({ set }, newValue) => {
    set(dicomsListState, Array.isArray(newValue) ? newValue : []);
  },
});

const dicomSelector = selectorFamily({
  key: 'dicomSelector',
  get: (dicomId: string) => async () => {
    return await offlineFileService.getDicom(dicomId);
  },
  cachePolicy_UNSTABLE: {
    // Only store the most recent set of dependencies and their values
    eviction: 'most-recent',
  },
});

export { selectedDicomState, dicomListSelector, dicomSelector };
