import { AuthType, DicomServer } from '../types/dicomServer';
import { v4 as uuidv4 } from 'uuid';
import { LOCAL_STORAGE } from '../utils/constants';
import { ModelServer } from '../types/modelServer';
import { ModelSupportedPaths, ModelTypes } from '../components/Settings/ModelSettings/ModelSchema';

export const defaultFilterFields: DicomServer['defaultFilterFields'] = [
  '00081030', // Study Description
  '00080060', // Modality
];

export const emptyServerSetting: () => DicomServer = () => ({
  id: uuidv4(),
  editable: true,
  removable: true,
  authType: '',
  name: '',
  url: '',
  uuid: '',
  postfix: '',
  wadoUriPostfix: '',
  qidoPostfix: '',
  wadoPostfix: '',
  qidoSupportsIncludeField: true,
  imageRendering: 'wadors',
  thumbnailRendering: 'wadors',
  enableStudyLazyLoad: true,
  supportsFuzzyMatching: true,
  canSave: true,
  defaultFilterFields: [...defaultFilterFields],
});

export const defaultDemoServer: DicomServer = {
  id: uuidv4(),
  editable: false,
  removable: false,
  name: 'Demo server',
  authType: process.env.REACT_APP_DEMO_PACS_AUTH ? (process.env.REACT_APP_DEMO_PACS_AUTH as AuthType) : '',
  login: process.env.REACT_APP_DEMO_PACS_LOGIN || '',
  password: process.env.REACT_APP_DEMO_PACS_PASSWORD || '',
  url: process.env.REACT_APP_DEMO_PACS || 'https://localhost/zhiva/pacs',
  uuid: '',
  postfix: process.env.REACT_APP_DEMO_PACS_POSTFIX || '',
  wadoUriPostfix: process.env.REACT_APP_DEMO_PACS_WADO_POSTFIX || 'wado',
  qidoPostfix: process.env.REACT_APP_DEMO_PACS_QIDO || '',
  wadoPostfix: process.env.REACT_APP_DEMO_PACS_WADO || '',
  qidoSupportsIncludeField: true,
  imageRendering: 'wadors',
  thumbnailRendering: 'wadors',
  enableStudyLazyLoad: true,
  supportsFuzzyMatching: true,
  canSave: process.env.REACT_APP_DEMO_PACS_SAVE === 'true',
  defaultFilterFields: [...defaultFilterFields],
  restrictedListOfDicoms:
    (process.env.REACT_APP_DEMO_PACS_DICOMS || '').split(';')[0] !== ''
      ? (process.env.REACT_APP_DEMO_PACS_DICOMS || '').split(';')
      : undefined,
};

export const defaultLocalServer: DicomServer = {
  id: uuidv4(),
  editable: true,
  removable: false,
  name: 'Local server',
  authType: process.env.REACT_APP_LOCAL_PACS_AUTH ? (process.env.REACT_APP_LOCAL_PACS_AUTH as AuthType) : 'basicAuth',
  login: process.env.REACT_APP_LOCAL_PACS_LOGIN || 'zhiva',
  password: process.env.REACT_APP_LOCAL_PACS_PASSWORD || '3$yc$XYQhSA55bJf',
  url: 'https://localhost/zhiva/pacs',
  uuid: '',
  postfix: '',
  wadoUriPostfix: 'wado',
  qidoPostfix: '',
  wadoPostfix: '',
  qidoSupportsIncludeField: true,
  imageRendering: 'wadors',
  thumbnailRendering: 'wadors',
  enableStudyLazyLoad: true,
  supportsFuzzyMatching: true,
  canSave: false,
  defaultFilterFields: [...defaultFilterFields],
};

const envModelServers = (process.env.REACT_APP_DEMO_MODELS || '').split(';');

export const defaultDemoModels: ModelServer[] = [
  {
    id: uuidv4(),
    editable: false,
    removable: true,
    url: '/demo',
    name: 'Heart Detection Model',
    type: ModelTypes.ANNOTATION,
    uuid: 'f769f0a4-07f6-4b9d-a66b-d55174ae54ff',
    supports: ModelSupportedPaths.SERIES,
    isDemo: true,
    demoRestrictions: [
      {
        study: '1.3.6.1.4.1.14519.5.2.1.7009.2403.871108593056125491804754960339',
        series: '1.3.6.1.4.1.14519.5.2.1.7009.2403.168353129945747450419572751964',
      },
    ],
    modalities: [],
  },
  {
    id: uuidv4(),
    editable: false,
    removable: true,
    url: '/demo',
    name: 'Lung Detection Model',
    type: ModelTypes.ANNOTATION,
    uuid: '34414858-4e3b-4161-a1d7-7e074124dd8b',
    supports: ModelSupportedPaths.SERIES,
    isDemo: true,
    demoRestrictions: [
      {
        study: '1.3.6.1.4.1.14519.5.2.1.7009.2403.871108593056125491804754960339',
        series: '1.3.6.1.4.1.14519.5.2.1.7009.2403.168353129945747450419572751964',
      },
      {
        study: '1.3.6.1.4.1.25403.345050719074.3824.20170125095438.5',
        series: '1.3.6.1.4.1.25403.345050719074.3824.20170125095438.6',
      },
      {
        study: '1.3.6.1.4.1.25403.345050719074.3824.20170125095438.5',
        series: '1.3.6.1.4.1.25403.345050719074.3824.20170125095449.8',
      },
    ],
    modalities: [],
  },
  {
    id: uuidv4(),
    editable: false,
    removable: true,
    url: '/demo',
    name: 'Lung Segmentation Model',
    type: ModelTypes.SEGMENTATION,
    uuid: '82e2a896-a0dd-4c41-a8fb-afb9401e95b8',
    supports: ModelSupportedPaths.SERIES,
    isDemo: true,
    demoRestrictions: [
      {
        study: '1.3.6.1.4.1.25403.345050719074.3824.20170125095438.5',
        series: '1.3.6.1.4.1.25403.345050719074.3824.20170125095449.8',
      },
    ],
    modalities: [],
  },
  {
    id: uuidv4(),
    editable: false,
    removable: false,
    url: '/demo',
    name: 'SmarterAchillesMRI',
    type: ModelTypes.PREDICTION,
    uuid: '31dea3d4-3cb0-4e71-9b2a-d6bc65b52521',
    supports: ModelSupportedPaths.SERIES,
    isDemo: true,
    demoRestrictions: [
      {
        study: '1.3.6.1.4.1.19291.2.1.1.12487713712201116701561570944',
        series: '1.3.6.1.4.1.19291.2.1.2.12487713712201116701561571545',
      },
      {
        study: '1.3.6.1.4.1.19291.2.1.1.1248771371220111670156141102',
        series: '1.3.6.1.4.1.19291.2.1.2.1248771371220111670156141163',
      },
    ],
    modalities: [],
  },
].filter((modelServer) => {
  if (Array.isArray(envModelServers) && envModelServers[0] !== '') {
    return envModelServers.includes(modelServer.uuid);
  }
  return true;
});

export const localServers: DicomServer[] = [{ ...defaultDemoServer }, { ...defaultLocalServer }];

export const defaultValuesPerKey = {
  [LOCAL_STORAGE.DICOM_SERVER_KEY]: { ...defaultDemoServer },
  [LOCAL_STORAGE.DICOM_SERVER_LIST_KEY]: localServers.map((server) => ({ ...server })),
  [LOCAL_STORAGE.MODEL_SERVER_LIST_KEY]: defaultDemoModels.map((server) => ({ ...server })),
};
