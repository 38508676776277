import React, { FC, useMemo } from 'react';
import { Link as LinkFromRouter } from "react-router-dom";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

interface Props {
  to: string;
  [rest: string]: any;
}

const RouterButtonNavLink: FC<Props> = (props) => {
  const { to, ...rest } = props;

  const CustomLink = useMemo(
    () => React.forwardRef<HTMLAnchorElement>((linkProps, ref) => <LinkFromRouter ref={ref} to={to} {...linkProps} />),
    [to],
  );
  return <BottomNavigationAction component={CustomLink} {...rest} />;
};

export default RouterButtonNavLink;
