import { SegmentationObj } from "../../types/Dicom";
import generateUID from "../../utils/cornerstoneUtils/generateUID";

interface CreateSegObj {
  name: string,
  rows: number,
  columns: number,
  seriesUID?: string,
  instanceUID?: string,
  studyUID: string,
  seriesNumber?: number
}

const createSegmentationObj = ({ name, rows, columns, seriesUID = generateUID(), instanceUID, studyUID, seriesNumber }: CreateSegObj): SegmentationObj => {
  return {
    id: seriesUID + '-' + (seriesNumber || 100),
    SeriesNumber: seriesNumber || 100,
    StudyInstanceUID: studyUID,
    SeriesInstanceUID: seriesUID,
    SOPInstanceUID: instanceUID || generateUID(),
    PerformedProcedureStepStartDate: "",
    PerformedProcedureStepStartTime: "",
    NumberOfSeriesRelatedInstances: 0,
    SeriesDescription: name,
    Modalities: "SEG",
    rows: rows,
    NumberOfFrames: 0,
    columns: columns,
    ReferencedSeriesSequence: [],
    SegmentSequence: [],
    PerFrameFunctionalGroupsSequence: [],
    ContentCreatorName: 'ZhivaAI',
  }
};

export default createSegmentationObj;
