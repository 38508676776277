import { parseJwt } from '../../utils/jwt';

export class TokenService {
  static async getAccessAndRefTokensFromAuthToken(
    authToken: string,
    {
      tokenServer,
    }: {
      tokenServer: string;
    },
  ): Promise<{
    accessToken: string;
    refreshToken?: string;
  } | null> {
    try {
      const headers = new Headers({
        'Access-Control-Request-Method': 'GET,HEAD,OPTIONS,POST',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Expose-Headers': 'Authorization',
        'Vary': 'Origin',
        Authorization: 'Bearer ' + authToken,
      });
      const response = await fetch(tokenServer, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: headers,
        redirect: 'follow',
        // referrerPolicy: 'no-referrer',
      });
      let accessToken = response.headers.get('Authorization');
      if (accessToken?.startsWith('Bearer ')) {
        accessToken = accessToken.substring(7);
      }
      let refreshToken = await response.text();

      if (accessToken == null) {
        return null;
      }

      return {
        accessToken: accessToken,
        refreshToken: refreshToken,
      };
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getRefreshToken(
    refreshToken: string,
    {
      tokenServer,
    }: {
      tokenServer: string;
    },
  ): Promise<{
    accessToken: string;
  } | null> {
    try {
      const headers = new Headers({
        'Access-Control-Request-Method': 'GET,HEAD,OPTIONS,POST',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Expose-Headers': 'Authorization',
        Authorization: 'Bearer ' + refreshToken,
      });
      const response = await fetch(tokenServer, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: headers,
        redirect: 'follow',
        // referrerPolicy: 'no-referrer',
      });
      let accessToken = response.headers.get('Authorization');
      if (accessToken?.startsWith('Bearer ')) {
        accessToken = accessToken.substring(7);
      }

      if (accessToken == null) {
        return null;
      }

      return {
        accessToken: accessToken,
      };
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static isTokenExpired(token: string): boolean {
    const tokenPayload = parseJwt(token);
    if (!tokenPayload) {
      return false;
    }
    return Number(tokenPayload.exp) < Math.floor(Date.now() / 1000);
  }
}

const TokenServiceInstance = new TokenService();

export default TokenServiceInstance;
