import React, { FC, ReactNode, useMemo } from 'react';
import { Link as LinkFromRouter, useRouteMatch } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

// constants

interface Props {
  label: string;
  to: string;
  icon?: ReactNode;
  activeOnlyWhenExact?: boolean;
}

const RouterListLink: FC<Props> = (props) => {
  const { label, to, icon, activeOnlyWhenExact } = props;
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });


  const CustomLink = useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement>((linkProps, ref) => (
        <LinkFromRouter ref={ref} to={to} {...linkProps} title={label} />
      )),
    [to],
  );
  return (
    <ListItem button selected={match != null} component={CustomLink}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={label} />
    </ListItem>
  );
};

export default RouterListLink;
