import { studyResultsResponse } from "../../../types/dicomServer";
import { StudyObj } from '../../../types/Dicom';
import { getModalities, getPatientName, getStringValue } from '../../../utils/dicomCodesUtils';
import { COMMON_DICOM_CODES } from '../../../utils/constants';

export function mapStudy(study: studyResultsResponse): StudyObj {
  return {
    id:
      getStringValue(study[COMMON_DICOM_CODES.STUDY_INSTANCE_UID]) +
      '-' +
      getStringValue(study[COMMON_DICOM_CODES.STUDY_DATE]),
    StudyInstanceUID: getStringValue(study[COMMON_DICOM_CODES.STUDY_INSTANCE_UID]),
    // 00080005 = SpecificCharacterSet
    StudyDate: getStringValue(study[COMMON_DICOM_CODES.STUDY_DATE]),
    StudyTime: getStringValue(study[COMMON_DICOM_CODES.STUDY_TIME]),
    AccessionNumber: getStringValue(study[COMMON_DICOM_CODES.ACCESSION_NUMBER]),
    ReferringPhysicianName: getStringValue(study[COMMON_DICOM_CODES.REFERRING_PHYSICIAN_NAME]),
    // 00081190 = URL
    PatientName: getPatientName(study[COMMON_DICOM_CODES.PATIENT_NAME]),
    PatientID: getStringValue(study[COMMON_DICOM_CODES.PATIENT_ID]),
    PatientBirthdate: getStringValue(study[COMMON_DICOM_CODES.PATIENT_BIRTH_DATE]),
    PatientSex: getStringValue(study[COMMON_DICOM_CODES.PATIENT_SEX]),
    StudyId: getStringValue(study[COMMON_DICOM_CODES.STUDY_ID]),
    NumberOfStudyRelatedSeries: getStringValue(study[COMMON_DICOM_CODES.NUMBER_OF_STUDY_RELATED_SERIES]),
    NumberOfStudyRelatedInstances: getStringValue(study[COMMON_DICOM_CODES.NUMBER_OF_STUDY_RELATED_INSTANCES]),
    StudyDescription: getStringValue(study[COMMON_DICOM_CODES.STUDY_DESCRIPTION]),
    Modalities: getModalities({
      modalityField: study[COMMON_DICOM_CODES.MODALITY],
      modalitiesAvailableField: study[COMMON_DICOM_CODES.MODALITIES_IN_STUDY],
      keepSeparate: true,
    }),
  }
}

export default function mapStudyListResults(results: studyResultsResponse[]): StudyObj[] {
  return results.map((study) => mapStudy(study));
}