import { openDB, IDBPDatabase, DBSchema, IDBPTransaction, StoreNames } from 'idb';
import { DicomDBObj } from '../types/Dicom';

interface DicomDB extends DBSchema {
  studies: {
    key: string;
    value: DicomDBObj;
  };
  images: {
    key: string;
    value: Blob;
  };
}

const DICOMS_STORE_NAME = 'studies';
const IMAGES_STORE_NAME = 'images';
const DICOMS_DB_NAME = 'Dicoms';

class FileDBService {
  static DB_VERSION = 8;
  public databaseName: string;
  public initializing: boolean;
  public db?: IDBPDatabase<DicomDB> | null;

  public constructor(databaseName: string = DICOMS_DB_NAME) {
    this.databaseName = databaseName;

    this.initializing = true;
  }

  async initDB(dbName: string, version: number, cb?: () => void) {
    this.db = await openDB<DicomDB>(dbName, version, {
      upgrade(
        database: IDBPDatabase<DicomDB>,
        oldVersion: number,
        newVersion: number | null,
        transaction: IDBPTransaction<DicomDB, StoreNames<DicomDB>[], 'versionchange'>,
      ) {
        if(!database.objectStoreNames.contains(DICOMS_STORE_NAME)) {
          database.createObjectStore(DICOMS_STORE_NAME, {
            keyPath: 'id',
          });
          // dicomStore.createIndex('StudyDate', 'StudyDate');
          database.createObjectStore(IMAGES_STORE_NAME);
        }
      },
    });
    this.initializing = false;

    if (typeof cb === 'function') {
      cb();
    }
  }

  async addDicom(dicom: DicomDBObj) {
    await this.initDB(this.databaseName, FileDBService.DB_VERSION);
    if (this.db != null) {
      const transaction = this.db.transaction([DICOMS_STORE_NAME, IMAGES_STORE_NAME], 'readwrite');
      transaction.objectStore(DICOMS_STORE_NAME).put(dicom);

      transaction.done
        .then(() => {
          console.log('All steps succeeded, changes committed!');
        })
        .catch((err) => {
          console.error('Something went wrong, transaction aborted', err);
        });
    }
  }

  async setDicoms(newList: DicomDBObj[]): Promise<void> {
    await this.initDB(this.databaseName, FileDBService.DB_VERSION);
    if (this.db == null || this.initializing) {
      console.error('DB not initialized yet', this.db, this.initializing);
    }
    const currentImages = await this.getDicoms();
    const notStoredElements = newList.filter((el) => !currentImages?.map((currEl) => currEl.id).includes(el.id));
    notStoredElements.forEach((el) => {
      this.addDicom(el);
    });
    // const storedWithoutImage = newList.filter((el) => {
    //   const imgOnCurrentList = currentImages?.find((currEl) => currEl.id === el.id);
    //   return imgOnCurrentList != null && imgOnCurrentList.image == null && el.image != null;
    // });
    // storedWithoutImage.forEach((el) => {
    //   this.addDicom(el);
    // });
  }

  async getDicomKeys(): Promise<string[] | undefined> {
    await this.initDB(this.databaseName, FileDBService.DB_VERSION);
    if (this.db == null || this.initializing) {
      console.error('DB not initialized yet', this.db, this.initializing);
    }
    return this.db?.getAllKeys(DICOMS_STORE_NAME);
  }

  async getDicoms(): Promise<DicomDBObj[] | undefined> {
    await this.initDB(this.databaseName, FileDBService.DB_VERSION);
    if (this.db == null || this.initializing) {
      console.error('DB not initialized yet', this.db, this.initializing);
    }
    return this.db?.getAll(DICOMS_STORE_NAME);
  }

  async getDicom(key: string): Promise<Blob | undefined> {
    await this.initDB(this.databaseName, FileDBService.DB_VERSION);
    if (this.db == null || this.initializing) {
      console.error('DB not initialized yet', this.db, this.initializing);
    }
    return this.db?.get(IMAGES_STORE_NAME, key);
  }
}

const FileDBServiceInstance = new FileDBService('studies-store');

export default FileDBServiceInstance;
