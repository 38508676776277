import React, { FC } from 'react';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import formatDA from '../CornerstoneViewport/helpers/formatDA';
import Chip from '@mui/material/Chip';
import { Modality } from '../../types/Dicom';
import { MODALITIES } from '../../utils/constants';

interface DateCellProps {
  date: string;
  cellConfig?: TableCellProps;
}

export const DateCell: FC<DateCellProps> = ({ date, cellConfig }) => {
  const formattedDate = formatDA(date);

  return <TableCell>{formattedDate}</TableCell>;
};

interface ModalitiesCellProps {
  modalities: string | string[];
  cellConfig?: TableCellProps;
}

export const ModalitiesCell: FC<ModalitiesCellProps> = ({ modalities, cellConfig }) => {
  const modalitiesArr = (Array.isArray(modalities) ? modalities : [modalities]) as Modality[];
  return (
    <TableCell>
      {modalitiesArr.map((modality) => (
        <Tooltip key={modality} title={MODALITIES[modality] || ''}>
          <Chip size={'small'} label={modality} />
        </Tooltip>
      ))}
    </TableCell>
  );
};

interface DicomCellProps {
  type: string;
  value: any | any[];
  cellConfig?: TableCellProps;
}

export const DicomCell: FC<DicomCellProps> = ({ type, value, cellConfig }) => {
  if (value == null || value.length === 0) {
    return <TableCell {...cellConfig}>{`<Not Set>`}</TableCell>;
  }
  switch (type) {
    case 'date':
      return <DateCell date={Array.isArray(value) ? value[0] : value} cellConfig={cellConfig} />;
    case 'modality':
      return <ModalitiesCell modalities={value} cellConfig={cellConfig} />;
    default:
      return <TableCell {...cellConfig}>{value}</TableCell>;
  }
};
