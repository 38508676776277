import React, { FC, ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../Auth';

interface Props {
  children: ReactNode;
  [rest: string]: any;
}

const AuthRoute: FC<Props> = ({ children, ...rest }) => {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default AuthRoute;
