import { seriesResultsResponse } from '../../../types/dicomServer';
import { InstanceMetadataObj } from '../../../types/Dicom';
import { getModalities, getStringValue } from '../../../utils/dicomCodesUtils';
import { COMMON_DICOM_CODES } from '../../../utils/constants';
import { data } from 'dcmjs'

export function mapSeriesMetadata(series: seriesResultsResponse): InstanceMetadataObj {
  return {
    ...data.DicomMetaDictionary.naturalizeDataset(series),
    id:
      getStringValue(series[COMMON_DICOM_CODES.SERIES_INSTANCE_UID]) +
      '-' +
      getStringValue(series[COMMON_DICOM_CODES.SERIES_NUMBER]),
    Modalities: getModalities({
      modalityField: series[COMMON_DICOM_CODES.MODALITY],
      keepSeparate: true,
    }),
  };
}

export default function mapSeriesMetadataResults(results: seriesResultsResponse[]): InstanceMetadataObj[] {
  const seriesLength = results.length;
  return results.map((series) => ({
    ...mapSeriesMetadata(series),
    NumOfImages: seriesLength,
  }));
}
