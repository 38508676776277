import { InstanceUIDs } from "../../../types/Dicom";
import { DicomServer } from "../../../types/dicomServer";

export function buildServerUri(dicomServer: DicomServer, postfixes: string[] = []): string {
  const postFixString = [
    dicomServer.postfix,
    ...postfixes
  ].filter(postfix => typeof postfix === 'string' && postfix.length > 0);
  return `${dicomServer.url}${postFixString.length > 0 ? '/' : ''}${postFixString.join('/')}`
}

export default function buildInstanceWadoRsUri(
  dicomServer: DicomServer,
  StudyInstanceUID: InstanceUIDs['StudyInstanceUID'],
  SeriesInstanceUID: InstanceUIDs['SeriesInstanceUID'],
  SOPInstanceUID: InstanceUIDs['SOPInstanceUID']
) {
  return `${dicomServer.imageRendering}:${buildServerUri(dicomServer, [dicomServer.wadoPostfix])}/studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}`;
}

export function buildWADORSImageId(
  dicomServer: DicomServer,
  StudyInstanceUID: InstanceUIDs['StudyInstanceUID'],
  SeriesInstanceUID: InstanceUIDs['SeriesInstanceUID'],
  SOPInstanceUID: InstanceUIDs['SOPInstanceUID'],
  frameId: number = 1,
  ) {
  return buildInstanceWadoRsUri(dicomServer, StudyInstanceUID, SeriesInstanceUID, SOPInstanceUID) + `/frames/${frameId}`;
}