import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';

interface Props {}

const MotFound: FC<Props> = () => {
  let location = useLocation();
  return (
    <Box sx={{pt:4}}>
      <Typography variant="body2" color="secondary" align="center">
        There is nothing here <code>{location.pathname}</code>
      </Typography>
    </Box>
  );
};

export default MotFound;
