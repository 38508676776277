const steps = [
  {
    selector: '#dicomListContainer',
    content: "Here are your recent medical images.",
  },
  {
    selector: '#dicomListContainer tbody tr:first-child',
    content: "Here you can see the metadata of a single image."
  },
  {
    selector: '#dicomListContainer tbody tr:first-child a[aria-label="store image"]',
    content: 'This purple icon opens an example study. Please click on it to go next.',
  }
];

export default steps;
