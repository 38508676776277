import React, { FC, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";

interface Props {
  children: ReactNode;
}


const TitleTypography = styled(Typography)(({ theme }) => ({
  // ...theme.typography.h6,
  paddingLeft: theme.spacing(2),
}));

const ChartTitle: FC<Props> = ({ children }) => {
  return (
    <TitleTypography color={'primary'} variant={'h6'}>
      {children}
    </TitleTypography>
  );
};

export default ChartTitle;
