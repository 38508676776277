import { LOCAL_STORAGE } from '../../utils/constants';
import { defaultValuesPerKey } from '../../configs/dicomServer';
import { ModelFilter, ModelServer } from '../../types/modelServer';
import { InstanceUIDs } from '../../types/Dicom';
import { generateModelAPIPath } from './generateModelAPIPath';
import SegmentationAPI, { BrushColorClasses } from '../SegmentationService/SegmentationAPI';
import { ModelTypes } from '../../components/Settings/ModelSettings/ModelSchema';

export type ModelRequestOptionFilter = {
  dataKey: string;
  uuid: string;
  dataFilter?: {
    dataKey: string;
    uuid: string;
  }[];
};

export type ModelRequestOptions = {
  filters?: ModelRequestOptionFilter[];
};

class ModelServerService {
  public modelServers: ModelServer[];

  public constructor() {
    const storedValRaw = localStorage.getItem(LOCAL_STORAGE.MODEL_SERVER_LIST_KEY);

    this.modelServers = [];

    if (storedValRaw != null) {
      this.setAvailableModels(JSON.parse(storedValRaw || '[]'));
    } else {
      this.setAvailableModels(defaultValuesPerKey[LOCAL_STORAGE.MODEL_SERVER_LIST_KEY] as ModelServer[]);
    }
  }

  setAvailableModels = (models: ModelServer[]): void => {
    this.modelServers = models;
    let activeLabelMapIndex = SegmentationAPI.getLastLabelMapIndex() + 1;
    SegmentationAPI.setAvailableBrushClassesFromModels(
      this.modelServers
        .filter((model) => model.type === ModelTypes.SEGMENTATION)
        .reduce<BrushColorClasses>((acc, model) => {
          for (const segClass of model.segmentationClasses || []) {
            acc[segClass.class] = {
              color: segClass.color,
              name: segClass.name,
              class: segClass.class,
              activeLabelmapIndex: activeLabelMapIndex,
              source: 'model',
            };
            activeLabelMapIndex += 1;
          }
          return acc;
        }, {}),
    );
  };

  /**
   * Checks if only demo models are on the list
   */
  areOnlyDemoModels = (): boolean => {
    return this.modelServers.every((modelServer) => modelServer.isDemo === true);
  };

  /**
   * Filters list of model servers by given filter
   * @param filter
   */
  getAvailableModels = (filter?: ModelFilter): ModelServer[] => {
    if (this.modelServers == null) {
      throw new Error('ModelService has no "models" assigned to it');
    }
    if (filter != null) {
      return this.modelServers.filter((modelServer) => {
        if (
          filter.modalities != null &&
          Array.isArray(modelServer.modalities) &&
          modelServer.modalities.length > 0 &&
          !modelServer.modalities.some((modality) => filter.modalities?.includes(modality))
        ) {
          return false;
        }
        if (filter.supports != null && modelServer.supports !== filter.supports) {
          return false;
        }
        if (filter.type != null && modelServer.type !== filter.type) {
          return false;
        }
        return true;
      });
    }
    return this.modelServers;
  };

  /**
   * Filters list of model servers to exclude non demo models and models that are not designed to match specific StudyUID from "demoRestriction"
   * @param models
   * @param demoRestrictions
   */
  filterDemoModels = (models: ModelServer[], demoRestrictions?: ModelServer['demoRestrictions']): ModelServer[] => {
    function getRestrictionString(restriction: { study: string; series?: string; instance?: string }) {
      return `study/${restriction.study}/series/${restriction.series}/instance/${restriction.instance}`;
    }
    return models.filter((modelServer) => {
      if (!modelServer.isDemo) {
        return false;
      }
      if (demoRestrictions != null) {
        return modelServer.demoRestrictions?.some((demoRestriction) => {
          return demoRestrictions.map(getRestrictionString).includes(getRestrictionString(demoRestriction));
        });
      }
      return true;
    });
  };

  /**
   * Filter out demo models
   * @param models
   */
  filterNonDemoModels = (models: ModelServer[]): ModelServer[] => {
    return models.filter((modelServer) => !modelServer.isDemo);
  };

  getDataFromModel = async (
    model: ModelServer,
    dicomData: {
      StudyInstanceUID: InstanceUIDs['StudyInstanceUID'];
      SeriesInstanceUID?: InstanceUIDs['SeriesInstanceUID'];
      SOPInstanceUID?: InstanceUIDs['SOPInstanceUID'];
    },
    isLoadingHandler?: (isLoading: boolean) => void,
    options?: ModelRequestOptions,
  ) => {
    if (isLoadingHandler) {
      isLoadingHandler(true);
    }
    const requestPath = generateModelAPIPath(model, dicomData, options);

    try {
      const res = await fetch(requestPath);
      if (res.status >= 400 && res.status < 500) {
        throw new Error(`Cannot get response from ${model.name}, ${res.statusText}`);
      }
      if (res.status >= 500) {
        throw new Error(`Internal Error: ${model.name}, ${res.statusText}`);
      }
      return res.json();
    } catch (e: any) {
      throw new Error(e.message);
    }
  };
}

const ModelServerServiceInstance = new ModelServerService();

export default ModelServerServiceInstance;

export { ModelServerService };
