import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import { DicomServer } from '../../types/dicomServer';
import { LOCAL_STORAGE } from '../../utils/constants';
import { defaultValuesPerKey } from '../../configs/dicomServer';
import getAuthorizationHeader from './helpers/getAuthorizationHeader';

class DicomServerService {
  public server: DicomServer;

  public constructor() {
    const storedValRaw = localStorage.getItem(LOCAL_STORAGE.DICOM_SERVER_KEY);

    if (storedValRaw != null) {
      this.server = JSON.parse(storedValRaw || '{}');
    } else {
      this.server = defaultValuesPerKey[LOCAL_STORAGE.DICOM_SERVER_KEY] as DicomServer;
    }
    this.updateWADOImageLoader();
  }

  updateWADOImageLoader = (): void => {
    cornerstoneWADOImageLoader.configure({
      beforeSend: (xhr: any) => {
        const headers = getAuthorizationHeader({
          requestOptions:
            this.server.authType === 'basicAuth'
              ? {
                  auth: {
                    type: this.server.authType,
                    value: `${this.server.login}:${this.server.password}`,
                  },
                }
              : this.server.authType === 'token' || this.server.authType === 'zhivaAuth'
              ? {
                  auth: {
                    type: this.server.authType,
                    value: `${this.server.accessToken}`,
                  },
                }
              : undefined,
        });

        if (headers.Authorization) {
          xhr.setRequestHeader('Authorization', headers.Authorization);
        }
      },
      errorInterceptor: (error: any) => {
        // const { appConfig = {} } = AppContext;

        // if (typeof appConfig.httpErrorHandler === 'function') {
        //   appConfig.httpErrorHandler(error);
        // }
        console.error(error);
      },
    });
  };

  setSelectedServer = (server: DicomServer): void => {
    this.server = server;
    this.updateWADOImageLoader();
  };

  getSelectedServer = (): DicomServer => {
    if (this.server == null) {
      throw new Error('DicomService has no "server" assigned to it');
    }
    return this.server;
  };

  setNewToken = (newToken: string): void => {
    this.server.accessToken = newToken;

    localStorage.setItem(LOCAL_STORAGE.DICOM_SERVER_KEY, JSON.stringify({ ...this.server }));

    const serversList =
      localStorage.getItem(LOCAL_STORAGE.DICOM_SERVER_LIST_KEY) != null
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.DICOM_SERVER_LIST_KEY) || '{}')
        : null;

    const selectedServer = serversList.find((el: DicomServer) => el.id === this.server.id);

    const otherServers = serversList.filter((el: DicomServer) => el.id !== selectedServer.id);

    localStorage.setItem(
      LOCAL_STORAGE.DICOM_SERVER_LIST_KEY,
      JSON.stringify([...otherServers, { ...selectedServer, accessToken: newToken }]),
    );

    this.updateWADOImageLoader();
  };
}

const DicomServerInstance = new DicomServerService();

export default DicomServerInstance;
