import React from 'react';
import { DICOMS_ROUTE } from '../../utils/routes';

const steps = [
  {
    selector: '#dicomViewContainer',
    content:
      'This window allows to analyze DICOM images. It displays important metadata about the patient and the image.',
    prevLocation: DICOMS_ROUTE,
  },
  {
    selector: '#series-drawer',
    content: 'This drawer display all series available within the selected study.',
  },
  {
    selector: '.viewport-wrapper .scroll-holder',
    content:
      'Actually, this image is not a single image, but a series of frames. You can see the next ones by using the scroll bar.',
  },
  {
    selector: '[aria-label="dicom-navigation"]',
    content: 'The menu allows you to select different tools to suit your needs.',
  },
  {
    selector: '[aria-label="mouse-button-select"]',
    content:
      "Before selecting your tool, you have to select which mouse button you're assigning it to. You can choose left or right mouse button.",
  },
  {
    selector: '[aria-label="Wwwc-button"]',
    content: () => (
      <React.Fragment>
        You can modify the image brightness by clicking the left mouse button and moving the cursor in x and y axis.
        <br />
        <br />
        <img src="/tour-images/dicom-window.gif" alt="bightness gif" loading="lazy" width={300} />
      </React.Fragment>
    ),
  },
  {
    selector: '[aria-label="Zoom-button"]',
    content: () => (
      <React.Fragment>
        You can change the zoom by clicking the right mouse button and moving the cursor.
        <br />
        <br />
        <img src="/tour-images/zoom.gif" alt="zoom gif" loading="lazy" width={300} />
      </React.Fragment>
    ),
  },
  {
    selector: '[aria-label="Pan-button"]',
    content: 'If the image is not centered properly, you can move it around with the Pan tool.',
  },
  {
    selector: '[aria-label="Length-button"]',
    content: () => (
      <React.Fragment>
        One of the features supported by our viewer is measuring the distance on a given DICOM. You can check out this
        feature by clicking this icon.
        <br />
        <br />
        <img src="/tour-images/distance-measures.gif" alt="distance measure gif" loading="lazy" width={300} />
      </React.Fragment>
    ),
  },
  {
    selector: '[aria-label="measurement-button"]',
    content:
      'Measurements are the important part of any study. This tool allows you to create ROIs and segmentations on the selected image.',
  },
  {
    selector: '[aria-label="Frame Rate Button Group"]',
    resizeObservables: ['[aria-label="Frame Rate Button Group"]'],
    content:
      'You have an ability to move through series with a set framerate by clicking this button and using play controls.',
  },
  {
    selector: '[aria-label="ai-models-button"]',
    content:
      'AI model provides are able to add their solutions for you to use. If the model matches all the series\' criteria then it become available inside this menu.' ,
  },
  {
    selector: '[aria-label="share-button"]',
    content:
      'You can share the image with others by clicking this button. It will generate a link that you can send to anyone you want.',
  },
  {
    selector: '[aria-label="help-button"]',
    content:
      "That's the end of our little tutorial. Now feel free to play with the viewer on your own. \n If you need that tutorial again, just click this button.",
  },
];

export default steps;
