import * as yup from 'yup';
import { MODALITIES } from '../../../utils/constants';

export enum ModelSupportedPaths {
  STUDIES = '/studies',
  SERIES = '/studies/series',
  INSTANCES = '/studies/series/instances',
}
export enum ModelTypes {
  SEGMENTATION = 'segmentation',
  ANNOTATION = 'annotation',
  PREDICTION = 'prediction',
}

const filterDataObject = yup
  .array()
  .of(
    yup.object().shape({
      title: yup.string().required('Title is a required field'),
      description: yup.string(),
      dataKey: yup
        .string()
        .matches(/^[a-zA-Z0-9-]+$/, { message: 'Only alphanumeric and hyphen characters are allowed.' })
        .required('Key is a required field'),
      dataFilter: yup
        .array()
        .of(
          yup.object().shape({
            title: yup.string().required('Title is a required field'),
            description: yup.string(),
            dataKey: yup
              .string()
              .matches(/^[a-zA-Z0-9-]+$/, { message: 'Only alphanumeric and hyphen characters are allowed.' })
              .required('Key is a required field'),
          }),
        )
        .test(
          'can-be-array',
          `Second level filters are supported only in "${ModelSupportedPaths.STUDIES}" paths. Please remove them if you want to use selected "Supported Path" value.`,
          (value, context: any) => {
            const supportsStudies =
              (context.from as any[]).filter(
                (fromEl) => fromEl.value.supports != null && fromEl.value.supports === ModelSupportedPaths.STUDIES,
              ).length > 0;
            return !(!supportsStudies && Array.isArray(value) && value.length > 0);
          },
        ),
    }),
  )
  .test(
    'can-be-array',
    `Filters are not supported in "${ModelSupportedPaths.INSTANCES}" paths. Please remove them if you want to use selected "Supported Path" value.`,
    (value, context: any) => {
      return !(context.parent.supports === ModelSupportedPaths.INSTANCES && Array.isArray(value) && value.length > 0);
    },
  );

const segmentationClassesObject = yup
  .array()
  .of(
    yup.object().shape({
      name: yup.string().required('Name is a required field'),
      color: yup
        .string()
        .matches(/rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)/, { message: 'Color has to be a valid RGB format.' })
        .required('Color is a required field'),
      class: yup
        .string()
        .matches(/^[a-zA-Z0-9_-]+$/, { message: 'Only alphanumeric and hyphen characters are allowed.' })
        .required('Key is a required field'),
    }),
  )
  .test(
    'can-be-array',
    `Segmentation classes are only supported in "${ModelTypes.SEGMENTATION}" model type. Please remove them if you want to use selected "model type" value.`,
    (value, context: any) => {
      return !(context.parent.type !== ModelTypes.SEGMENTATION && Array.isArray(value) && value.length > 0);
    },
  );

export const modelSchema = yup.object().shape({
  name: yup.string().required(),
  uuid: yup
    .string()
    .required()
    .matches(
      /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$/,
      'Enter correct UUIDv4',
    ),
  url: yup
    .string()
    .required()
    .matches(
      /^(?:(https):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/,
      'Enter correct url',
    ),
  type: yup.string().required().oneOf([ModelTypes.SEGMENTATION, ModelTypes.ANNOTATION, ModelTypes.PREDICTION]),
  supports: yup
    .string()
    .required()
    .oneOf([ModelSupportedPaths.STUDIES, ModelSupportedPaths.SERIES, ModelSupportedPaths.INSTANCES]),
  modalities: yup.array().of(yup.string().oneOf(Object.keys(MODALITIES))),
  dataFilter: filterDataObject,
  segmentationClasses: segmentationClassesObject,
});
