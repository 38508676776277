import React, { FC, ReactNode, useReducer } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { isMobile, useDevice } from 'use-ua-parser-js';

// components
import Header from './Header';
import Navigation from './Navigation';
import { darkTheme } from './theme';
import Container from '@mui/material/Container';
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../utils/constants';
import BottomNav from './ButtomNav';

const PREFIX = 'Layout';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  container: `${PREFIX}-container`,
  containerFull: `${PREFIX}-containerFull`,
  toolbar: `${PREFIX}-toolbar`,
  contentShift: `${PREFIX}-contentShift`,
  appBarSpacer: `${PREFIX}-appBarSpacer`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
  },
  [`& .${classes.content}`]: {
    flexGrow: 1,
    minHeight: `100vh`,
    height: `100vh`,
    background: darkTheme.palette.background.paper,
  },

  [`& .${classes.container}`]: {
    padding: 0,
    maxWidth: '100vw',
    overflowY: 'auto',
    overflowX: 'auto',
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
  },

  [`& .${classes.containerFull}`]: {
    height: `calc(100%)`,
  },

  [`& .${classes.toolbar}`]: {
    ...darkTheme.mixins.toolbar,
  },

  [`& .${classes.contentShift}`]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: darkTheme.transitions.create('margin', {
      easing: darkTheme.transitions.easing.easeOut,
      duration: darkTheme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.appBarSpacer}`]: darkTheme.mixins.toolbar,
}));

interface Props {
  children: ReactNode;
  withNav?: boolean;
  options?: {
    fullWidth: boolean;
  };
}

const Layout: FC<Props> = ({ children, withNav = true, options = {} }) => {
  const [open, toggle] = useReducer((open) => !open, false);
  const useMobileNav = isMobile(useDevice());
  return (
    <Root className={classes.root}>
      {withNav && (
        <React.Fragment>
          {!useMobileNav && <Header drawerOpen={open} handleDrawerOpen={toggle} />}
          <Navigation drawerOpen={open} handleDrawerClose={toggle} handleDrawerOpen={toggle} />
        </React.Fragment>
      )}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        { !useMobileNav && withNav && <div className={classes.appBarSpacer} />}
        <Container
          className={clsx(classes.container, {
            [classes.containerFull]: !withNav,
          })}
        >
          <React.Fragment>{children}</React.Fragment>
        </Container>
      </main>
      {withNav && useMobileNav && <BottomNav handleDrawerOpen={toggle} drawerOpen={open} />}
    </Root>
  );
};

export default Layout;
