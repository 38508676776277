import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ChartTitle from '../Layout/ChartTitle';
import RouterButtonLink from '../RouterLink/RouterButtonLink';
import { format } from 'date-fns';

const PREFIX = 'Costs';

const classes = {
  depositContext: `${PREFIX}-depositContext`,
};

const Deposit = styled(Typography)({
  [`&.${classes.depositContext}`]: {
    flex: 1,
  },
});

interface Props {}

const Costs: FC<Props> = () => {
  return (
    <React.Fragment>
      <ChartTitle>Today's costs</ChartTitle>
      <Typography component="p" variant="h4">
        $3,024.00
      </Typography>
      <Deposit color="textSecondary" className={classes.depositContext}>
        {format(new Date(), "'on' PPP")}
      </Deposit>
      <div>
        <RouterButtonLink to="/app/dashboard/costs" color="primary">
          View balance
        </RouterButtonLink>
      </div>
    </React.Fragment>
  );
};

export default Costs;
